import { DefaultElements } from '@/core/intefaces'

import { FieldCoordinatesModel } from '@/modules/Fields/models/FieldsModel'

export enum ETaskPriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
}

export enum ETaskStatus {
  ACTUAL = 'ACTUAL',
  COMPLETED = 'COMPLETED',
  REVIEW = 'REVIEW',
}

export enum ETaskType {
  PIN = 'PIN',
  POLYGON = 'POLYGON',
  VEGETATION_POLYGON = 'VEGETATION_POLYGON',
}

export interface FilterTask {
  farmId?: string
  organizationId?: string
  status?: ETaskStatus
}

export interface ITask extends DefaultElements {
  assignee: string
  author: string
  coordinates: FieldCoordinatesModel[]
  description: string
  endTime: string | Date
  farmId: string
  fieldId: string
  name?: string
  immediate: boolean
  priority: ETaskPriority
  status: ETaskStatus
  type: ETaskType
  serialNumber?: string
  urlPictures?: string[]
}

export interface IReport extends DefaultElements {
  taskId: string
  description: string
  executor: string
  endTime: Date
  serialNumberTask: string
  bbch: string
  problemTypes: string[]
  urlPictures: string[]
  discussAdvisor: boolean
}
