import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '@/components/Icon'

import './ListInfo.scss'

const cnListInfo = cn('ListInfo')

interface IListInfoItem {
  icon?: string
  value: number | { '1h': number }
}

interface IListInfo
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  list: IListInfoItem[]
}

export const ListInfo: FC<IListInfo> = Memo(
  ({ list, className }: IListInfo) => {
    return (
      <div className={cnListInfo(null, [className])}>
        {list.map(el => (
          <div className={cnListInfo('item')}>
            {el.icon && (
              <div className={cnListInfo('icon')}>
                <Icon name={el.icon} fill='#3499E6' />
              </div>
            )}
            <div className={cnListInfo('value')}>
              {typeof el.value === 'number'
                ? el.value
                : el.value['1h'].toFixed(2)}
            </div>
          </div>
        ))}
      </div>
    )
  },
)
