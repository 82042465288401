import { cn } from '@bem-react/classname'
import queryString from 'query-string'
import { FC, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { IQueryParamsAndPaginate } from '@/core/intefaces'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Loading } from '@/components/Loading'
import { Paginate } from '@/components/Paginate'
import { Title } from '@/components/Title'

import { Farm } from './components/Farm'
import { FarmResponseModel } from './models/FarmsModel'
import { farmsDataSelector } from './store/FarmsSelector'
import { getFarmsThunk } from './store/FarmsSlice'
import './styles/Farms.scss'

const cnFarms = cn('Farms')

export const Farms: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const farmsData = useAppSelector(farmsDataSelector)

  const [farms, setFarms] = useState<FarmResponseModel[]>([])
  const [page, setPage] = useState<number>(0)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (farmsData) {
      setFarms(farmsData.content)
    }
  }, [farmsData])

  const loadFarms = async (query: IQueryParamsAndPaginate) => {
    try {
      await dispatch(getFarmsThunk({ size: 10, ...query }))
      setLoading(false)
    } catch (errors) {
      console.log('loadFarms', errors)
      setLoading(false)
    }
  }

  useEffect(() => {
    const obj = queryString.parse(search)

    if (obj) {
      loadFarms({ ...obj, page })
    } else {
      loadFarms({ page })
    }
  }, [page, search])

  return (
    <>
      <Helmet>
        <title>{t('Farms')} | Sensilize teams</title>
      </Helmet>
      <Title>{t('Farms')}</Title>

      {isLoading ? (
        <Loading minHeight='50vh' />
      ) : (
        <div className={cnFarms('grid')}>
          {farms.map(el => (
            <Farm key={el.id} farm={el} />
          ))}

          {farmsData && farmsData.totalPages >= 2 && (
            <Paginate
              total={farmsData.totalPages}
              page={page}
              changePage={page => setPage(page)}
            />
          )}
        </div>
      )}
    </>
  )
})
