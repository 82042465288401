/* eslint-disable @typescript-eslint/no-explicit-any */
import { paths } from '@/config/paths'
import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { IPaginateQuery, IQueryParams, IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import {
  FieldCreateModel,
  FieldModel,
  FieldResponseDataModel,
} from './models/FieldsModel'

class FieldsService {
  async getFields(
    query: IPaginateQuery | IQueryParams | undefined,
  ): Promise<IResponseData<FieldResponseDataModel[]>> {
    const str = query ? '?' + queryString.stringify(query) : ''

    const res = await http.get<IResponseData<FieldResponseDataModel[]>>(
      `/${paths.farm}/fields${str}`,
    )

    return res.data
  }

  async getField(id: string): Promise<FieldResponseDataModel> {
    const res = await http.get<FieldResponseDataModel>(
      `/${paths.farm}/fields/${id}`,
    )

    return res.data
  }

  async createField(data: FieldCreateModel): Promise<FieldModel> {
    const res = await http.post<FieldCreateModel, AxiosResponse<FieldModel>>(
      `/${paths.farm}/fields`,
      data,
    )

    return res.data
  }

  async updateField(data: FieldCreateModel): Promise<FieldModel> {
    const res = await http.post<FieldCreateModel, AxiosResponse<FieldModel>>(
      `/${paths.farm}/fields`,
      data,
    )

    return res.data
  }

  async deleteField(id: string): Promise<void> {
    const res = await http.delete(`/${paths.farm}/fields/${id}`)

    return res.data
  }
}

export default new FieldsService()
