import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  IDataChart,
  IForecast,
  IForecastData,
} from '@/core/services/Weather/models/ForecastModel'

import { Memo } from '@/hoc/Memo'

import { Title } from '@/components/Title'

import { LineChart } from '../LineChart'
import './Analytics.scss'

interface IAnalytics {
  className?: string
  forecast: IForecastData[]
  daily: IForecast | null
}

const cnAnalytics = cn('Analytics')

export const Analytics: FC<IAnalytics> = Memo(
  ({ className, forecast, daily }: IAnalytics) => {
    const { t } = useTranslation('translation')

    const [precipitation, setPrecipitation] = useState<IDataChart[]>([])
    const [temperatures, setTemperatures] = useState<IDataChart[]>([])
    const [dailyP, setDailyP] = useState<IDataChart[]>([])
    const [dailyT, setDailyT] = useState<IDataChart[]>([])

    useEffect(() => {
      if (forecast) {
        const arr = [...forecast].sort((a, b) => b.dt - a.dt)

        const p = arr.map(el => {
          return {
            date: el.dt * 1000,
            value: el.rain ? el.rain : el.snow ? el.snow : 0,
          }
        })

        const t = arr.map(el => {
          return {
            date: el.dt * 1000,
            value: +el.main.temp.toFixed(0),
          }
        })

        setPrecipitation(p)
        setTemperatures(t)
      }
    }, [forecast])

    useEffect(() => {
      if (daily) {
        const arr = [...daily.hourly].filter((_, i) => i <= 23)

        const p = arr.map(el => {
          return {
            date: el.dt * 1000,
            value: el.pop ? el.pop : 0,
          }
        })

        const t = arr.map(el => {
          return {
            date: el.dt * 1000,
            value: +el.temp.toFixed(0),
          }
        })

        // console.log(
        //   t.map(el => {
        //     return {
        //       date: dayjs(el.date * 1000).format('DD/MM/YYYY HH:mm'),
        //     }
        //   }),
        // )

        setDailyP(p)
        setDailyT(t)
      }
    }, [daily])

    return (
      <div className={cnAnalytics(null, [className])}>
        {/* <div className={cnAnalytics('item')}>
          <Title size='sm' className={cnAnalytics('subtitle')}>
            {t('Accumulated precipitation, mm')}
          </Title>

          <LineChart
            data={precipitation}
            settings={{
              fields: [
                {
                  valueX: 'date',
                  valueY: 'value',
                },
              ],
            }}
          />
        </div> */}
        <div className={cnAnalytics('item')}>
          <Title size='sm' className={cnAnalytics('subtitle')}>
            {t('Daily precipitation, mm')}
          </Title>

          <LineChart
            data={dailyP}
            settings={{
              fields: [
                {
                  valueX: 'date',
                  valueY: 'value',
                },
              ],
            }}
            baseInterval={{ timeUnit: 'hour', count: 1 }}
          />
        </div>
        {/* <div className={cnAnalytics('item')}>
          <Title size='sm' className={cnAnalytics('subtitle')}>
            {t('Daily temperatures, °C')}
          </Title>

          <LineChart
            data={temperatures}
            settings={{
              fields: [
                {
                  valueX: 'date',
                  valueY: 'value',
                },
              ],
            }}
          />
        </div> */}
        <div className={cnAnalytics('item')}>
          <Title size='sm' className={cnAnalytics('subtitle')}>
            {t('Sum of Active Temperatures, °C')}
          </Title>
          <LineChart
            data={dailyT}
            settings={{
              fields: [
                {
                  valueX: 'date',
                  valueY: 'value',
                },
              ],
            }}
            baseInterval={{ timeUnit: 'hour', count: 1 }}
          />
        </div>
      </div>
    )
  },
)
