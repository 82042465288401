import { cn } from '@bem-react/classname'
import * as turf from '@turf/turf'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import { IUser } from '@/core/intefaces'

import { Memo } from '@/hoc/Memo'

import { generateSvgImage } from '@/utils/generateSvgImage'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Culture } from '@/components/Culture'
import { Info } from '@/components/Info'
import { Status } from '@/components/Status'
import { Users } from '@/components/Users'

import { ETaskPriority } from '@/modules/Tasks/models/TasksModel'

import {
  FieldResponseDataModel,
  FieldSeasonListModel,
} from '../../models/FieldsModel'
import { FieldImage } from '../FieldImage'
import './FieldItem.scss'

const cnFieldItem = cn('FieldItem')

interface IFieldItem {
  className?: string
  field: FieldResponseDataModel
}

export const FieldItem: FC<IFieldItem> = Memo(
  ({ className, field }: IFieldItem) => {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const [path, setPath] = useState<string>('')
    const [hectares, setHectares] = useState<string>('')
    const [season, setSeason] = useState<null | FieldSeasonListModel>(null)

    useEffect(() => {
      if (field.seasonList.length >= 1) {
        const currentSeason = field.seasonList[0]

        setSeason(currentSeason)
      }
    }, [field])

    useEffect(() => {
      if (field) {
        const p = generateSvgImage(field.coordinates, field.displayColor)

        setPath(p)
      }
    }, [field])

    useEffect(() => {
      if (field) {
        const coordinates: GeoJSON.Feature<GeoJSON.Polygon>[] = [field].map(
          el => {
            const c = el.coordinates.map(item => {
              return [item.longitude, item.latitude]
            })

            return {
              type: 'Feature',
              geometry: { type: 'Polygon', coordinates: [c] },
              properties: {
                name: el.fieldName,
              },
            }
          },
        )

        const geojson: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
          type: 'FeatureCollection',
          features: coordinates,
        }

        const area = turf.area(geojson)
        // const foot = 3.2808399;
        // const footSquare = foot * foot
        // const newNumber = number * footSquare;
        const convert = turf.convertArea(area, 'meters', 'hectares')
        // // Restrict the area to 2 decimal points.
        // const rounded_area = Math.round(area * 100) / 100

        // console.log('convert', convert.toFixed(3))
        setHectares(convert.toFixed(3))
      }
    }, [field])

    const onClick = () => {
      navigate(`/cabinet/fields/${field.id}`)
    }

    const users: IUser[] = [
      {
        id: 'sdadas',
        firstName: 'Louise',
        lastName: '',
        middleName: '',
        country: '',
        phoneNumber: '',
        email: '',
        username: '',
      },
      {
        id: 'sdasadsadsadas',
        firstName: 'Thierry',
        lastName: '',
        middleName: '',
        country: '',
        phoneNumber: '',
        email: '',
        username: '',
      },
      {
        id: 'sdasadsadsadas',
        firstName: 'Gabrielle',
        lastName: '',
        middleName: '',
        country: '',
        phoneNumber: '',
        email: '',
        username: '',
      },
      {
        id: 'sdasadsadsadas',
        firstName: 'Émilie',
        lastName: '',
        middleName: '',
        country: '',
        phoneNumber: '',
        email: '',
        username: '',
      },
    ]

    return (
      <Card className={cnFieldItem(null, [className])}>
        <div className={cnFieldItem('top')}>
          <div className={cnFieldItem('infos')}>
            <div className={cnFieldItem('view')}>
              <FieldImage path={path} />
              <div className={cnFieldItem('data')}>
                <div className={cnFieldItem('name')}>{field.fieldName}</div>
                <div className={cnFieldItem('hectares')}>
                  {hectares} {t('ha')}
                </div>
              </div>
            </div>
            {season && (
              <Culture season={season} displayColor={field.displayColor} />
            )}
          </div>
          <div className={cnFieldItem('infos')}>
            <Info
              title={field.farmDto?.organizationDto.organizationName}
              iconName='organizations'
              className={cnFieldItem('info')}
            />
            <Info
              title={field.farmDto?.farmName}
              iconName='farms'
              className={cnFieldItem('info')}
            />

            <Users users={users} className={cnFieldItem('info')} />
          </div>
        </div>

        <div className={cnFieldItem('tasks')}>
          <div className={cnFieldItem('task')}>
            <NavLink className={cnFieldItem('link')} to={''}>
              Example Task 1
            </NavLink>
            <div className={cnFieldItem('text')}>Inspect the problem area</div>
            <div className={cnFieldItem('wrap')}>
              <div className={cnFieldItem('date')}>Sep 5, 2021 5:00</div>
              <Status status={ETaskPriority.CRITICAL} />
            </div>
          </div>
          <div className={cnFieldItem('task')}>
            <NavLink className={cnFieldItem('link')} to={''}>
              Example Task 1
            </NavLink>
            <div className={cnFieldItem('text')}>Inspect the problem area</div>
            <div className={cnFieldItem('wrap')}>
              <div className={cnFieldItem('date')}>Sep 5, 2021 5:00</div>
              <Status status={ETaskPriority.LOW} />
            </div>
          </div>
          <div className={cnFieldItem('task')}>
            <NavLink className={cnFieldItem('link')} to={''}>
              Example Task 1
            </NavLink>
            <div className={cnFieldItem('text')}>Inspect the problem area</div>
            <div className={cnFieldItem('wrap')}>
              <div className={cnFieldItem('date')}>Sep 5, 2021 5:00</div>
              <Status status={ETaskPriority.HIGH} />
            </div>
          </div>
        </div>

        <div className={cnFieldItem('bottom')}>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>3 m/s</div>
            <div className={cnFieldItem('key')}>{t('Wind')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>0 mm</div>
            <div className={cnFieldItem('key')}>{t('Clouds')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>0 mm</div>
            <div className={cnFieldItem('key')}>{t('Precipitation')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>0 hPa</div>
            <div className={cnFieldItem('key')}>{t('Pressure')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>56%</div>
            <div className={cnFieldItem('key')}>{t('Humidity')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>10</div>
            <div className={cnFieldItem('key')}>{t('UV index')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>38°C</div>
            <div className={cnFieldItem('key')}>{t('Surface temperature')}</div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>8%</div>
            <div className={cnFieldItem('key')}>
              {t('Temperature at a depth of 10cm')}
            </div>
          </div>
          <div className={cnFieldItem('item')}>
            <div className={cnFieldItem('value')}>8%</div>
            <div className={cnFieldItem('key')}>{t('Soil moisture')}</div>
          </div>
          <Button onClick={onClick}>{t('More info')}</Button>
        </div>
      </Card>
    )
  },
)
