import { cn } from '@bem-react/classname'
import { FC } from 'react'

import { Memo } from '@/hoc/Memo'

import './FieldImage.scss'

interface IFieldImage {
  className?: string
  path: string
}

const cnFieldImage = cn('FieldImage')

export const FieldImage: FC<IFieldImage> = Memo(
  ({ path, className }: IFieldImage) => {
    return (
      <div
        className={cnFieldImage(null, [className])}
        dangerouslySetInnerHTML={{ __html: path }}
      ></div>
    )
  },
)
