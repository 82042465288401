import { cn } from '@bem-react/classname'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ISoil } from '@/core/services/Soil/models/SoilModel'

import { Memo } from '@/hoc/Memo'

import { Paginator } from '@/components/Paginator'

import { CurrentWeather } from '../CurrentWeather'
import './SoilCondition.scss'

const cnSoilCondition = cn('SoilCondition')

interface ISoilCondition {
  className?: string
  soil: ISoil | null
}

export const SoilCondition: FC<ISoilCondition> = Memo(
  ({ className, soil }: ISoilCondition) => {
    const { t } = useTranslation('translation')

    const [active, setActive] = useState<number>(1)

    const list = [
      { id: 1, title: t('Now') },
      { id: 2, title: t('After 3 hours') },
      { id: 3, title: t('24 hours ago') },
    ]

    return (
      <>
        {soil && (
          <div className={cnSoilCondition(null, [className])}>
            <div className={cnSoilCondition('top')}>
              <div className={cnSoilCondition('title')}>
                {t('Soil condition')}
              </div>

              <Paginator
                list={list}
                onClick={id => setActive(id as number)}
                active={active}
                isPadding={false}
              />
            </div>
            <div className={cnSoilCondition('grid')}>
              <CurrentWeather
                title={t('Temperature on a surface')}
                value={`${(soil.t0 - 273).toFixed(1)}°C`}
              />
              <CurrentWeather
                title={t('Temperature at a depth of 10cm')}
                value={`${(soil.t10 - 273).toFixed(1)}°C`}
              />
              <CurrentWeather
                title={t('Humidity soil')}
                value={`${soil.moisture.toFixed(1)}%`}
              />
            </div>
          </div>
        )}
      </>
    )
  },
)
