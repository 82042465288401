import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { IForecastDaily } from '@/core/services/Weather/models/ForecastModel'

import { Memo } from '@/hoc/Memo'

import { Icon } from '@/components/Icon'

import './Forecast.scss'

const cnForecast = cn('Forecast')

interface IForecast
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  item: IForecastDaily //IForecastData
}

export const Forecast: FC<IForecast> = Memo(
  ({ className, item }: IForecast) => {
    const { t } = useTranslation('translation')

    return (
      <div className={cnForecast(null, [className])}>
        <div className={cnForecast('icon')}>
          <Icon name={item.weather[0].icon} width={40} height={40} />
        </div>
        <div className={cnForecast('today')}>
          <div className={cnForecast('day')}>
            {dayjs().isSame(dayjs(item.dt * 1000), 'day')
              ? t('Today')
              : dayjs(item.dt * 1000).format('dddd')}
          </div>
          <div className={cnForecast('date')}>
            {dayjs(item.dt * 1000).format('MMMM DD')}
          </div>
        </div>

        <div className={cnForecast('el')}>
          <div className={cnForecast('value')}>
            {Math.round(item.temp.max)}° / {Math.round(item.temp.min)}°
          </div>
          <div className={cnForecast('key')}>
            {t('Max')} / {t('Min')}
          </div>
        </div>
        <div className={cnForecast('el')}>
          <div className={cnForecast('value')}>{item.wind_speed} m/s</div>
          {/* <div className={cnForecast('value')}>{item.wind.speed} m/s</div> */}
          <div className={cnForecast('key')}>{t('Wind')}</div>
        </div>
        <div className={cnForecast('el')}>
          <div className={cnForecast('value')}>{item.humidity}%</div>
          {/* <div className={cnForecast('value')}>{item.main.humidity}%</div> */}
          <div className={cnForecast('key')}>{t('Humidity')}</div>
        </div>
        <div className={cnForecast('el')}>
          <div className={cnForecast('value')}>{item.clouds}%</div>
          <div className={cnForecast('key')}>{t('Clouds')}</div>
        </div>

        {item.pop >= 0 && (
          <div className={cnForecast('el')}>
            <div className={cnForecast('value')}>
              {Math.round(item.pop * 100)}%
            </div>
            <div className={cnForecast('key')}>{t('Precipitation')}</div>
          </div>
        )}

        {/* {(item.rain || item.snow) && (
          <div className={cnForecast('el')}>
            <div className={cnForecast('value')}>
              {item.rain || item.snow} mm
            </div>
            <div className={cnForecast('key')}>{t('Precipitation')}</div>
          </div>
        )} */}
      </div>
    )
  },
)
