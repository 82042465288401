import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const soil = (state: RootState) => state.soilReducer
export const soilSelector = createSelector(
  soil,
  soilReducer => soilReducer.soil,
)

const soils = (state: RootState) => state.soilReducer
export const soilsSelector = createSelector(
  soils,
  soilReducer => soilReducer.soils,
)
