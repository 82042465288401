import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import queryString from 'query-string'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { IQueryParamsAndPaginate } from '@/core/intefaces'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import yup from '@/utils/yup-extended'

import { Paginate } from '@/components/Paginate'
import { Select } from '@/components/Select'
import { Title } from '@/components/Title'

import { farmsDataSelector } from '../Farms/store/FarmsSelector'
import { getFarmsThunk } from '../Farms/store/FarmsSlice'
import { organizationsSelector } from '../Organizations/store/OrganizationsSelector'
import { getOrganizationThunk } from '../Organizations/store/OrganizationsSlice'
import { TaskItem } from './components/TaskItem'
import { FilterTask } from './models/TasksModel'
import { tasksDataSelector, tasksSelector } from './store/TasksSelector'
import { getTasksThunk, resetTasks } from './store/TasksSlice'
import './styles/Tasks.scss'

const cnTasks = cn('Tasks')

const statuses = [
  { id: 'CREATED', label: 'Created' },
  { id: 'EXECUTED', label: 'Executed' },
  { id: 'FINISHED', label: 'Finished' },
]

export const Tasks: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const tasks = useAppSelector(tasksSelector)
  const tasksData = useAppSelector(tasksDataSelector)
  const farmsData = useAppSelector(farmsDataSelector)
  const orgData = useAppSelector(organizationsSelector)

  const [page, setPage] = useState<number>(0)
  const [params, setParams] = useState<IQueryParamsAndPaginate | null>(null)

  const getFilters = async () => {
    try {
      await dispatch(getOrganizationThunk()).unwrap()
      await dispatch(getFarmsThunk()).unwrap()
    } catch (errors) {
      console.log('getFilters', errors)
    }
  }

  const getTasks = async (query: IQueryParamsAndPaginate) => {
    try {
      await dispatch(getTasksThunk({ size: 8, ...query })).unwrap()
    } catch (errors) {
      console.log('getTasksThunk', errors)
    }
  }

  useEffect(() => {
    if (params) {
      getTasks({ ...params, page })
    } else if (!params && page >= 0) {
      getTasks({ page })
    }
  }, [page, params])

  useEffect(() => {
    if (location && location.search) {
      const obj = queryString.parse(location.search)

      if (obj) {
        if (obj.page) {
          setPage(+obj.page)
        }
        getTasks(obj as unknown as IQueryParamsAndPaginate)

        if (obj.page) {
          delete obj.page

          reset(obj)
        } else {
          reset(obj)
        }
      }
    }
  }, [])

  useEffect(() => {
    getFilters()

    return () => {
      dispatch(resetTasks())
    }
  }, [])

  const Schema = yup.object().shape({
    farmId: yup.string().trim().nullable(),
    organizationId: yup.string().trim().nullable(),
    status: yup.string().trim().nullable(),
  })

  const { watch, control, reset } = useForm<FilterTask>({
    resolver: yupResolver(Schema),
    mode: 'onChange',
  })

  useEffect(() => {
    const subscription = watch((form, { type }) => {
      if (type === 'change') {
        setParams(form as IQueryParamsAndPaginate)

        if (location && location.search) {
          const obj = queryString.parse(location.search)

          delete obj.page

          const str = queryString.stringify({ ...obj, ...form })

          navigate(location.pathname + '?' + str)
        } else {
          const str = queryString.stringify(form)

          navigate(location.pathname + '?' + str)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, location])

  return (
    <>
      <Helmet>
        <title>{t('Tasks')} | Sensilize teams</title>
      </Helmet>

      <div className={cnTasks('top')}>
        <Title className={cnTasks('title')}>{t('Tasks')}</Title>

        <div className={cnTasks('filters')}>
          {orgData && (
            <Controller
              name='organizationId'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  options={orgData.content}
                  handleCustomChange={onChange}
                  returnValue='id'
                  theme='white'
                  optionValue='id'
                  optionLabel='organizationName'
                  placeholder={t('Organisation')}
                  className={cnTasks('select')}
                  mb='none'
                />
              )}
            />
          )}
          {farmsData && (
            <Controller
              name='farmId'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  options={farmsData.content}
                  handleCustomChange={onChange}
                  returnValue='id'
                  theme='white'
                  optionValue='id'
                  optionLabel='farmName'
                  placeholder={t('Farm')}
                  className={cnTasks('select')}
                  mb='none'
                />
              )}
            />
          )}
          <Controller
            name='status'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                options={statuses}
                handleCustomChange={onChange}
                returnValue='id'
                theme='white'
                optionValue='id'
                optionLabel='label'
                placeholder={t('Status')}
                className={cnTasks('select')}
                mb='none'
              />
            )}
          />
        </div>
      </div>

      <div className={cnTasks('list')}>
        {tasks.map(el => (
          <TaskItem
            key={el.id}
            organizationsName='MegaFarm manufacturing'
            farmName='Apple farm'
            fieldName='Field 1'
            task={el}
          />
        ))}

        {tasksData && tasksData.totalPages >= 2 && (
          <Paginate
            total={tasksData.totalPages}
            page={page}
            changePage={page => setPage(page)}
          />
        )}
      </div>
    </>
  )
})
