import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import SoilService from '../SatelliteService'
import { ISatelliteImage } from '../models/SatelliteImageModel'

export interface SoilState {
  satelliteImage: ISatelliteImage | null
  dates: string[]
}

const initialState: SoilState = {
  satelliteImage: null,
  dates: [],
}

export const getSatelliteImageThunk = createAsyncThunk(
  'satellite/getSatelliteImageThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await SoilService.getSatelliteImage(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getCurrentDateThunk = createAsyncThunk(
  'satellite/getCurrentDateThunk',
  async (query: string, { rejectWithValue }) => {
    try {
      return await SoilService.getCurrentDate(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const satelliteSlice = createSlice({
  name: 'satellite',
  initialState,
  reducers: {
    resetSatelliteImage(state) {
      state.satelliteImage = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSatelliteImageThunk.fulfilled, (state, action) => {
        state.satelliteImage = action.payload
      })
      .addCase(getSatelliteImageThunk.rejected, state => {
        state.satelliteImage = null
      })

      .addCase(getCurrentDateThunk.fulfilled, (state, action) => {
        state.dates = action.payload
      })
      .addCase(getCurrentDateThunk.rejected, state => {
        state.dates = []
      })
  },
})
export const { actions, reducer } = satelliteSlice
export const { resetSatelliteImage } = actions
