import * as yup from 'yup'
import { AnyObject, Maybe, Message } from 'yup/lib/types'

const NUMBER_REGEX = /^\d+(?:\.\d{1,2})?$/
const NUMBER_INTEGER_REGEX = /^\d+$/

type ValidValueIntegerAndFloatMessages = {
  isDouble?: string
  isInteger?: string
  isMinValue?: {
    value: number
    message: string
  }
  isMaxValue?: {
    value: number
    message: string
  }
} & Message

type ValidValuePassword = {
  lowerСase?: string
  upperСase?: string
  lowerAndUpperСase?: string
  specialCharacter?: string
} & Message

function isValidValueIntegerAndFloat(
  this: yup.StringSchema,
  messages: ValidValueIntegerAndFloatMessages,
) {
  return this.test('isValidValueIntegerAndFloat', messages, function (value) {
    const { path, createError } = this

    // if(messages.isDouble && value) {
    //   return schema.transform()
    // }

    if (messages.isDouble && value && !value.match(NUMBER_REGEX)) {
      return createError({
        path,
        message: messages.isDouble,
      })
    }

    if (messages.isInteger && value && !value.match(NUMBER_INTEGER_REGEX)) {
      return createError({
        path,
        message: messages.isInteger,
      })
    }

    if (
      messages.isMinValue &&
      value &&
      !isNaN(parseFloat(value)) &&
      +value < messages.isMinValue.value
    ) {
      return createError({
        path,
        message: messages.isMinValue.message,
      })
    }

    if (
      messages.isMaxValue &&
      value &&
      !isNaN(parseFloat(value)) &&
      +value > messages.isMaxValue.value
    ) {
      return createError({
        path,
        message: messages.isMaxValue.message,
      })
    }

    return true
  })
}

const SERIAL_REGEX = /^[0-9][A-Za-z]{2}[0-9]{3}[A-Za-z]{2}$/

function isValidSerAlfa(this: yup.StringSchema, message: string) {
  return this.test('isValidSerAlfa', message, function (value) {
    const { path, createError } = this

    if (value && !value.match(SERIAL_REGEX)) {
      return createError({
        path,
        message: message,
      })
    }

    return true
  })
}

const WIFI_ADDRESS_REGEX =
  /^(tcp){1}:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3};\d{1,5}$/

function isValidWiFiAddress(this: yup.StringSchema, message: string) {
  return this.test('isValidWiFiAddress', message, function (value) {
    const { path, createError } = this

    if (value && !value.match(WIFI_ADDRESS_REGEX)) {
      return createError({
        path,
        message: message,
      })
    }

    return true
  })
}

function isValidPassword(this: yup.StringSchema, messages: ValidValuePassword) {
  return this.test('isValidPassword', messages, function (value) {
    const { path, createError } = this

    if (messages.lowerСase && value && !value.match(/[a-z]/i)) {
      return createError({
        path,
        message: messages.lowerСase,
      })
    }
    if (messages.upperСase && value && !value.match(/[A-Z]/i)) {
      return createError({
        path,
        message: messages.upperСase,
      })
    }

    if (messages.lowerAndUpperСase && value && !value.match(/[a-zA-Z]/i)) {
      return createError({
        path,
        message: messages.lowerAndUpperСase,
      })
    }

    if (messages.specialCharacter && value && !value.match(/[!@#$%^&*]/)) {
      return createError({
        path,
        message: messages.specialCharacter,
      })
    }

    return true
  })
}

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidValueIntegerAndFloat(
      messages: ValidValueIntegerAndFloatMessages,
    ): StringSchema<TType, TContext>
  }

  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidSerAlfa(message: string): StringSchema<TType, TContext>
  }

  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidWiFiAddress(message: string): StringSchema<TType, TContext>
  }

  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidPassword(messages: ValidValuePassword): StringSchema<TType, TContext>
  }
}

yup.addMethod(
  yup.string,
  'isValidValueIntegerAndFloat',
  isValidValueIntegerAndFloat,
)

yup.addMethod(yup.string, 'isValidSerAlfa', isValidSerAlfa)
yup.addMethod(yup.string, 'isValidWiFiAddress', isValidWiFiAddress)
yup.addMethod(yup.string, 'isValidPassword', isValidPassword)

export default yup
