import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Empty.scss'

const cnEmpty = cn('Empty')

interface IEmpty
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text?: string
  header?: string
  children?: ReactNode
  icon?: string
  isHeaderDark?: boolean
}

export const Empty: FC<IEmpty> = Memo(
  ({
    className,
    text = 'No data',
    header,
    children,
    icon,
    isHeaderDark,
  }: IEmpty) => {
    const { t } = useTranslation('translation')
    return (
      <div className={cnEmpty(null, [className])}>
        {icon && (
          <div className={cnEmpty('icon')}>
            <Icon name={icon}></Icon>
          </div>
        )}
        {header && (
          <div className={cnEmpty('header', { dark: isHeaderDark })}>
            {header}
          </div>
        )}

        <div className={cnEmpty('text')}>{t(text)}</div>
        {children && <div className={cnEmpty('btn')}>{children}</div>}
      </div>
    )
  },
)
