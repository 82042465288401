import { cn } from '@bem-react/classname'
import { FC } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Info.scss'

const cnInfo = cn('Info')

interface IInfo {
  className?: string
  iconName?: string
  title?: string
}

export const Info: FC<IInfo> = Memo(({ className, iconName, title }: IInfo) => {
  return (
    <div className={cnInfo(null, [className])}>
      <Icon name={iconName} />
      {title}
    </div>
  )
})
