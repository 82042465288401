import { cn } from '@bem-react/classname'
import { FC } from 'react'
// import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { Menu } from '../Menu'
import './Header.scss'

const cnHeader = cn('Header')

interface IHeader {
  className?: string
}

export const Header: FC<IHeader> = Memo(({ className }: IHeader) => {
  // const { t } = useTranslation('translation')

  return (
    <header className={cnHeader(null, [className])}>
      <div className={cnHeader('line')}>
        <Link to={'/cabinet/organizations'} className={cnHeader('logo')}>
          <img src='/img/logo.svg' alt='Sensilize' />
        </Link>
        <Menu className={cnHeader('menu')} />
      </div>
    </header>
  )
})
