/* eslint-disable @typescript-eslint/no-explicit-any */
import { paths } from '@/config/paths'
import queryString from 'query-string'

import { IQueryParams, IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import {
  IChat,
  IConsultant,
  IRequestMessages,
  IResponseMessages,
} from './models/СhatsModel'

class ChatsService {
  async getChat(query: IQueryParams | undefined): Promise<IChat[]> {
    const str = query ? '?' + queryString.stringify(query) : ''

    const res = await http.get<IResponseData<IChat[]>>(
      `/${paths.chats}/chats${str}`,
    )

    return res.data.content
  }

  async getСonsultants(): Promise<IConsultant[]> {
    const res = await http.get<IConsultant[]>(`/${paths.chats}/consultants`)

    return res.data
  }

  async setHelp(chatId: string): Promise<void> {
    await http.post(`/${paths.chats}/chats/${chatId}/help`)
  }

  async getMessages(data: IRequestMessages): Promise<IResponseMessages> {
    const str =
      data && data.query ? '?' + queryString.stringify(data.query) : ''

    const res = await http.get<IResponseData<any[]>>(
      `/${paths.chats}/chats/${data.chatId}/messages/${str}`,
    )

    return res.data
  }
}

export default new ChatsService()
