import { cn } from '@bem-react/classname'
import * as turf from '@turf/turf'
import dayjs from 'dayjs'
import queryString from 'query-string'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import i18n from '@/core/i18n'
import { IQueryParamsAndPaginate } from '@/core/intefaces'
import { satelliteImageSelector } from '@/core/services/Satellite/store/SatelliteSelector'
import {
  getCurrentDateThunk,
  getSatelliteImageThunk,
} from '@/core/services/Satellite/store/SatelliteSlice'
import { ISeason } from '@/core/services/Season/models/SeasonsModel'
import { seasonsSelector } from '@/core/services/Season/store/SeasonsSelector'
import {
  getSeasonsThunk,
  resetSeasons,
} from '@/core/services/Season/store/SeasonsSlice'
import { getMembersThunk } from '@/core/services/Settings/store/SettingsSlice'
import { soilSelector } from '@/core/services/Soil/store/SoilSelector'
import { getSoilThunk, resetSoils } from '@/core/services/Soil/store/SoilSlice'
import { IForecast } from '@/core/services/Weather/models/ForecastModel'
import {
  currentWeatherSelector,
  uviSelector,
  weatherSelector,
} from '@/core/services/Weather/store/WeatherSelector'
import {
  getCurrentUVIThunk,
  getCurrentWeatherThunk,
  getFieldWeatherThunk,
  resetWeather,
} from '@/core/services/Weather/store/WeatherSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { generateSvgImage } from '@/utils/generateSvgImage'
import { removeEmpty } from '@/utils/removeEmpty'

import { Empty } from '@/components/Empty'
import { Icon } from '@/components/Icon'
import { MapBox } from '@/components/MapBox'
import { Paginator } from '@/components/Paginator'
import { Title } from '@/components/Title'
import { VegetationIndices } from '@/components/VegetationIndices'

import { tasksDataSelector, tasksSelector } from '../Tasks/store/TasksSelector'
import { getTasksThunk, resetTasks } from '../Tasks/store/TasksSlice'
import { Analytics } from './components/Analytics'
import { ChartArea } from './components/ChartArea'
import { CurrentWeather } from './components/CurrentWeather'
import { FieldImage } from './components/FieldImage'
import { Forecast } from './components/Forecast'
import { ListInfo } from './components/ListInfo'
import { Scouting } from './components/Scouting'
import { Season } from './components/Season'
import { SoilCondition } from './components/SoilCondition'
import { Task } from './components/Task'
import { WeatherDay } from './components/WeatherDay'
import { fieldSelector } from './store/FieldsSelector'
import { getFieldByIdThunk, resetField } from './store/FieldsSlice'
import './styles/FieldView.scss'

type ParamsType = {
  // id: string
  fieldId: string
}

const cnFieldView = cn('FieldView')

export const FieldView: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const {
    //id,

    fieldId,
  } = useParams<ParamsType>()
  const field = useAppSelector(fieldSelector)
  const weather = useAppSelector(weatherSelector)
  const soil = useAppSelector(soilSelector)
  const uvi = useAppSelector(uviSelector)
  const tasks = useAppSelector(tasksSelector)
  const tasksData = useAppSelector(tasksDataSelector)
  const seasonsList = useAppSelector(seasonsSelector)
  const satelliteImage = useAppSelector(satelliteImageSelector)
  const currentWeather = useAppSelector(currentWeatherSelector)

  const [index, setIndex] = useState<string | null>(null)
  const [date, setDate] = useState<string | Date>(new Date())
  const [activeTab, setActiveTab] = useState<number>(1)
  const [active, setActive] = useState<number>(1)
  const [path, setPath] = useState<string>('')
  const [hectares, setHectares] = useState<string>('')
  const [seasons, setSeasons] = useState<ISeason[]>([])
  const [forecast, setForecast] = useState<IForecast | null>(null)

  const listPager = [
    { id: 1, title: t('Information') },
    { id: 2, title: t('Scouting') },
    { id: 3, title: t('Weather') },
    { id: 4, title: t('Setellite') },
  ]

  const list = [
    { id: 1, title: t('Analytics') },
    { id: 2, title: t('Forecast') },
  ]

  useEffect(() => {
    if (search) {
      const { activeTab } = queryString.parse(search)
      if (activeTab) {
        setActiveTab(+activeTab)
      }
    }
  }, [search])

  useEffect(() => {
    const arr = seasonsList
      .map(el => {
        return {
          ...el,
          year: +el.year,
        }
      })
      .sort((a, b) => b.year - a.year)

    setSeasons(arr)
  }, [seasonsList])

  useEffect(() => {
    if (field && index) {
      onLoadSatelliteImage(field.id, dayjs(date).format('YYYY-MM-DD'), index)
    }
  }, [field, date, index])

  const getMembers = async (id: string) => {
    try {
      await dispatch(getMembersThunk(id)).unwrap()
    } catch (errors) {
      console.log('getMembers', errors)
    }
  }

  const onLoadSatelliteImage = async (
    fieldId: string,
    date: string | Date,
    index: string,
  ) => {
    try {
      await dispatch(
        getSatelliteImageThunk(
          `?fieldId=${fieldId}&evalScriptType=${index}&date=${date}`,
        ),
      ).unwrap()

      await dispatch(
        getCurrentDateThunk(`?fieldId=${fieldId}&evalScriptType=${index}`),
      ).unwrap()
    } catch (errors) {
      console.log('onLoadSatelliteImage', errors)
    }
  }

  const getFieldById = async (id: string) => {
    try {
      await dispatch(getFieldByIdThunk(id)).unwrap()

      await dispatch(getFieldWeatherThunk(id)).unwrap()
      await dispatch(getSoilThunk(id)).unwrap()
      await loadSeasons(id)
      await getTasks(id)
    } catch (error) {
      console.log('geField', error)
    }
  }

  const loadSeasons = async (id: string) => {
    try {
      await dispatch(getSeasonsThunk(id)).unwrap()
    } catch (errors) {
      console.log('loadSeasons', errors)
    }
  }

  useEffect(() => {
    if (field) {
      getForecast()
    }
  }, [field])

  useEffect(() => {
    if (fieldId) {
      getFieldById(fieldId)
    }

    return () => {
      resetData()
    }
  }, [fieldId])

  const resetData = () => {
    dispatch(resetField())
    dispatch(resetSeasons())
    dispatch(resetTasks())
    dispatch(resetSoils())
    dispatch(resetWeather())
    setForecast(null)
    setSeasons([])
  }

  const getForecast = async () => {
    if (!field) return
    try {
      const res = await fetch(
        `https://api.openweathermap.org/data/2.5/onecall?lat=${field.centroid.latitude}&lon=${field.centroid.longitude}&units=metric&lang=${i18n.languages[0]}&exclude=minutely&appid=eb16b4923c41373bc822af0689977c5a`,
      )

      const data = await res.json()

      setForecast(data)

      await dispatch(
        getCurrentWeatherThunk({
          fieldId: field.id,
          // historyPeriodEnum: HistoryPeriodEnum.YEAR,
          // type: HistoryTypeEnum.HISTORY,
          start: dayjs().subtract(2, 'months').format('YYYY-MM-DDTHH:mm'),
          end: dayjs().format('YYYY-MM-DDTHH:mm'),
        }),
      ).unwrap()

      await dispatch(getCurrentUVIThunk(field.id)).unwrap()
    } catch (errors) {
      console.log('getForecast', errors)
    }
  }

  useEffect(() => {
    if (field) {
      const coordinates: GeoJSON.Feature<GeoJSON.Polygon>[] = [field].map(
        el => {
          const c = el.coordinates.map(item => {
            return [item.longitude, item.latitude]
          })

          return {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [c] },
            properties: {
              name: el.fieldName,
            },
          }
        },
      )

      const geojson: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
        type: 'FeatureCollection',
        features: coordinates,
      }

      const area = turf.area(geojson)
      // const foot = 3.2808399;
      // const footSquare = foot * foot
      // const newNumber = number * footSquare;
      const convert = turf.convertArea(area, 'meters', 'hectares')
      // // Restrict the area to 2 decimal points.
      // const rounded_area = Math.round(area * 100) / 100

      // console.log('convert', convert.toFixed(3))
      setHectares(convert.toFixed(3))
    }
  }, [field])

  useEffect(() => {
    if (field) {
      const p = generateSvgImage(field.coordinates, field.displayColor)
      setPath(p)

      if (field.farmDto) {
        getMembers(field.farmDto?.id)
      }
    }
  }, [field])

  const getTasks = async (id?: string, tab?: string) => {
    if (!id) return

    const obj = { fieldId: id, status: tab ? tab : null }

    const data = removeEmpty(obj)

    try {
      await dispatch(
        getTasksThunk(data as unknown as IQueryParamsAndPaginate),
      ).unwrap()
    } catch (errors) {
      console.log('getTasks', errors)
    }
  }

  const changeTab = (tab: number) => {
    if (activeTab === 2) {
      getTasks(fieldId)
    }

    if (tab !== 1) {
      navigate(`${pathname}?activeTab=${tab}`)
    } else {
      navigate(`${pathname}`)
    }

    setActiveTab(tab as number)
  }

  return (
    <>
      <Helmet>
        <title>{t('Fields')} | Sensilize teams</title>
      </Helmet>

      <div className={cnFieldView('content')}>
        <div className={cnFieldView('top')}>
          <div className={cnFieldView('field')}>
            <FieldImage path={path} />
            <div className={cnFieldView('info')}>
              <div className={cnFieldView('name')}>{field?.fieldName}</div>
              <div className={cnFieldView('hectares')}>
                {hectares} {t('ft')}
              </div>
            </div>
          </div>

          <Paginator
            list={listPager}
            onClick={tab => changeTab(tab as number)}
            active={activeTab}
          />
        </div>

        {activeTab === 1 && (
          <>
            <div className={cnFieldView('group')}>
              <Title
                size='sm'
                className={cnFieldView('subtitle', { none: true })}
              >
                {t('Seasons')}
              </Title>

              {seasons && seasons.length > 0 && field ? (
                <>
                  {seasons.map(el => (
                    <Season
                      key={el.id}
                      color={field.displayColor}
                      season={el}
                      className={cnFieldView('crop')}
                    />
                  ))}
                </>
              ) : (
                <Empty />
              )}
            </div>
            <div className={cnFieldView('group')}>
              <Title
                size='sm'
                className={cnFieldView('subtitle', { none: true })}
              >
                {t('Actual task')}
              </Title>

              {tasks && tasks.length > 0 ? (
                <div className={cnFieldView('grid', { task: true })}>
                  {tasks.map(el => (
                    <Task
                      key={el.id}
                      task={el}
                      onDeleted={() => fieldId && getTasks(fieldId)}
                      urlEdit={`/cabinet/tasks/${el.id}/edit`}
                      urlView={`/cabinet/tasks/${el.id}/view`}
                    />
                  ))}
                </div>
              ) : (
                <Empty />
              )}
            </div>
            <div className={cnFieldView('group')}>
              <Title
                size='sm'
                className={cnFieldView('subtitle', { none: true })}
              >
                {t('Current weather')}
              </Title>

              <div className={cnFieldView('group', { grid: true })}>
                {weather && (
                  <div className={cnFieldView('grid')}>
                    <CurrentWeather
                      title={t('Wind')}
                      value={`${weather.wind.speed} m/s`}
                    />
                    <CurrentWeather
                      title={t('Clouds')}
                      value={`${weather.clouds}%`}
                    />
                    <CurrentWeather
                      title={t('Precipitation')}
                      value={`${
                        weather.rain || weather.snow
                          ? weather.rain || weather.snow
                          : 0
                      } mm`}
                    />
                    <CurrentWeather
                      title={t('Pressure')}
                      value={`${weather.main.pressure}hPa`}
                    />
                    <CurrentWeather
                      title={t('Humidity')}
                      value={`${weather.main.humidity}%`}
                    />
                    <CurrentWeather title={t('UV index')} value={uvi + ''} />
                  </div>
                )}
                {field && (
                  <MapBox
                    centroid={field.centroid}
                    fieldsList={[field]}
                    zoom={11}
                    mode={'view'}
                    style={{
                      height: 166,
                    }}
                    className={cnFieldView('mapbox')}
                  >
                    <button
                      className={cnFieldView('fullscreen')}
                      type='button'
                      onClick={() => setActiveTab(4)}
                    >
                      <Icon name='fullscreen-show' />
                    </button>
                  </MapBox>
                )}

                {weather && <WeatherDay weather={weather} />}

                <SoilCondition soil={soil} />
              </div>
            </div>

            <div className={cnFieldView('group')}>
              <Title
                size='sm'
                className={cnFieldView('subtitle', { none: true })}
              >
                {t('Forecast')}
              </Title>

              {forecast && forecast.daily ? (
                <div className={cnFieldView('grid', { five: true })}>
                  {forecast.daily
                    .filter((el, i) => i < 5)
                    .map(el => (
                      <Forecast key={el.dt} item={el} />
                    ))}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <div className={cnFieldView('tab', { full: true })}>
            <Scouting
              tasks={tasksData && tasksData.content ? tasksData.content : null}
              onDeleted={() => getTasks(fieldId)}
              field={field}
              onClickIndex={i => setIndex(i)}
              onSetDate={date => setDate(date)}
              urlImage={satelliteImage ? satelliteImage.accessUrl : null}
              changeTab={tab => getTasks(fieldId, tab)}
            />
          </div>
        )}
        {activeTab === 3 && (
          <div className={cnFieldView('tab')}>
            <Paginator
              className={cnFieldView('pager')}
              list={list}
              onClick={id => setActive(id as number)}
              active={active}
            />

            {active === 1 && (
              <Analytics forecast={currentWeather} daily={forecast} />
            )}
            {active === 2 && (
              <div className={cnFieldView('tab')}>
                <div className={cnFieldView('group')}>
                  <Title size='sm' className={cnFieldView('subtitle')}>
                    {t('Current weather')}
                  </Title>
                  {weather && (
                    <div className={cnFieldView('grid')}>
                      <CurrentWeather
                        title={t('Wind')}
                        value={`${weather.wind.speed} m/s`}
                      />
                      <CurrentWeather
                        title={t('Clouds')}
                        value={`${weather.clouds}%`}
                      />
                      <CurrentWeather
                        title={t('Precipitation')}
                        value={`${
                          weather.rain || weather.snow
                            ? weather.rain || weather.snow
                            : 0
                        } mm`}
                      />
                      <CurrentWeather
                        title={t('Pressure')}
                        value={`${weather.main.pressure}hPa`}
                      />
                      <CurrentWeather
                        title={t('Humidity')}
                        value={`${weather.main.humidity}%`}
                      />
                      <CurrentWeather title={t('UV index')} value={uvi + ''} />
                    </div>
                  )}
                </div>

                <div className={cnFieldView('group', { grid: true })}>
                  {weather && <WeatherDay weather={weather} />}
                  <SoilCondition soil={soil} />
                </div>
                <div className={cnFieldView('group')}>
                  <Title size='sm' className={cnFieldView('subtitle')}>
                    {t('Forecast 24h')}
                  </Title>
                  {forecast && forecast.hourly && (
                    <ChartArea data={forecast.hourly} />
                  )}
                </div>
                <div className={cnFieldView('group')}>
                  <Title size='sm' className={cnFieldView('subtitle')}>
                    {t('Precipitation')} (mm)
                  </Title>

                  {forecast && forecast.hourly && (
                    <ListInfo
                      list={forecast.hourly
                        .filter((el, i) => i < 24)
                        .map(el => {
                          // console.log(el)

                          return {
                            icon: 'humidity',
                            value: el.rain ? el.rain : el.snow ? el.snow : 0,
                          }
                        })}
                    />
                  )}
                </div>
                <div className={cnFieldView('group')}>
                  <Title size='sm' className={cnFieldView('subtitle')}>
                    {t('Wind m/s')}
                  </Title>
                  {forecast && forecast.hourly && (
                    <ListInfo
                      list={
                        forecast &&
                        forecast.hourly
                          .filter((el, i) => i < 24)
                          .map(el => {
                            return {
                              value: Math.round(el.wind_speed),
                            }
                          })
                      }
                    />
                  )}
                </div>
                <div className={cnFieldView('group')}>
                  <Title size='sm' className={cnFieldView('subtitle')}>
                    {t('Forecast')}
                  </Title>
                  {forecast && forecast.daily && (
                    <div className={cnFieldView('grid', { five: true })}>
                      {forecast.daily
                        .filter((el, i) => i < 5)
                        .map(el => (
                          <Forecast key={el.dt} item={el} />
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === 4 && field && (
          <MapBox
            className={cnFieldView('mapbox', { fullscreen: true })}
            centroid={field.centroid}
            fieldsList={[field]}
            zoom={14}
            urlImage={satelliteImage ? satelliteImage.accessUrl : null}
            style={{
              height: '100%',
            }}
            mode={'image'}
          >
            <VegetationIndices
              onClickIndex={i => setIndex(i)}
              onSetDate={date => setDate(date)}
            />
          </MapBox>
        )}
      </div>
    </>
  )
})
