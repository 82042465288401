import { paths } from '@/config/paths'
import queryString from 'query-string'

import { IPaginateQuery, IQueryParams, IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import {
  FarmResponseModel,
  FarmsCreateModel,
  FarmsModel,
} from './models/FarmsModel'

class FarmsService {
  async getFarms(
    query: IPaginateQuery | IQueryParams | undefined,
  ): Promise<IResponseData<FarmResponseModel[]>> {
    const str = query ? '?' + queryString.stringify(query) : ''

    const res = await http.get(`/${paths.farm}/farms${str}`)

    return res.data
  }

  async getFarmById(id: string): Promise<FarmResponseModel> {
    const res = await http.get<FarmResponseModel>(`/${paths.farm}/farms/${id}`)

    return res.data
  }

  async createFarm(data: FarmsCreateModel): Promise<FarmsCreateModel> {
    const res = await http.post<FarmsCreateModel>(`/${paths.farm}/farms`, data)

    return res.data
  }

  async updateFarm(data: FarmsModel): Promise<FarmsModel> {
    const { id } = data
    const res = await http.put<FarmsModel>(`/${paths.farm}/farms/${id}`, data)

    return res.data
  }

  async deleteFarm(id: string): Promise<void> {
    await http.delete(`/${paths.farm}/farms/${id}`)
  }
}

export default new FarmsService()
