import { cn } from '@bem-react/classname'
import { Feature, Point, Polygon } from 'geojson'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import { Button } from '@/components/Button'
import { ContentLoader } from '@/components/ContentLoader'
import { Empty } from '@/components/Empty'
import { MapBox } from '@/components/MapBox'
import DrawControl from '@/components/MapBox/draw-control'
import { Paginator } from '@/components/Paginator'
import { Title } from '@/components/Title'
import { Top } from '@/components/Top'
import { VegetationIndices } from '@/components/VegetationIndices'

import { ITask } from '@/modules/Tasks/models/TasksModel'

import { FieldResponseDataModel } from '../../models/FieldsModel'
import { Task } from '../Task'
import { TaskForm } from '../TaskForm'
import './Scouting.scss'

const cnScouting = cn('Scouting')

interface IScoutingSchema {
  field: FieldResponseDataModel | null
  tasks: ITask[] | null
  className?: string
  onDeleted?: () => void
  empty?: boolean
  isCreateTask?: boolean
  onClickIndex: (str: string) => void
  onSetDate: (date: string | Date) => void
  urlImage: string | null
  changeTab: (tab: string) => void
}

export const Scouting: FC<IScoutingSchema> = Memo(
  ({
    field,
    className,
    tasks,
    onDeleted,
    isCreateTask,
    onClickIndex,
    onSetDate,
    urlImage,
    changeTab,
  }: // activeIndex,
  IScoutingSchema) => {
    const { t } = useTranslation('translation')

    const [
      isLoading, //setLoading
    ] = useState<boolean>(false)
    const [isForm, setForm] = useState<boolean>(false)
    const [isNoData, setNoData] = useState<boolean>(true)
    const [activeTab, setActiveTab] = useState<string>('ACTUAL')
    const [features, setFeatures] = useState<
      Feature<Polygon>[] | Feature<Point>[]
    >([])

    const listPager = [
      { id: 'ACTUAL', title: t('Actual') },
      { id: 'COMPLETED', title: t('Completed') },
      { id: 'REVIEW', title: t('On review') },
    ]

    // useEffect(() => {
    //   if (tasks) {
    //     setLoading(false)
    //   } else {
    //     setLoading(true)
    //   }
    // }, [tasks])

    useEffect(() => {
      if (!isNoData) {
        changeTab(activeTab)
      }
    }, [activeTab])

    useEffect(() => {
      if (tasks && tasks.length) {
        setNoData(false)
      }
    }, [tasks])

    useEffect(() => {
      if (isCreateTask) {
        setForm(true)
      }
    }, [])

    const onCreate = useCallback((e: MapboxDraw.DrawCreateEvent) => {
      setFeatures(e.features as Feature<Polygon>[] | Feature<Point>[])
      setForm(true)
    }, [])

    console.log('tasks', tasks)

    return (
      <div className={cnScouting(null, [className])}>
        <div className={cnScouting('left', { center: isNoData })}>
          {isLoading ? (
            <ContentLoader type='task' items={4} />
          ) : (
            <>
              {isForm ? (
                <>
                  <Top
                    type='close'
                    onClickBtn={() => setForm(false)}
                    className={cnScouting('head')}
                  >
                    <Title className={cnScouting('title')}>
                      {t('Create task')}
                    </Title>
                  </Top>
                  <TaskForm
                    field={field}
                    onSuccess={() => {
                      setForm(false)
                      onDeleted && onDeleted()
                      setNoData(false)
                    }}
                    features={features}
                  />
                </>
              ) : (
                <>
                  {!isNoData && (
                    <div className={cnScouting('top')}>
                      <Top
                        type='create'
                        onClickBtn={() => setForm(true)}
                        className={cnScouting('head')}
                      >
                        <Title size='sm' className={cnScouting('title')}>
                          {t('Actual task')}
                        </Title>
                      </Top>

                      <Paginator
                        className={cnScouting('tabs')}
                        list={listPager}
                        onClick={id => setActiveTab(id as string)}
                        active={activeTab}
                      />
                    </div>
                  )}

                  {!isNoData ? (
                    <>
                      {tasks && tasks.length >= 1 ? (
                        <>
                          {tasks.map(el => (
                            <Task
                              key={el.id}
                              task={el}
                              className={cnScouting('task')}
                              onDeleted={onDeleted}
                              urlEdit={`/cabinet/tasks/${el.id}/edit`}
                              urlView={`/cabinet/tasks/${el.id}/view`}
                            />
                          ))}
                        </>
                      ) : (
                        <Empty />
                      )}
                    </>
                  ) : (
                    <Empty
                      icon='check-task'
                      className={cnScouting('empty')}
                      header={t('Create first task')}
                      text={
                        'Fill in the task information and assign a person to be responsible.'
                      }
                      isHeaderDark
                    >
                      <Button
                        className={cnScouting('btn')}
                        onClick={() => setForm(true)}
                      >
                        {t('Create task')}
                      </Button>
                    </Empty>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {field && (
          <MapBox
            className={cnScouting('right')}
            centroid={field.centroid}
            fieldsList={[field]}
            zoom={14}
            urlImage={urlImage}
            style={{
              height: '100%',
            }}
            mode={'image'}
            // onClick={onClickToMap}
            // markers={markers}
          >
            <DrawControl
              displayControlsDefault={false}
              // defaultMode={'draw_polygon'}
              position='top-left'
              controls={{
                polygon: true,
                trash: true,
                point: true,
              }}
              onCreate={onCreate}
              // onUpdate={onUpdate}
              // onDelete={onDelete}
            />
            {/* <MapDescription
              className={cnScouting('description')}
              title={t('How it works')}
            >
              <p>
                {t(
                  'Place a minimum of three dots around the perimeter to create the field. It is necessary to build a closed figure.',
                )}
              </p>
              <p>{t('To delete the created point, double-click on it.')}</p>
            </MapDescription> */}

            <VegetationIndices
              onClickIndex={onClickIndex}
              onSetDate={onSetDate}
              className={cnScouting('indices')}
            />
          </MapBox>
        )}
      </div>
    )
  },
)
