import { cn } from '@bem-react/classname'
import { FC, createRef, useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { v4 as uuidv4 } from 'uuid'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Photos.scss'

const cnPhotos = cn('Photos')

interface IPhoto {
  id: string
  preview: string
}
interface IPhotos {
  items?: string[] // IPhoto[]
  className?: string
  isAdd?: boolean
  isChat?: boolean
  accept?: string
  onUpload?: (image: File) => void
  onChangePhoto?: (images: string[]) => void
  resetImage?: () => void
}

export const Photos: FC<IPhotos> = Memo(
  ({
    className,
    items,
    isAdd = true,
    accept = 'image/png, image/jpeg, image/jpg',
    onUpload,
    onChangePhoto,
    isChat,
    resetImage,
  }: IPhotos) => {
    const inputReference = createRef<HTMLInputElement>()
    const [photos, setPhotos] = useState<IPhoto[]>([])
    const [photoIndex, setPhotoIndex] = useState<number>(0)
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
      if (items) {
        const arr = items.map(el => {
          return {
            id: uuidv4(),
            preview: el,
          }
        })
        setPhotos(arr)
      }
    }, [items])

    const deletePhoto = (id: string) => {
      const arr = photos.filter(el => el.id !== id)

      if (onChangePhoto) {
        onChangePhoto(arr.map(el => el.preview))
      } else {
        setPhotos(arr)
      }
    }

    const openWindow = () => {
      if (inputReference && inputReference.current) {
        inputReference.current.click()
      }
    }

    const changeInputFile = (files: FileList | null) => {
      if (!files) return

      const file: File = files[0]

      // const preview = URL.createObjectURL(file)

      // const arr = [
      //   ...photos,
      //   {
      //     id: uuidv4(),
      //     preview,
      //   },
      // ]
      // setPhotos(arr)

      // if (onChangePhoto) {
      //   onChangePhoto(arr)
      // }

      if (onUpload) {
        onUpload(file)
      }
    }

    const openLightbox = (i: number) => {
      setPhotoIndex(i)
      setVisible(true)
    }

    return (
      <div className={cnPhotos(null, [className])}>
        {photos.map((el, i) => (
          <div
            className={cnPhotos('photo', { view: !isAdd, chat: isChat })}
            style={{
              backgroundImage: `url(${el.preview})`,
            }}
            key={i}
            onClick={() => !isAdd && openLightbox(i)}
          >
            {(isAdd || isChat) && (
              <button
                className={cnPhotos('delete', { chat: isChat })}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (isChat && resetImage) resetImage()
                  else deletePhoto(el.id)
                }}
                type='button'
              >
                <Icon
                  name='delete'
                  fill={isChat ? '#eb5757' : '#fff'}
                  width={isChat ? 12 : 18}
                  height={isChat ? 12 : 18}
                />
              </button>
            )}
          </div>
        ))}

        {isAdd && (
          <>
            <button
              type='button'
              className={cnPhotos('create')}
              onClick={openWindow}
            >
              <Icon name='add' />
            </button>

            <input
              type='file'
              name='file'
              id='file'
              ref={inputReference}
              className={cnPhotos('file')}
              accept={accept}
              onChange={e => changeInputFile(e.target.files)}
            />
          </>
        )}

        {visible && (
          <Lightbox
            mainSrc={photos[photoIndex].preview}
            nextSrc={photos[(photoIndex + 1) % photos.length].preview}
            prevSrc={
              photos[(photoIndex + photos.length - 1) % photos.length].preview
            }
            onCloseRequest={() => setVisible(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % photos.length)
            }
          />
        )}
      </div>
    )
  },
)
