import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
// import { watchStatusUnauthorized } from './watchStatusUnauthorized'

export const store = configureStore({
  reducer: rootReducer,
  // middleware: getDefaultMiddleware =>
  //   getDefaultMiddleware({ serializableCheck: false }).concat(
  //     watchStatusUnauthorized,
  //   ),

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
