import { cn } from '@bem-react/classname'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Position, multiPolygon, polygon } from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Map, { FillLayer, Layer, MapRef, Marker, Source } from 'react-map-gl'

import { useAppDispatch } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import {
  FieldCoordinatesModel,
  FieldResponseDataModel,
  ModeMapBoxType,
} from '@/modules/Fields/models/FieldsModel'
import { createOrUpdateCoordinates } from '@/modules/Fields/store/FieldsSlice'
import { ETaskType, ITask } from '@/modules/Tasks/models/TasksModel'

import { Icon } from '../Icon'
// import { Icon } from '../Icon'
import './MapBox.scss'
import DrawControl from './draw-control'
import GeocoderControl from './geocoder-control'

const cnMapBox = cn('MapBox')

const token = process.env.REACT_APP_MAPBOX_KEY || ''

const colors = [
  '#E96262',
  '#D9D9D9',
  '#3D71D5',
  '#F95CBA',
  '#F3C03C',
  '#393939',
  '#4CCC7F',
  '#FC8026',
  '#5AABF5',
  '#B64747',
]
interface IMapBox {
  className?: string
  centroid?: FieldCoordinatesModel
  zoom?: number
  mode?: ModeMapBoxType | null
  fieldsList?: FieldResponseDataModel[]
  children?: ReactNode
  style?: CSSProperties | undefined
  onClick?: (e: mapboxgl.MapLayerMouseEvent) => void
  // markers?: FieldCoordinatesModel[]
  isZooming?: boolean
  isSearching?: boolean
  urlImage?: string | null
  tasks?: ITask[]
}

export const MapBox: FC<IMapBox> = Memo(
  ({
    style,
    centroid,
    zoom = 5,
    mode,
    fieldsList,
    children,
    onClick,
    // markers,
    isZooming,
    className,
    isSearching,
    urlImage,
    tasks,
  }: IMapBox) => {
    const dispatch = useAppDispatch()
    // const { i18n } = useTranslation('translation')
    const mapRef = useRef<
      (MapRef & { setLanguage: (lang: string) => void }) | null
    >(null)

    const [centered, setCentered] = useState<FieldCoordinatesModel | undefined>(
      undefined,
    )
    const [coordinates, setCoordinates] = useState<number[][]>([])

    const [features, setFeatures] = useState<
      GeoJSON.Feature<GeoJSON.Polygon>[]
    >([])
    const [fields, setFields] =
      useState<GeoJSON.FeatureCollection<GeoJSON.Geometry> | null>(null)

    const [polygons, setPolygons] =
      useState<GeoJSON.Feature<GeoJSON.Geometry> | null>(null)
    const [points, setPoints] = useState<number[][]>([])

    // useEffect(() => {
    //   console.log('i18n', i18n.language)
    //   if (mapRef && mapRef.current) {
    //     console.log('mapRef.current', mapRef.current)
    //     // mapRef.current.setLanguage(i18n.language)
    //     console.log(
    //       mapRef.current.getLayoutProperty('country-label', 'text-field'),
    //     )
    //   }
    // }, [mapRef.current, i18n])

    useEffect(() => {
      if (tasks) {
        const points = tasks.filter(el => el.type === ETaskType.PIN)
        const polygons = tasks.filter(el => el.type === ETaskType.POLYGON)

        if (points.length) {
          const arr: Position[] = []
          points.forEach(el => {
            el.coordinates.forEach(item => {
              arr.push([item.longitude, item.latitude])
            })
          })

          setPoints(arr)
        }

        if (polygons.length) {
          const arr: Position[][][] = []
          polygons.forEach(el => {
            const coords: Position[][] = []
            el.coordinates.forEach(item => {
              coords.push([
                item.longitude as unknown as Position,
                item.latitude as unknown as Position,
              ])
            })

            arr.push(coords)
          })
          const data = multiPolygon(arr)

          setPolygons(data)
        }
      }
    }, [tasks])

    useEffect(() => {
      if (fieldsList && fieldsList?.length) {
        const field = fieldsList[0]

        const arr1 = field.coordinates
          .map(item => {
            return item.longitude
          })
          .sort((a, b) => a - b)

        const arr2 = field.coordinates
          .map(item => {
            return item.latitude
          })
          .sort((a, b) => b - a)

        const x1 = arr1[0]
        const x2 = arr1[arr1.length - 1]
        const y1 = arr2[0]
        const y2 = arr2[arr2.length - 1]

        const coordinates = [
          [x1, y1],
          [x2, y1],
          [x2, y2],
          [x1, y2],
        ]
        setCoordinates(coordinates)
      }
    }, [])

    useEffect(() => {
      if (mapRef && mapRef.current && centroid && isZooming) {
        mapRef.current.flyTo({
          center: [centroid.longitude, centroid.latitude],
          duration: 2000,
          zoom: 15,
        })
      }
      setCentered(centroid)
    }, [mapRef, centroid])

    useEffect(() => {
      if (mapRef && mapRef.current && mode === 'image') {
        mapRef.current.moveLayer('overlay', 'outline')
      }
    }, [mapRef, mode, urlImage])

    useEffect(() => {
      onCreateCoods(features)
    }, [features])

    useEffect(() => {
      if (fieldsList && fieldsList.length) {
        const coordinates: GeoJSON.Feature<GeoJSON.Polygon>[] = fieldsList.map(
          el => {
            const c = el.coordinates.map(item => {
              return [item.longitude, item.latitude]
            })

            let colorNumber = 0

            for (let index = 0; index < colors.length; index++) {
              const element = colors[index]

              if (el.displayColor === element) {
                colorNumber = index
              }
            }

            return {
              type: 'Feature',
              geometry: { type: 'Polygon', coordinates: [c] },
              properties: {
                name: el.fieldName,
                displayColor: el.displayColor,
                colorNumber,
              },
            }
          },
        )

        const geojson: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
          type: 'FeatureCollection',
          features: coordinates,
        }

        setFields(geojson)
      }
    }, [fieldsList])

    const onUpdate = useCallback((e: MapboxDraw.DrawUpdateEvent) => {
      const updEl = e.features.length === 1 ? e.features[0] : null

      if (!updEl) return

      setFeatures(currFeatures => {
        const upd = currFeatures.map(el => {
          if (el.id === updEl.id) {
            return updEl as GeoJSON.Feature<GeoJSON.Polygon>
          }
          return el
        })

        return upd
      })
    }, [])

    const onCreate = useCallback((e: MapboxDraw.DrawCreateEvent) => {
      setFeatures(currFeatures => {
        return [
          ...currFeatures,
          ...(e.features as GeoJSON.Feature<GeoJSON.Polygon>[]),
        ]
      })
    }, [])

    const onCreateCoods = (data: GeoJSON.Feature<GeoJSON.Polygon>[]) => {
      const coordinates: FieldCoordinatesModel[] = []

      data.forEach(el => {
        el.geometry.coordinates.forEach(coords => {
          coords.forEach(c => {
            coordinates.push({
              longitude: c[0],
              latitude: c[1],
            })
          })
        })
      })

      dispatch(createOrUpdateCoordinates(coordinates))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onResult = (evt: any) => {
      const { result } = evt
      const location =
        result &&
        (result.center ||
          (result.geometry?.type === 'Point' && result.geometry.coordinates))

      if (mapRef && mapRef.current && location && isZooming) {
        console.log('onResult location', location)
        mapRef.current.flyTo({
          center: location,
          duration: 2000,
          zoom: 12,
        })
      }
    }

    const dataLayer: FillLayer = {
      id: 'layer-data',
      type: 'fill',
      // source: 'source',
      paint: {
        'fill-color': {
          property: 'colorNumber',
          stops: [
            [0, '#E96262'],
            [1, '#D9D9D9'],
            [2, '#3D71D5'],
            [3, '#F95CBA'],
            [4, '#F3C03C'],
            [5, '#393939'],
            [6, '#4CCC7F'],
            [7, '#FC8026'],
            [8, '#5AABF5'],
            [9, '#B64747'],
          ],
        },
        'fill-opacity': 0.4,
      },
    }

    return (
      <div className={cnMapBox(null, [className])}>
        {centered && (
          <Map
            ref={mapRef}
            initialViewState={{
              longitude: centered && centered.longitude,
              latitude: centered && centered.latitude,
              zoom,
            }}
            logoPosition='bottom-right'
            style={{
              width: '100%',
              height: '100vh',
              ...style,
            }}
            mapStyle='mapbox://styles/mapbox/satellite-streets-v12'
            mapboxAccessToken={token}
            interactiveLayerIds={['layer-data', 'unique-id', 'tasks-data']}
            onClick={onClick}
            localFontFamily='Montserrat'
          >
            {children}
            {points.map((el, i) => (
              <Marker
                key={i}
                longitude={el[1]}
                latitude={el[0]}
                anchor='bottom'
              >
                <Icon name='pin' width={10} height={10} />
              </Marker>
            ))}
            {polygons && (
              <>
                <Source id='polygons' type='geojson' data={polygons} />
                <Layer
                  id='outline'
                  type='line'
                  source='polygons'
                  paint={{
                    'line-color': '#fff',
                    'line-width': 2,
                  }}
                />
              </>
            )}
            {fields && mode === 'image' && (
              <>
                {urlImage && (
                  <>
                    <Source
                      id='map-source'
                      type='image'
                      url={urlImage}
                      coordinates={coordinates}
                    />
                    <Layer id='overlay' source='map-source' type='raster' />
                  </>
                )}

                <Source
                  id='maine'
                  type='geojson'
                  data={polygon([
                    fieldsList
                      ? fieldsList[0].coordinates.map(item => {
                          return [item.longitude, item.latitude]
                        })
                      : [],
                  ])}
                />
                {!urlImage && (
                  <>
                    <Layer
                      id='maine'
                      type='fill'
                      source='maine'
                      paint={{
                        'fill-color': fieldsList
                          ? fieldsList[0]?.displayColor
                          : '',
                        'fill-opacity': 0.4,
                      }}
                    />
                  </>
                )}

                <Layer
                  id='outline'
                  type='line'
                  source='maine'
                  paint={{
                    'line-color': fieldsList ? fieldsList[0]?.displayColor : '',
                    'line-width': 2,
                  }}
                />
              </>
            )}
            {fields && mode === 'view' && (
              <Source id={'geojson'} type='geojson' data={fields}>
                <Layer {...dataLayer} />
              </Source>
            )}
            {mode === 'create' && (
              <DrawControl
                defaultMode='draw_polygon'
                onCreate={onCreate}
                onUpdate={onUpdate}
              />
            )}
            x
            {isSearching && (
              <GeocoderControl
                onResult={onResult}
                mapboxAccessToken={token}
                position='top-left'
              />
            )}
          </Map>
        )}
      </div>
    )
  },
)
