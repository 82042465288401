import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Top.scss'

const cnTop = cn('Top')

interface ITop
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  type?: 'create' | 'close'
  onClickBtn?: () => void
}

export const Top: FC<ITop> = Memo(
  ({ children, className, type, onClickBtn }: ITop) => {
    const navigate = useNavigate()

    return (
      <div className={cnTop(null, [className])}>
        {children}

        {!!type && (
          <button
            type='button'
            className={cnTop('btn')}
            onClick={() => {
              if (onClickBtn) {
                onClickBtn()
              } else {
                navigate(-1)
              }
            }}
          >
            {type === 'create' ? <Icon name='add' /> : <Icon name='close' />}
          </button>
        )}
      </div>
    )
  },
)
