/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { FC, ReactNode, useEffect, useState } from 'react'
import DatePickerField from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'

import { TypeMargin, TypeSize } from '@/core/types'

import { FieldThemeType } from '../Field'
import './DatePicker.scss'

type DatePickerType = 'filter'
type LabelColorType = 'dark' | 'blue'
type FlexDirectionType = 'column' | 'row'
type alignItemsType = 'top' | 'bottom' | 'right' | 'left' | 'middle'

const cnDatePicker = cn('DatePicker')

export interface DatePickerSchema {
  uid?: string
  label?: string
  name?: string
  register?: any
  errors?: string
  placeholder?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  className?: string
  classNameLabel?: string
  classNameInput?: string
  control?: any
  iconLeft?: ReactNode | string
  iconRight?: ReactNode | string
  dateFormat?: string
  returnDateFormat?: string
  defaultDate?: Date
  isDefaultDate?: boolean
  isButtonOpen?: boolean
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  timeIntervals?: number
  timeCaption?: string
  showTimeInput?: boolean
  onChange: (e: Date | string) => void
  value?: string | Date
  excludeDates?: Date[]
  includeDates?: Date[]
  minDate?: Date
  maxDate?: Date
  small?: boolean
  type?: DatePickerType
  flexDirection?: FlexDirectionType
  labelColor?: LabelColorType
  alignItems?: alignItemsType
  theme?: FieldThemeType
  mb?: TypeMargin
  info?: string
  sizeInput?: TypeSize
  showMonthYearPicker?: boolean
  filterDates?: string[]
}

export const DatePicker: FC<DatePickerSchema> = ({
  uid = 'datepicker-' + uuidv4(),
  label,
  name,
  required,
  errors,
  placeholder,
  disabled,
  className,
  classNameLabel,
  classNameInput,
  iconLeft,
  iconRight,
  dateFormat = 'dd.MM.yyyy',
  // returnDateFormat = 'DD.MM.YYYY',
  defaultDate,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption = 'Time',
  showTimeInput,
  isButtonOpen,
  onChange,
  value,
  excludeDates,
  minDate,
  maxDate,
  mb = 'sm',
  theme = 'default',
  info,
  sizeInput,
  includeDates,
  showMonthYearPicker,
  filterDates,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation('translation')

  useEffect(() => {
    if (defaultDate) setStartDate(defaultDate)
  }, [defaultDate])

  const handleChange = (e: Date) => {
    onChange(dayjs(e).toISOString())
  }

  const filterDate = (date: Date): boolean => {
    if (!filterDates) return true

    return filterDates?.includes(dayjs(date).format('YYYY-MM-DD'))
  }

  return (
    <div
      className={cnDatePicker(
        {
          xxl: mb === 'xxl',
          xl: mb === 'xl',
          lg: mb === 'lg',
          md: mb === 'md',
          sm: mb === 'sm',
          none: mb === 'none',
        },
        [className],
      )}
    >
      {!!label && (
        <label
          htmlFor={uid}
          className={cnDatePicker(
            'label',
            {
              required,
            },
            [classNameLabel],
          )}
        >
          {label}
        </label>
      )}
      <div className={cnDatePicker('wrap')}>
        {iconLeft && (
          <div
            className={cnDatePicker('icon', {
              left: true,
              click: isButtonOpen,
            })}
            onClick={() => setOpen(true)}
          >
            {iconLeft}
          </div>
        )}

        <DatePickerField
          id={uid}
          // locale='ru-RU'
          name={name}
          disabled={disabled}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          onChange={e => e && handleChange(e as Date)}
          selected={value ? new Date(value) : startDate}
          className={cnDatePicker(
            'input',
            {
              errors: !!errors,
              left: !!iconLeft,
              right: !!iconRight || !!errors || !!info,
              xxl: sizeInput === 'xxl',
              xl: sizeInput === 'xl',
              lg: sizeInput === 'lg',
              md: sizeInput === 'md',
              sm: sizeInput === 'sm',
              default: theme === 'default',
              white: theme === 'white',
              gray: theme === 'gray',
              ios: isIOS,
            },
            [classNameInput],
          )}
          calendarClassName={cnDatePicker('calendar')}
          clearButtonClassName={cnDatePicker('clear')}
          dayClassName={() => cnDatePicker('day')}
          weekDayClassName={() => cnDatePicker('week')}
          monthClassName={() => cnDatePicker('month')}
          timeClassName={() => cnDatePicker('time')}
          popperClassName={cnDatePicker('popper')}
          wrapperClassName={cnDatePicker('wrapper')}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          timeCaption={t(timeCaption)}
          showTimeInput={showTimeInput}
          excludeDates={excludeDates}
          minDate={minDate}
          maxDate={maxDate}
          open={isButtonOpen && open}
          onClickOutside={() => isButtonOpen && setOpen(false)}
          onBlur={() => isButtonOpen && setOpen(false)}
          onFocus={() => isButtonOpen && setOpen(true)}
          autoComplete='off'
          includeDates={includeDates}
          showMonthYearPicker={showMonthYearPicker}
          filterDate={filterDate}
          popperPlacement='top-end'
        />

        {iconRight && (
          <div
            className={cnDatePicker('icon', {
              right: true,
              click: isButtonOpen,
            })}
            onClick={() => setOpen(true)}
          >
            {iconRight}
          </div>
        )}
      </div>
      <Transition in={!!errors} timeout={500} mountOnEnter unmountOnExit>
        {state => (
          <div className={cnDatePicker('error', [state])}>{errors}</div>
        )}
      </Transition>
    </div>
  )
}
