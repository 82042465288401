import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import './Title.scss'

const cnTitle = cn('Title')

interface ITitle
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  size?: TypeSize
}

export const Title: FC<ITitle> = Memo(
  ({ children, className, size = 'xxl' }: ITitle) => {
    return (
      <div
        className={cnTitle(
          {
            xxl: size === 'xxl',
            xl: size === 'xl',
            lg: size === 'lg',
            md: size === 'md',
            sm: size === 'sm',
          },
          [className],
        )}
      >
        {children}
      </div>
    )
  },
)
