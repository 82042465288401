import { cn } from '@bem-react/classname'
import { FC, ReactNode, useState } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Accordion.scss'

const cnAccordion = cn('Accordion')

interface IAccordion {
  children: ReactNode
  title?: string
  className?: string
}

export const Accordion: FC<IAccordion> = Memo(
  ({ children, title, className }: IAccordion) => {
    const [active, setActive] = useState<boolean>(false)

    return (
      <div className={cnAccordion(null, [className])}>
        <div className={cnAccordion('top')}>
          {title && <div className={cnAccordion('title')}>{title}</div>}
          <button
            type='button'
            className={cnAccordion('btn')}
            onClick={() => setActive(!active)}
          >
            {active ? <Icon name='plus' /> : <Icon name='minus' />}
          </button>
        </div>

        {active && <div className={cnAccordion('content')}>{children}</div>}
      </div>
    )
  },
)
