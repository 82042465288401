import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import SoilService from '../SoilService'
import { ISoil } from '../models/SoilModel'

export interface SoilState {
  soil: ISoil | null
  soils: ISoil[]
}

const initialState: SoilState = {
  soil: null,
  soils: [],
}

export const getSoilThunk = createAsyncThunk(
  'soil/getSoilThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await SoilService.getSoil(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getSoilHistoryThunk = createAsyncThunk(
  'soil/getSoilHistoryThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await SoilService.getSoils(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const soilSlice = createSlice({
  name: 'soil',
  initialState,
  reducers: {
    resetSoils(state) {
      state.soil = null
      state.soils = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSoilThunk.fulfilled, (state, action) => {
        state.soil = action.payload
      })
      .addCase(getSoilThunk.rejected, state => {
        state.soil = null
      })
      .addCase(getSoilHistoryThunk.fulfilled, (state, action) => {
        state.soils = action.payload
      })
      .addCase(getSoilHistoryThunk.rejected, state => {
        state.soils = []
      })
  },
})
export const { actions, reducer } = soilSlice
export const { resetSoils } = actions
