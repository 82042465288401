import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import UploaderService from '../UploaderService'
import {
  UploadUrlRequestModel,
  UploaderRequestModel,
  UploaderResponseModel,
} from '../models/UploaderModel'

export interface UploaderState {
  files: UploaderResponseModel[]
}

const initialState: UploaderState = {
  files: [],
}

export const uploadImageThunk = createAsyncThunk(
  'uploader/uploadImageThunk',
  async (data: UploaderRequestModel, { rejectWithValue }) => {
    try {
      return await UploaderService.upload(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const presignUrlThunk = createAsyncThunk(
  'uploader/presignUrlThunk',
  async (data: UploadUrlRequestModel, { rejectWithValue }) => {
    try {
      return await UploaderService.presignUrl(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
  },
})
export const { actions, reducer } = uploaderSlice
