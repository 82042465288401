import { cn } from '@bem-react/classname'
import range from 'lodash/range'
import { FC, useEffect, useState } from 'react'
import Loader, { IContentLoaderProps } from 'react-content-loader'

import { Memo } from '@/hoc/Memo'

import './ContentLoader.scss'

const cnContentLoader = cn('ContentLoader')

interface IContentLoader extends IContentLoaderProps {
  className?: string
  type?: 'card' | 'small' | 'task' | 'facebook'
  items?: number
}

export const ContentLoader: FC<IContentLoader> = Memo(
  ({ type = 'small', items = 1, ...props }: IContentLoader) => {
    const [arr, setArr] = useState<number[]>([items])

    useEffect(() => {
      if (items > 1) {
        const a = range(items)

        setArr(a)
      }
    }, [items])

    return (
      <>
        {type === 'facebook' &&
          arr.map(el => (
            <Loader
              speed={2}
              width={'100%'}
              height={124}
              viewBox='0 0 476 124'
              backgroundColor='#cccccc'
              foregroundColor='#ffffff'
              key={el + '-facebook'}
              className={cnContentLoader()}
              {...props}
            >
              <rect x='48' y='8' rx='3' ry='3' width='88' height='6' />
              <rect x='48' y='26' rx='3' ry='3' width='52' height='6' />
              <rect x='0' y='56' rx='3' ry='3' width='410' height='6' />
              <rect x='0' y='72' rx='3' ry='3' width='380' height='6' />
              <rect x='0' y='88' rx='3' ry='3' width='178' height='6' />
              <circle cx='20' cy='20' r='20' />
            </Loader>
          ))}
        {type === 'task' &&
          arr.map(el => (
            <Loader
              speed={2}
              width={'100%'}
              height={180}
              viewBox='0 0 480 180'
              backgroundColor='#cccccc'
              foregroundColor='#ffffff'
              key={el + '-task'}
              {...props}
              className={cnContentLoader()}
            >
              <rect x='1' y='15' rx='3' ry='3' width='370' height='18' />
              <rect x='0' y='56' rx='3' ry='3' width='320' height='6' />
              <rect x='1' y='82' rx='3' ry='3' width='178' height='6' />
              <circle cx='23' cy='133' r='23' />
              <rect x='450' y='15' rx='3' ry='3' width='30' height='18' />
              <rect x='415' y='15' rx='3' ry='3' width='30' height='18' />
              <rect x='60' y='126' rx='3' ry='3' width='100' height='12' />
            </Loader>
          ))}
        {type === 'small' &&
          arr.map(el => (
            <Loader
              height={56}
              width={'100%'}
              viewBox='0 0 320 56'
              backgroundColor='#cccccc'
              backgroundOpacity={0.3}
              foregroundColor='#fff'
              speed={2}
              key={el + '-small'}
              {...props}
              className={cnContentLoader()}
            >
              <circle cx='27' cy='27' r='18' />
              <rect x='53' y='14' rx='3' ry='3' width='180' height='13' />
              <rect x='53' y='30' rx='3' ry='3' width='10' height='10' />
              <rect x='67' y='30' rx='3' ry='3' width='74' height='10' />
              <circle cx='305' cy='27' r='8' />
              <rect x='0' y='53' rx='0' ry='0' width='320' height='1' />
              <rect x='219' y='146' rx='0' ry='0' width='0' height='0' />
            </Loader>
          ))}

        {type === 'card' &&
          arr.map(el => (
            <Loader
              speed={2}
              width={'100%'}
              height={200}
              viewBox='0 0 400 200'
              backgroundColor='#cccccc'
              backgroundOpacity={0.3}
              foregroundColor='#fff'
              key={el + '-card'}
              {...props}
            >
              <rect x='2' y='2' rx='10' ry='10' width='400' height='201' />
            </Loader>
          ))}
      </>
    )
  },
)
