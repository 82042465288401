import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const isChange = (state: RootState) => state.appReducer
export const changeMapSelector = createSelector(
  isChange,
  appReducer => appReducer.isChange,
)

const isLoading = (state: RootState) => state.appReducer
export const changeLoadingSelector = createSelector(
  isLoading,
  appReducer => appReducer.isLoading,
)

const isOpen = (state: RootState) => state.appReducer
export const changeMenuSelector = createSelector(
  isOpen,
  appReducer => appReducer.isOpen,
)

const visibleMaps = (state: RootState) => state.appReducer
export const visibleMapsSelector = createSelector(
  visibleMaps,
  appReducer => appReducer.visibleMaps,
)
