import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const seasons = (state: RootState) => state.seasonsReducer
export const seasonsSelector = createSelector(
  seasons,
  seasonsReducer => seasonsReducer.seasons,
)

const season = (state: RootState) => state.seasonsReducer
export const seasonSelector = createSelector(
  season,
  seasonsReducer => seasonsReducer.seasons,
)
