import { cn } from '@bem-react/classname'
import { FC, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import { Header } from '../Header'
import './Private.scss'

interface IPrivate {
  children: ReactNode
}

const cnPrivate = cn('Private')

export const Private: FC<IPrivate> = Memo(({ children }: IPrivate) => {
  return (
    <div className={cnPrivate()}>
      <Header />
      <div className={cnPrivate('content')}>{children}</div>
    </div>
  )
})
