import { FC } from 'react'
import SVG from 'react-inlinesvg'

import { Memo } from '@/hoc/Memo'

export interface IIcon {
  name?: string
  fill?: string
  stroke?: string
  className?: string
  width?: number
  height?: number
  path?: string
  onClick?: () => void
}

export const Icon: FC<IIcon> = Memo(
  ({ name, onClick, path = 'icons', ...props }: IIcon) => {
    const p = `${path}/${name}`
    const src = `/img/${p}.svg`

    return (
      <SVG
        // onError={error => console.log(error.message)}
        // onLoad={(src, hasCache) => console.log(src, hasCache)}
        // preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
        src={src}
        onClick={onClick}
        {...props}
      />
    )
  },
)
