import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { Feature, Point, Polygon, Position } from 'geojson'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  membersSelector,
  userSelector,
} from '@/core/services/Settings/store/SettingsSelector'
import { setToast } from '@/core/services/Toast/store/toast.slice'
import { ToastType } from '@/core/services/Toast/types/ToastType'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import yup from '@/utils/yup-extended'

import { Button } from '@/components/Button'
import { DatePicker } from '@/components/DatePicker'
import { Field } from '@/components/Field'
import { Icon } from '@/components/Icon'
import { Paginator } from '@/components/Paginator'
import { Select } from '@/components/Select'
import { TextArea } from '@/components/TextArea'

import {
  ETaskStatus,
  ETaskType,
  ITask,
} from '@/modules/Tasks/models/TasksModel'
import { createTaskThunk } from '@/modules/Tasks/store/TasksSlice'

import {
  FieldCoordinatesModel,
  FieldResponseDataModel,
} from '../../models/FieldsModel'
import './TaskForm.scss'

const cnTaskForm = cn('TaskForm')

interface ITaskFormSchema {
  className?: string
  field: FieldResponseDataModel | null
  features: Feature<Polygon>[] | Feature<Point>[]
  onSuccess: () => void
}

export const TaskForm: FC<ITaskFormSchema> = Memo(
  ({ className, field, features, onSuccess }: ITaskFormSchema) => {
    const { t } = useTranslation('translation')
    const dispatch = useAppDispatch()
    const members = useAppSelector(membersSelector)
    const user = useAppSelector(userSelector)
    const [activeTab, setActiveTab] = useState<number>(1)
    const dateNow = new Date().toLocaleDateString().replaceAll('/', '.')

    const listPager = [
      { id: 1, title: t('Select user') },
      { id: 2, title: t('Invite new user') },
    ]

    const [priorities] = useState<Array<{ id: string; label: string }>>([
      { id: 'CRITICAL', label: t('Critical') },
      { id: 'HIGH', label: t('High') },
      { id: 'LOW', label: t('Low') },
      { id: 'NORMAL', label: t('Normal') },
    ])

    const Schema = yup.object().shape({
      name: yup.string().trim().required(t('This field is required')),
      priority: yup.string().trim().required(t('This field is required')),
      assignee: yup.string().trim().required(t('This field is required')),
      description: yup.string().trim().required(t('This field is required')),
      endTime: yup.string().trim().required(t('This field is required')),
    })

    const {
      handleSubmit,
      formState: { errors, isSubmitting },
      register,
      control,
    } = useForm<ITask>({
      // defaultValues: new FieldCreateModel(),
      resolver: yupResolver(Schema),
      mode: 'onChange',
    })

    const saveTask = async (data: ITask) => {
      if (features.length === 0) {
        dispatch(
          setToast({
            type: ToastType.error,
            message: t(`Field not drawn`),
            position: 'top-center',
          }),
        )
        return
      }

      if (!field || !field.farmDto) return

      const current = features[0].geometry

      switch (true) {
        case current.type === 'Point':
          data.type = ETaskType.PIN
          break
        case current.type === 'Polygon':
          data.type = ETaskType.POLYGON
          break
        default:
          data.type = ETaskType.VEGETATION_POLYGON
          break
      }

      data.status = ETaskStatus.ACTUAL
      data.farmId = field.farmDto?.id
      data.fieldId = field.id

      if (user) {
        data.author = user.id
      }

      let arr: FieldCoordinatesModel[] = []

      switch (data.type) {
        case ETaskType.PIN:
          {
            const coords = current.coordinates
            arr = [
              {
                longitude: coords[0] as number,
                latitude: coords[1] as number,
              },
            ]
          }
          break
        case ETaskType.POLYGON:
          {
            const polygon = current.coordinates[0] as Position[]

            const coords = polygon.map(el => {
              return {
                longitude: el[0] as number,
                latitude: el[1] as number,
              }
            })

            arr = coords
          }
          break
        default:
          break
      }

      data.coordinates = arr

      try {
        const res = await dispatch(createTaskThunk(data)).unwrap()

        dispatch(
          setToast({
            type: ToastType.success,
            message: t(`The task {{name}} has been successfully {{action}}`, {
              name: `${res.name}`,
              action: t('created'),
            }),
          }),
        )

        onSuccess()
        console.log(res)
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    return (
      <form
        onSubmit={handleSubmit(saveTask)}
        className={cnTaskForm(null, [className])}
      >
        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Task name')}</div>
          <Field
            placeholder={t('Enter task name')}
            theme='white'
            {...register('name')}
            errors={errors.name && errors.name.message}
            mb='none'
          />
        </div>

        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Date of creation')}</div>
          <Controller
            name={`createdAt`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <DatePicker
                name={name}
                value={value as Date}
                onChange={onChange}
                placeholder={t(`${dateNow}`)}
                className={cnTaskForm('select')}
                theme='white'
                disabled
                readonly
              />
            )}
          />
        </div>
        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Responsible')}</div>

          <Paginator
            list={listPager}
            onClick={id => setActiveTab(id as number)}
            active={activeTab}
            className={cnTaskForm('user')}
          />

          {activeTab === 1 && (
            <Controller
              name={`assignee`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={members}
                  value={value}
                  handleCustomChange={onChange}
                  placeholder={t('Select responsible')}
                  optionLabel='firstName'
                  optionValue='firstName'
                  returnValue='id'
                  theme='white'
                  className={cnTaskForm('select')}
                  errors={errors && errors.assignee && errors.assignee.message}
                  mb='none'
                />
              )}
            />
          )}

          {activeTab === 2 && (
            <Field
              placeholder={t('Insert E-Mail')}
              theme='white'
              // {...register('name')}
              name='email'
              mb='none'
            />
          )}
        </div>
        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Task Priority')}</div>
          <Controller
            name={`priority`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                options={priorities}
                value={value}
                handleCustomChange={onChange}
                placeholder={t('Select priority')}
                optionLabel='label'
                optionValue='id'
                returnValue='id'
                theme='white'
                className={cnTaskForm('select')}
                errors={errors && errors.priority && errors.priority.message}
                mb='none'
              />
            )}
          />
        </div>
        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Due date')}</div>

          <Controller
            name={`endTime`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <DatePicker
                name={name}
                value={value as Date}
                onChange={onChange}
                placeholder={t('Date')}
                className={cnTaskForm('select')}
                theme='white'
                iconRight={<Icon name='calendar' />}
                errors={errors && errors.endTime && errors.endTime.message}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div className={cnTaskForm('step')}>
          <div className={cnTaskForm('title')}>{t('Task description')}</div>
          <TextArea
            theme='white'
            {...register(`description`)}
            errors={errors && errors.description && errors.description.message}
            mb='none'
          />
        </div>

        <Button type='submit' fluid isLoading={isSubmitting}>
          {t('Create task')}
        </Button>
      </form>
    )
  },
)
