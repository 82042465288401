import { cn } from '@bem-react/classname'
import queryString from 'query-string'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { range } from '@/utils/range'

import './Paginate.scss'

const cnPaginate = cn('Paginate')

export interface IPaginate {
  total: number
  page: number
  changePage: (page: number) => void
}

export const Paginate: FC<IPaginate> = Memo(
  ({ total, page, changePage }: IPaginate) => {
    const { search, pathname } = useLocation()
    const navigate = useNavigate()
    const [arr, setArr] = useState<number[]>([])

    useEffect(() => {
      if (total) {
        setArr(range(1, total))
      }
    }, [total])

    const change = (index: number) => {
      const obj = queryString.parse(search)

      changePage(index === 0 ? 0 : index - 1)

      const str = queryString.stringify({ ...obj, page: index })

      navigate(`${pathname}?${str}`)
    }

    return (
      <nav className={cnPaginate()}>
        <ul className={cnPaginate('list')}>
          {arr.map(el => (
            <li className={cnPaginate('item')} key={el}>
              <button
                className={cnPaginate('link', { active: el === page + 1 })}
                onClick={() => change(el)}
              >
                {el}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    )
  },
)
