import { paths } from '@/config/paths'
import queryString from 'query-string'

import { IPaginateQuery, IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import {
  OrganizationModel,
  OrganizationTypeModel,
} from './models/OrganizationsModel'

class OrganizationsService {
  async getOrganization(
    query: IPaginateQuery | undefined,
  ): Promise<IResponseData<OrganizationModel[]>> {
    const str = query ? '?' + queryString.stringify(query) : ''

    const res = await http.get(`/${paths.farm}/organizations${str}`)

    return res.data
  }

  async getOrganizationType(): Promise<OrganizationTypeModel[]> {
    const res = await http.get<IResponseData<OrganizationTypeModel[]>>(
      `/${paths.farm}/organizations/type`,
    )

    return res.data.content
  }

  async getOrganizationById(id: string): Promise<OrganizationModel> {
    const res = await http.get<OrganizationModel>(
      `/${paths.farm}/organizations/${id}`,
    )

    return res.data
  }

  async createOrganization(
    data: OrganizationModel,
  ): Promise<OrganizationModel> {
    const res = await http.post<OrganizationModel>(
      `/${paths.farm}/organizations`,
      data,
    )

    return res.data
  }

  async updateOrganization(
    data: OrganizationModel,
  ): Promise<OrganizationModel> {
    const { id } = data
    const res = await http.put<OrganizationModel>(
      `/${paths.farm}/organizations/${id}`,
      data,
    )

    return res.data
  }

  async deleteOrganization(id: string): Promise<void> {
    await http.delete<OrganizationModel>(`/${paths.farm}/organizations/${id}`)
  }
}

export default new OrganizationsService()
