import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const weather = (state: RootState) => state.weatherReducer
export const weatherSelector = createSelector(
  weather,
  weatherReducer => weatherReducer.weather,
)

const forecast = (state: RootState) => state.weatherReducer
export const forecastSelector = createSelector(
  forecast,
  weatherReducer => weatherReducer.forecast,
)

const current = (state: RootState) => state.weatherReducer
export const currentWeatherSelector = createSelector(
  current,
  weatherReducer => weatherReducer.current,
)

const uvi = (state: RootState) => state.weatherReducer
export const uviSelector = createSelector(
  uvi,
  weatherReducer => weatherReducer.uvi,
)
