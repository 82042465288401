import { cn } from '@bem-react/classname'
import { AnimatePresence, motion } from 'framer-motion'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import './Spinner.scss'

interface ISpinner
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  position?: 'fixed' | 'absolute' | 'relative'
  zIndex?: number
  visible: boolean
}

const variants = {
  visible: { opacity: 1 },
  hidden: {
    opacity: 0,
  },
}

const cnSpinner = cn('Spinner')

export const Spinner: FC<ISpinner> = Memo(
  ({
    className,
    zIndex = 2000,
    position = 'relative',
    style,
    visible,
  }: ISpinner) => {
    return (
      <AnimatePresence>
        {visible && (
          <motion.div
            transition={{ delayChildren: 1 }}
            variants={variants}
            initial='hidden'
            animate='visible'
            exit='hidden'
            className={cnSpinner(
              {
                fixed: position === 'fixed',
                absolute: position === 'absolute',
                relative: position === 'relative',
              },
              [className],
            )}
            style={{ zIndex, ...style }}
          >
            <div className={cnSpinner('loader', ['loader-radar'])}></div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              className='svg-filters'
            >
              <defs>
                <filter id='filter-goo'>
                  <feGaussianBlur
                    in='SourceGraphic'
                    stdDeviation='7'
                    result='blur'
                  ></feGaussianBlur>
                  <feColorMatrix
                    in='blur'
                    mode='matrix'
                    values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9'
                    result='goo'
                  ></feColorMatrix>
                  <feComposite
                    in='SourceGraphic'
                    operator='atop'
                    in2='goo'
                  ></feComposite>
                </filter>
                <filter id='filter-goo2'>
                  <feGaussianBlur
                    in='SourceGraphic'
                    stdDeviation='7'
                    result='blur'
                  ></feGaussianBlur>
                  <feColorMatrix
                    in='blur'
                    mode='matrix'
                    values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 29 -14'
                    result='goo'
                  ></feColorMatrix>
                  <feComposite
                    in='SourceGraphic'
                    operator='atop'
                    in2='goo'
                  ></feComposite>
                </filter>
                <filter id='filter-goo3'>
                  <feGaussianBlur
                    in='SourceGraphic'
                    stdDeviation='7'
                    result='blur'
                  ></feGaussianBlur>
                  <feColorMatrix
                    in='blur'
                    mode='matrix'
                    values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 25 -11'
                    result='goo'
                  ></feColorMatrix>
                  <feComposite
                    in='SourceGraphic'
                    operator='atop'
                    in2='goo'
                  ></feComposite>
                </filter>
                <filter id='turbulence-1'>
                  <feTurbulence
                    type='fractalNoise'
                    baseFrequency='0.001'
                    numOctaves='2'
                    // data-filterId='3'
                  />
                  <feDisplacementMap
                    xChannelSelector='R'
                    yChannelSelector='G'
                    in='SourceGraphic'
                    scale='25'
                  />
                </filter>

                <filter id='turbulence-2'>
                  <feTurbulence
                    type='fractalNoise'
                    baseFrequency='0.0015'
                    numOctaves='2'
                    // data-filterId='3'
                  />
                  <feDisplacementMap
                    xChannelSelector='R'
                    yChannelSelector='G'
                    in='SourceGraphic'
                    scale='25'
                  />
                </filter>

                <filter id='turbulence-3'>
                  <feTurbulence
                    type='fractalNoise'
                    baseFrequency='0.002'
                    numOctaves='2'
                    // data-filterId='3'
                  />
                  <feDisplacementMap
                    xChannelSelector='R'
                    yChannelSelector='G'
                    in='SourceGraphic'
                    scale='25'
                  />
                </filter>

                <filter id='turbulence-4'>
                  <feTurbulence
                    type='fractalNoise'
                    baseFrequency='0.0025'
                    numOctaves='2'
                    // data-filterId='3'
                  />
                  <feDisplacementMap
                    xChannelSelector='R'
                    yChannelSelector='G'
                    in='SourceGraphic'
                    scale='25'
                  />
                </filter>

                <filter id='turbulence-5'>
                  <feTurbulence
                    type='fractalNoise'
                    baseFrequency='0.003'
                    numOctaves='2'
                    // data-filterId='3'
                  />
                  <feDisplacementMap
                    xChannelSelector='R'
                    yChannelSelector='G'
                    in='SourceGraphic'
                    scale='25'
                  />
                </filter>
                <filter
                  id='filter-outline'
                  x='-20%'
                  y='-20%'
                  width='140%'
                  height='140%'
                  filterUnits='objectBoundingBox'
                  primitiveUnits='userSpaceOnUse'
                  colorInterpolationFilters='linearRGB'
                >
                  <feMorphology
                    operator='dilate'
                    radius='2 2'
                    in='SourceAlpha'
                    result='morphology'
                  />
                  <feFlood
                    floodColor='#ffffff'
                    floodOpacity='1'
                    result='flood'
                  />
                  <feComposite
                    in='flood'
                    in2='morphology'
                    operator='in'
                    result='composite'
                  />
                  <feMorphology
                    operator='erode'
                    radius='2 2'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    in='SourceAlpha'
                    result='morphology1'
                  />
                  <feComposite
                    in='composite'
                    in2='morphology1'
                    operator='out'
                    result='composite1'
                  />
                </filter>
              </defs>
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    )
  },
)
