import { cn } from '@bem-react/classname'
import queryString from 'query-string'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// import { useParams } from 'react-router-dom'
import { IQueryParamsAndPaginate } from '@/core/intefaces'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Loading } from '@/components/Loading'
import { Paginate } from '@/components/Paginate'
import { Select } from '@/components/Select'
import { Title } from '@/components/Title'

import { FieldItem } from './components/FieldItem'
import { fieldsDataSelector, fieldsSelector } from './store/FieldsSelector'
import { getFieldsThunk } from './store/FieldsSlice'
import './styles/Fields.scss'

const cnFields = cn('Fields')

export const Fields: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const fieldList = useAppSelector(fieldsSelector)
  const fieldsData = useAppSelector(fieldsDataSelector)

  const [page, setPage] = useState<number>(0)
  const [isLoading, setLoading] = useState<boolean>(true)

  const geFields = async (query: IQueryParamsAndPaginate) => {
    try {
      await dispatch(getFieldsThunk({ size: 3, ...query })).unwrap()
      setLoading(false)
    } catch (error) {
      console.log('geField', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    const obj = queryString.parse(search)

    console.log(obj)

    if (obj) {
      geFields({ ...obj, page })
    } else {
      geFields({ page })
    }
  }, [page, search])

  return (
    <>
      <Helmet>
        <title>{t('Fields')} | Sensilize teams</title>
      </Helmet>

      <div className={cnFields('top')}>
        <Title className={cnFields('title')}>{t('Fields')}</Title>

        <div className={cnFields('filters')}>
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Хозяйство')}
            className={cnFields('select')}
            mb='none'
          />
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Культуры')}
            className={cnFields('select')}
            mb='none'
          />
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Фаза')}
            className={cnFields('select')}
            mb='none'
          />
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Температура')}
            className={cnFields('select')}
            mb='none'
          />
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Индекс')}
            className={cnFields('select')}
            mb='none'
          />
          <Select
            value={1}
            options={[]}
            theme='white'
            placeholder={t('Влажность')}
            className={cnFields('select')}
            mb='none'
          />
        </div>
      </div>

      {isLoading ? (
        <Loading minHeight='50vh' />
      ) : (
        <div className={cnFields('grid')}>
          {fieldList.map(field => (
            <FieldItem key={field.id} field={field} />
          ))}

          {fieldsData && fieldsData.totalPages >= 2 && (
            <Paginate
              total={fieldsData.totalPages}
              page={page}
              changePage={page => setPage(page)}
            />
          )}
        </div>
      )}
    </>
  )
})
