import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { IForecastData } from '@/core/services/Weather/models/ForecastModel'

import { Memo } from '@/hoc/Memo'

import { Icon } from '@/components/Icon'

import './WeatherDay.scss'

const cnWeatherDay = cn('WeatherDay')

interface IWeatherDay
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  weather: IForecastData | null
}

export const WeatherDay: FC<IWeatherDay> = Memo(
  ({ className, weather }: IWeatherDay) => {
    const { t } = useTranslation('translation')
    return (
      <>
        {weather && (
          <div className={cnWeatherDay(null, [className])}>
            <div className={cnWeatherDay('item')}>
              <div className={cnWeatherDay('icon')}>
                <Icon
                  name={weather.weather[0].icon}
                  className={cnWeatherDay('svg')}
                />
                {/* <img
                  src={`/img/weather/${weather.weather[0].icon}.svg`}
                  alt={weather.weather[0].description}
                  width={40}
                  height={40}
                /> */}
              </div>
              <div className={cnWeatherDay('wrap')}>
                <div className={cnWeatherDay('temp')}>
                  {Math.round(weather.main.temp)}°C
                </div>
                {/* <div className={cnWeatherDay('temp', { small: true })}>
                  {t('MIN')} {weather.}
                  {'  '}
                  {t('MAX')} {weather.main.temp_max}
                </div> */}
              </div>
            </div>
            <div className={cnWeatherDay('square', { outline: true })}>
              <div className={cnWeatherDay('icon')}>
                <Icon name='humidity' fill='white' />
              </div>
              <div className={cnWeatherDay('wrap')}>
                <div className={cnWeatherDay('text')}>{t('Humidity')}</div>
                <div className={cnWeatherDay('text')}>
                  {weather.main.humidity}%
                </div>
              </div>
            </div>
            <div className={cnWeatherDay('square')}>
              <div className={cnWeatherDay('icon')}>
                <Icon name='rain' />
              </div>
              <div className={cnWeatherDay('wrap')}>
                <div className={cnWeatherDay('text')}>
                  {t('There was no rain')}
                </div>
                <div className={cnWeatherDay('text')}>{t('Last 3 hours')}</div>
              </div>
            </div>
            <div className={cnWeatherDay('square')}>
              <div className={cnWeatherDay('icon')}>
                <Icon name='show' />
              </div>
              <div className={cnWeatherDay('wrap')}>
                <div className={cnWeatherDay('text')}>
                  {t('There was no snow')}
                </div>
                <div className={cnWeatherDay('text')}>{t('Last 3 hours')}</div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  },
)
