import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import './CurrentWeather.scss'

const cnCurrentWeather = cn('CurrentWeather')

interface ICurrentWeather
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  value: string
  isLargeValue?: string
}

export const CurrentWeather: FC<ICurrentWeather> = Memo(
  ({ className, title, value, isLargeValue }: ICurrentWeather) => {
    return (
      <div className={cnCurrentWeather(null, [className])}>
        <div className={cnCurrentWeather('value', { large: isLargeValue })}>
          {value}
        </div>
        <div className={cnCurrentWeather('key')}>{title}</div>
      </div>
    )
  },
)
