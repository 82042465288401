import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IPaginateQuery, IResponseData } from '@/core/intefaces'

import OrganizationsService from '../OrganizationsService'
import {
  OrganizationModel,
  OrganizationTypeModel,
} from '../models/OrganizationsModel'

export interface OrganizationsState {
  organizations: IResponseData<OrganizationModel[]> | null
  organization: OrganizationModel | null
  organizationTypes: OrganizationTypeModel[]
}

const initialState: OrganizationsState = {
  organizations: null,
  organization: null,
  organizationTypes: [],
}

export const getOrganizationThunk = createAsyncThunk(
  'organizations/getOrganizationThunk',
  async (query: IPaginateQuery | undefined, { rejectWithValue }) => {
    try {
      return await OrganizationsService.getOrganization(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getOrganizationTypeThunk = createAsyncThunk(
  'organizations/getOrganizationTypeThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await OrganizationsService.getOrganizationType()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getOrganizationByIdThunk = createAsyncThunk(
  'organizations/getOrganizationByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await OrganizationsService.getOrganizationById(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createOrganizationThunk = createAsyncThunk(
  'organizations/createOrganizationThunk',
  async (data: OrganizationModel, { rejectWithValue }) => {
    try {
      return await OrganizationsService.createOrganization(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateOrganizationThunk = createAsyncThunk(
  'organizations/updateOrganizationThunk',
  async (data: OrganizationModel, { rejectWithValue }) => {
    try {
      return await OrganizationsService.updateOrganization(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteOrganizationThunk = createAsyncThunk(
  'organizations/deleteOrganizationThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await OrganizationsService.deleteOrganization(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationThunk.fulfilled, (state, action) => {
        state.organizations = action.payload
      })
      .addCase(getOrganizationThunk.rejected, state => {
        state.organizations = null
      })

      .addCase(getOrganizationByIdThunk.fulfilled, (state, action) => {
        state.organization = action.payload
      })
      .addCase(getOrganizationByIdThunk.rejected, state => {
        state.organization = null
      })

      .addCase(getOrganizationTypeThunk.fulfilled, (state, action) => {
        state.organizationTypes = action.payload
      })
      .addCase(getOrganizationTypeThunk.rejected, state => {
        state.organizationTypes = []
      })
  },
})

export const { actions, reducer } = organizationsSlice
