import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IPaginateQuery, IQueryParams, IResponseData } from '@/core/intefaces'

import FarmsService from '../FarmsService'
import { FarmResponseModel, FarmsModel } from '../models/FarmsModel'

export interface FarmsState {
  farmsData: IResponseData<FarmResponseModel[]> | null
  farm: FarmResponseModel | null
}

const initialState: FarmsState = {
  farm: null,
  farmsData: null,
}

export const getFarmsThunk = createAsyncThunk(
  'farms/getFarmsThunk',
  async (
    query: IPaginateQuery | IQueryParams | undefined,
    { rejectWithValue },
  ) => {
    try {
      return await FarmsService.getFarms(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getFarmByIdThunk = createAsyncThunk(
  'farms/getFarmByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await FarmsService.getFarmById(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createFarmThunk = createAsyncThunk(
  'farms/createFarmThunk',
  async (data: FarmsModel, { rejectWithValue }) => {
    try {
      return await FarmsService.createFarm(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateFarmThunk = createAsyncThunk(
  'farms/updateFarmsThunk',
  async (data: FarmsModel, { rejectWithValue }) => {
    try {
      return await FarmsService.updateFarm(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteFarmThunk = createAsyncThunk(
  'farms/deleteFarmsThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await FarmsService.deleteFarm(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const farmsSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFarmsThunk.fulfilled, (state, action) => {
        state.farmsData = action.payload
      })
      .addCase(getFarmsThunk.rejected, state => {
        state.farmsData = null
      })

      .addCase(getFarmByIdThunk.fulfilled, (state, action) => {
        state.farm = action.payload
      })
      .addCase(getFarmByIdThunk.rejected, state => {
        state.farm = null
      })

    // .addCase(getFarmsTypeThunk.fulfilled, (state, action) => {
    //   state.organizationTypes = action.payload
    // })
    // .addCase(getFarmsTypeThunk.rejected, state => {
    //   state.organizationTypes = []
    // })
  },
})

export const { actions, reducer } = farmsSlice
