import { paths } from '@/config/paths'
import { AxiosResponse } from 'axios'

import { IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import { ISeason, ISeasonCreate } from './models/SeasonsModel'

class SeasonService {
  async getSeasons(id: string): Promise<ISeason[]> {
    const res = await http.get<IResponseData<ISeason[]>>(
      `/${paths.farm}/seasons?fieldId=${id}`,
    )

    return res.data.content
  }

  async getSeasonById(id: string): Promise<ISeason> {
    const res = await http.get<IResponseData<ISeason>>(
      `/${paths.farm}/seasons/${id}`,
    )

    return res.data.content
  }

  async createSeason(data: ISeasonCreate): Promise<ISeason> {
    const res = await http.post<ISeasonCreate, AxiosResponse<ISeason>>(
      `/${paths.farm}/seasons`,
      data,
    )

    return res.data
  }

  async updateSeason(data: ISeason): Promise<ISeason> {
    const res = await http.put<ISeason>(
      `/${paths.farm}/seasons/${data.id}`,
      data,
    )

    return res.data
  }

  async deleteSeason(id: string): Promise<void> {
    await http.delete(`/${paths.farm}/seasons/${id}`)
  }
}

export default new SeasonService()
