import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IUser } from '@/core/intefaces'

import { Memo } from '@/hoc/Memo'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Info } from '@/components/Info'
import { Users } from '@/components/Users'

import { FarmResponseModel } from '../../models/FarmsModel'
import './Farm.scss'

const cnFarm = cn('Farm')

interface IFarm {
  farm: FarmResponseModel
  className?: string
}

export const Farm: FC<IFarm> = Memo(({ className, farm }: IFarm) => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const onClick = (path: string) => {
    navigate(`/cabinet/${path}`)
  }

  const users: IUser[] = [
    {
      id: 'sdadas',
      firstName: 'Louise',
      lastName: '',
      middleName: '',
      country: '',
      phoneNumber: '',
      email: '',
      username: '',
    },
    {
      id: 'sdasadsadsadas',
      firstName: 'Thierry',
      lastName: '',
      middleName: '',
      country: '',
      phoneNumber: '',
      email: '',
      username: '',
    },
    {
      id: 'sdasadsadsadas',
      firstName: 'Gabrielle',
      lastName: '',
      middleName: '',
      country: '',
      phoneNumber: '',
      email: '',
      username: '',
    },
    {
      id: 'sdasadsadsadas',
      firstName: 'Émilie',
      lastName: '',
      middleName: '',
      country: '',
      phoneNumber: '',
      email: '',
      username: '',
    },
  ]

  return (
    <Card className={cnFarm(null, [className])}>
      <div className={cnFarm('left')}>
        <div className={cnFarm('title')}>{farm.farmName}</div>
        {farm.organizationDto && (
          <div className={cnFarm('desc')}>
            {farm.organizationDto.organizationTypeDto?.nameFull}
          </div>
        )}
      </div>
      <div className={cnFarm('actions')}>
        {farm.organizationDto.organizationName && (
          // <Button
          //   color='outline'
          //   onClick={() => onClick('farms')}
          //   className={cnFarm('btn')}
          //   icon={<Icon name='organizations' />}
          //   iconPosition='left'
          // >
          //   {organizations}
          // </Button>

          <Info
            title={farm.organizationDto.organizationName}
            iconName='farms'
            className={cnFarm('btn')}
          />
        )}

        {farm.fields.length > 0 && (
          <Button
            color='outline'
            onClick={() => onClick(`fields/?farmId=${farm.id}`)}
            className={cnFarm('btn')}
          >
            {farm.fields.length} {t('Fields')}
          </Button>
        )}
        <Users users={users} className={cnFarm('users')} />
      </div>
    </Card>
  )
})
