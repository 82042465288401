import { paths } from '@/config/paths'
import queryString from 'query-string'

import { IQueryParamsAndPaginate, IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import { IReport, ITask } from './models/TasksModel'

class TasksService {
  async getTasks(
    query: IQueryParamsAndPaginate | undefined,
  ): Promise<IResponseData<ITask[]>> {
    const str = query ? '?' + queryString.stringify(query) : ''

    const res = await http.get<IResponseData<ITask[]>>(
      `/${paths.tasks}/tasks${str}`,
    )

    return res.data
  }

  async getTask(taskId: string): Promise<ITask> {
    const res = await http.get<ITask>(`/${paths.tasks}/tasks/${taskId}`)

    return res.data
  }

  async createTask(data: ITask): Promise<ITask> {
    const res = await http.post(`/${paths.tasks}/tasks`, data)

    return res.data
  }

  async updateTask(data: ITask): Promise<ITask> {
    const res = await http.put(`/${paths.tasks}/tasks/${data.id}`, data)

    return res.data
  }

  async deleteTask(taskId: string): Promise<void> {
    await http.delete(`/${paths.tasks}/tasks/${taskId}`)
  }

  async getReports(taskId: string): Promise<IResponseData<IReport[]>> {
    const res = await http.get(`/${paths.tasks}/reports/?taskId=${taskId}`)

    return res.data
  }
}

export default new TasksService()
