import { cn } from '@bem-react/classname'
import hexToRgba from 'hex-to-rgba'
import { FC, useEffect, useState } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '@/components/Icon'

import { FieldSeasonListModel } from '@/modules/Fields/models/FieldsModel'

import './Culture.scss'

const cnCulture = cn('Culture')

interface ICulture {
  season: FieldSeasonListModel
  displayColor: string
  isSmall?: boolean
}

export const Culture: FC<ICulture> = Memo(
  ({ season, displayColor, isSmall }: ICulture) => {
    const [props, setProps] = useState<
      { stroke?: string; fill?: string } | undefined
    >(undefined)

    const arr = [
      'crop.rapeseed',
      'crop.cannabis-sativa',
      'crop.chickpea',
      'crop.cucumber',
      'crop.cucurbita-pepo',
      'crop.carrot',
      'crop.cassava',
      'crop.buckwheat',
      'crop.soybean',
      'crop.upland-cotton',
      'crop.eggplant',
      'crop.barley',
      'crop.lentil',
      'crop.flax',
      'crop.apple',
    ]

    useEffect(() => {
      if (season.cropName) {
        const iconName = season.cropKey

        if (arr.includes(iconName)) {
          setProps({ fill: displayColor })
        } else {
          setProps({ stroke: displayColor })
        }
      }
    }, [season])

    return (
      <div className={cnCulture()}>
        {season && season.cropName && (
          <div
            className={cnCulture('icon', { small: isSmall })}
            style={{
              backgroundColor: hexToRgba(displayColor, '0.1'),
            }}
          >
            <Icon name={season.cropKey} path='culture' {...props} />
          </div>
        )}

        <div className={cnCulture('agro')}>
          <div className={cnCulture('crop')}>{season.cropName}</div>
          <div className={cnCulture('text')}>{season.cropTypeName}</div>
        </div>
      </div>
    )
  },
)
