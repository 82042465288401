import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ISeason } from '@/core/services/Season/models/SeasonsModel'

import { Memo } from '@/hoc/Memo'

import { getFilterTimeUTC } from '@/utils/getFilterTimeUTC'

import { Culture } from '@/components/Culture'

import './Season.scss'

const cnSeason = cn('Season')

interface ISeasonSchema {
  season: ISeason
  color: string
  className?: string
}

export const Season: FC<ISeasonSchema> = Memo(
  ({ className, color, season }: ISeasonSchema) => {
    const { t } = useTranslation('translation')

    return (
      <>
        <div className={cnSeason(null, [className])}>
          <div className={cnSeason('el', { flex: true })}>
            {season && <Culture season={season} displayColor={color} isSmall />}
          </div>

          {season && (
            <div className={cnSeason('el')}>
              <div className={cnSeason('value')}>{season.year}</div>
              <div className={cnSeason('key')}>{t('Season')}</div>
            </div>
          )}
          {season.showingDate && (
            <div className={cnSeason('el')}>
              <div className={cnSeason('value')}>
                {getFilterTimeUTC(season.showingDate)}
              </div>
              <div className={cnSeason('key')}>{t('Showing date')}</div>
            </div>
          )}
          {season.harvestingDate && (
            <div className={cnSeason('el')}>
              <div className={cnSeason('value')}>
                {getFilterTimeUTC(season.harvestingDate)}
              </div>
              <div className={cnSeason('key')}>{t('Harvesting date')}</div>
            </div>
          )}
        </div>
      </>
    )
  },
)
