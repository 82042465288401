import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
  isChange: boolean
  isLoading: boolean
  isOpen: boolean
  visibleMaps: boolean
}

const initialState: AppState = {
  isChange: false,
  isLoading: false,
  isOpen: false,
  visibleMaps: true,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeMap: (state, action) => {
      state.isChange = action.payload
    },
    changeLoading(state, action) {
      state.isLoading = action.payload
    },
    changeOpenMenu(state, action) {
      state.isOpen = action.payload
    },
    hideOrShowMapBox: (state, action) => {
      state.visibleMaps = action.payload
    },
  },
  extraReducers: {},
})

export const { actions, reducer } = appSlice
export const { changeMap, changeLoading, changeOpenMenu, hideOrShowMapBox } =
  actions
