import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const farmsData = (state: RootState) => state.farmsReducer
export const farmsDataSelector = createSelector(
  farmsData,
  farmsReducer => farmsReducer.farmsData,
)

const farm = (state: RootState) => state.farmsReducer
export const farmSelector = createSelector(
  farm,
  farmsReducer => farmsReducer.farm,
)
