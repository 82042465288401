import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import { ETaskPriority } from '@/modules/Tasks/models/TasksModel'

import './Status.scss'

const cnStatus = cn('Status')

interface IStatus {
  className?: string
  status: ETaskPriority
}

export const Status: FC<IStatus> = Memo(({ className, status }: IStatus) => {
  const { t } = useTranslation('translation')

  const [name, setName] = useState<string>('')

  useEffect(() => {
    switch (status) {
      case ETaskPriority.CRITICAL:
        setName('Critical')
        break
      case ETaskPriority.HIGH:
        setName('High')
        break
      case ETaskPriority.LOW:
        setName('Low')
        break
      case ETaskPriority.NORMAL:
        setName('Normal')
        break
    }
  }, [status])

  return (
    <div
      className={cnStatus(
        {
          critical: status === ETaskPriority.CRITICAL,
          high: status === ETaskPriority.HIGH,
          low: status === ETaskPriority.LOW,
          normal: status === ETaskPriority.NORMAL,
        },
        [className],
      )}
    >
      {t(name)}
    </div>
  )
})
