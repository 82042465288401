import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { PrivateRoute } from '@/core/PrivateRoute'
import { changeLoadingSelector } from '@/core/services/App/store/app.selector'
import { useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Spinner } from '@/components/Spinner'
import { Toast } from '@/components/Toast'

import { Chats } from './Chats'
import { Farms } from './Farms'
import { FieldView, Fields } from './Fields'
import { Login } from './Login'
import { Organizations } from './Organizations'
import { PageNotFound } from './PageNotFound'
import { TaskView, Tasks } from './Tasks'
import { EditTask } from './Tasks/EditTask'

export const AppTeams = Memo(() => {
  const { initialized, keycloak } = useKeycloak()
  const isLoading = useAppSelector(changeLoadingSelector)

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(30)
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      if (keycloak) keycloak.onTokenExpired = () => {}
    }
  }, [initialized, keycloak])

  if (!initialized) {
    return <Spinner position='fixed' visible={!initialized} />
  }

  return (
    <>
      <Spinner position='fixed' visible={isLoading} />
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path='cabinet' element={<PrivateRoute />}>
            <Route path='organizations' element={<Organizations />} />
            <Route path='farms' element={<Farms />} />
            <Route path='farms/:id' element={<Farms />} />
            <Route path='fields' element={<Fields />} />
            <Route path='fields/:fieldId' element={<FieldView />} />
            <Route path='tasks' element={<Tasks />} />
            <Route path='tasks/:id/view' element={<TaskView />} />
            <Route path='tasks/:id/edit' element={<EditTask />} />
            <Route path='chats' element={<Chats />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toast />
    </>
  )
})
