import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import SeasonService from '../SeasonService'
import { ISeason } from '../models/SeasonsModel'
import { ISeasonCreate } from './../models/SeasonsModel'

export interface WeatherState {
  seasons: ISeason[]
  season: ISeason | null
}

const initialState: WeatherState = {
  seasons: [],
  season: null,
}

export const getSeasonsThunk = createAsyncThunk(
  'seasons/getSeasonsThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await SeasonService.getSeasons(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getSeasonByIdThunk = createAsyncThunk(
  'seasons/getSeasonByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await SeasonService.getSeasonById(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createSeasonThunk = createAsyncThunk(
  'seasons/createSeasonThunk',
  async (data: ISeasonCreate, { rejectWithValue }) => {
    try {
      return await SeasonService.createSeason(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateSeasonThunk = createAsyncThunk(
  'seasons/updateSeasonThunk',
  async (data: ISeason, { rejectWithValue }) => {
    try {
      return await SeasonService.updateSeason(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteSeasonThunk = createAsyncThunk(
  'seasons/deleteSeasonThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await SeasonService.deleteSeason(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const seasonsSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {
    resetSeasons(state) {
      state.season = null
      state.seasons = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSeasonsThunk.fulfilled, (state, action) => {
        state.seasons = action.payload
      })
      .addCase(getSeasonsThunk.rejected, state => {
        state.seasons = []
      })

      .addCase(getSeasonByIdThunk.fulfilled, (state, action) => {
        state.season = action.payload
      })
      .addCase(getSeasonByIdThunk.rejected, state => {
        state.season = null
      })
  },
})
export const { actions, reducer } = seasonsSlice
export const { resetSeasons } = actions
