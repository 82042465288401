import { paths } from '@/config/paths'

import { http } from '@/utils/http'

import { ISoil } from './models/SoilModel'

class SoilService {
  async getSoil(id: string): Promise<ISoil> {
    const res = await http.get<ISoil>(
      `/${paths.agromonitoring}/soil/?fieldId=${id}`,
    )

    return res.data
  }

  async getSoils(id: string): Promise<ISoil[]> {
    const res = await http.get<ISoil[]>(
      `/${paths.agromonitoring}/soil/history/?fieldId=${id}`,
    )

    return res.data
  }
}

export default new SoilService()
