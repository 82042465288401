export const menu = [
  {
    id: 1,
    title: 'Organizations',
    url: '/organizations',
    icon: 'organization',
  },
  {
    id: 2,
    title: 'Farms',
    url: '/farms',
    icon: 'farms',
  },
  {
    id: 3,
    title: 'Fields',
    url: '/fields',
    icon: 'fields',
  },
  {
    id: 4,
    title: 'Tasks',
    url: '/tasks',
    icon: 'tasks',
  },
  {
    id: 5,
    title: 'Chats',
    url: '/chats',
    icon: 'сhats',
  },
]
