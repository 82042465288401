/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import converter from 'javascript-binary-converter'
import {
  FC,
  KeyboardEvent,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Scrollbars, positionValues } from 'react-custom-scrollbars'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SockJS from 'sockjs-client'
import Stomp, { Frame } from 'stompjs'

import { IResponseData } from '@/core/intefaces'
import { membersSelector } from '@/core/services/Settings/store/SettingsSelector'
import { getMembersThunk } from '@/core/services/Settings/store/SettingsSlice'
import { FilePropertiesModel } from '@/core/services/Uploader/models/UploaderModel'
import {
  presignUrlThunk,
  uploadImageThunk,
} from '@/core/services/Uploader/store/UploderSlice'
import {
  IMessage,
  IRequestMessage,
  MessageType,
} from '@/core/services/Сhats/models/СhatsModel'
import {
  consultantsSelector,
  messagesSelector,
} from '@/core/services/Сhats/store/СhatsSelector'
import {
  getChatThunk,
  getMessagesThunk,
  getСonsultantsThunk,
  pushMessage,
  resetMessage,
  uploadMessage,
} from '@/core/services/Сhats/store/СhatsSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { getItem } from '@/utils/persistanceStorage'
import yup from '@/utils/yup-extended'

import { Button } from '../Button'
import { Card } from '../Card'
import { Icon } from '../Icon'
import { Photos } from '../Photos'
// import { Photos } from '../Photos'
import './Chat.scss'

const cnChat = cn('Chat')

interface ISend {
  message: string
}

interface IChat {
  className?: string
  classNameWrap?: string
  taskId: string
  recipient: string
  farmId?: string
  type?: 'default' | 'component'
  disabled?: boolean
  accept?: string
  fieldId?: string
}

let stompClient: Stomp.Client | null = null
let socket: WebSocket | null = null

const acceptImage = ['image/png', 'image/jpeg', 'image/jpg']

export const Chat: FC<IChat> = Memo(
  ({
    className,
    classNameWrap,
    taskId,
    recipient,
    farmId,
    type = 'default',
    disabled,
    fieldId,
    accept = 'image/png, image/jpeg, image/jpg',
  }: IChat) => {
    const { t } = useTranslation('translation')
    const inputReference = createRef<HTMLInputElement>()
    const dispatch = useAppDispatch()
    const messages = useAppSelector(messagesSelector)
    const members = useAppSelector(membersSelector)
    const consultants = useAppSelector(consultantsSelector)

    const [chatId, setChatId] = useState<string>('')
    const [scrollbars, setScrollbars] = useState<Scrollbars | null>(null)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [messageType, setMessageType] = useState<MessageType>('MESSAGE')
    const [images, setImages] = useState<string>('')

    const generateFullName = (id: string) => {
      const user = members.find(el => el.id === id)
      const consultant = consultants.find(el => el.id === id)

      if (user) {
        return `${user.firstName} ${user.lastName}`
      }

      if (consultant) {
        return `${consultant.name} ${consultant.surname}`
      }

      return ''
    }

    const getСonsultants = async () => {
      try {
        await dispatch(getСonsultantsThunk()).unwrap()
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    const getMembers = async () => {
      if (!farmId) return

      try {
        await dispatch(getMembersThunk(farmId)).unwrap()
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    const getChat = async () => {
      if (!taskId) return

      try {
        await dispatch(getChatThunk({ taskId })).unwrap()
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    useEffect(() => {
      getСonsultants()
      getChat()
      getMembers()
      dispatch(resetMessage())
      const str = 'https://chat.sensilize.com' //process.env.REACT_APP_API_URL + `/chat-service`

      connect(str)

      return () => {
        if (stompClient) {
          stompClient.disconnect((): void => {
            console.log('disconnect')
            stompClient?.unsubscribe('/user/topic/private')

            setIsLoading(false)
          })
        }

        if (socket) {
          socket.close()
        }

        dispatch(resetMessage())
      }
    }, [])

    useEffect(() => {
      if (messages.length && scrollbars && currentPage === 0) {
        scrollbars.scrollToBottom()
      }
    }, [messages, scrollbars])

    const connect = (url: string) => {
      const token = getItem('token')
      if (!token) return

      socket = new SockJS(url + `/messages?token=${token}`)
      stompClient = Stomp.over(socket)

      stompClient.connect({ taskId: taskId }, onConnected, onError)
    }

    const onError = (err: string | Frame) => {
      console.log('onError', err)

      setIsLoading(false)
    }

    const onConnected = useCallback(() => {
      if (!stompClient) return

      stompClient.subscribe('/user/topic/private', onMessageReceived)

      setIsLoading(false)
    }, [messages])

    const onMessageReceived = useCallback(
      (msgs: Frame) => {
        const res: IResponseData<IMessage[]> = JSON.parse(msgs.body)
        // let data: IMessage[] = []
        // if (res && res.content) {
        //   data = res.content.map(el => {
        //     if (el.message.indexOf('https://ams3') !== -1) {
        //       return {
        //         ...el,
        //         messageType: 'IMAGE',
        //       }
        //     }

        //     return {
        //       ...el,
        //       messageType: 'MESSAGE',
        //     }
        //   })
        // }

        if (res && res.content && res.content.length) {
          dispatch(pushMessage(res.content.reverse()))
        } else {
          dispatch(pushMessage([res]))
        }

        setIsLoading(false)

        if (res && res.content && res.content.length) {
          const el = res.content[0]

          setChatId(el.chatId)
          setTotalPages(res.totalPages)
          setCurrentPage(res.number)
        }
      },
      [messages],
    )

    const resetData = () => {
      reset()
      setMessageType('MESSAGE')
      setImages('')
    }

    const Schema = yup.object().shape({
      message: yup.string().trim().required(t('This field is required')),
    })

    const {
      handleSubmit,
      reset,
      formState: { isSubmitting },
      register,
      setValue,
    } = useForm<ISend>({
      resolver: yupResolver(Schema),
      mode: 'onChange',
    })

    const onSubmit = (data: ISend) => {
      if (!stompClient) return

      const message: IRequestMessage = {
        message: data.message,
        taskId: taskId,
        messageType,
      }

      stompClient.send('/app/messages', {}, JSON.stringify(message))

      if (scrollbars) {
        setTimeout(() => {
          scrollbars.scrollToBottom()
        }, 300)
      }

      resetData()
    }

    const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey == false) {
        return handleSubmit(onSubmit)()
      }
    }

    const getMessages = async () => {
      if (!chatId) return

      try {
        const res = await dispatch(
          getMessagesThunk({
            chatId,
            query: { size: 15, page: currentPage + 1 },
          }),
        ).unwrap()

        if (res) {
          setCurrentPage(res.number)
          setTotalPages(res.totalPages)

          setLoading(false)

          dispatch(uploadMessage(res.content.reverse()))
        }
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    const handleScroll = (value: positionValues) => {
      if (
        value.top < 1 &&
        !loading &&
        totalPages > 1 &&
        totalPages !== currentPage + 1
      ) {
        setLoading(true)
        getMessages()
      }
    }

    const changeInputFile = async (files: FileList | null) => {
      if (!files) return
      if (!fieldId) return

      const file: File = files[0]

      if (acceptImage.includes(file.type)) {
        setMessageType('IMAGE')
      }

      const data: FilePropertiesModel = {
        '0': file.type,
        '1': fieldId,
        '2': 'task',
      }

      const res = await dispatch(
        uploadImageThunk({ fileProperties: data }),
      ).unwrap()

      if (res) {
        const str = await converter(file).toUint8Array()

        await dispatch(
          presignUrlThunk({
            type: file.type,
            presignUrl: res.presignUrl,
            file: str,
          }),
        )

        setValue('message', res.accessUrl)
        setImages(res.accessUrl)

        // reset(values => {
        //   let arr = values.message.split(', ')

        //   console.log(arr)

        //   arr = [...arr, res.accessUrl]

        //   setImages(arr.join(', '))

        //   return {
        //     message: arr.join(', '),
        //   }
        // })
      }
    }

    const openWindow = () => {
      if (inputReference && inputReference.current) {
        inputReference.current.click()
      }
    }

    const resetImage = () => {
      // let array = images.split(', ')
      // const index = array.indexOf(i + '')

      // array = array.slice(index, 1)

      // reset(() => {
      //   return {
      //     message: array.join(', '),
      //   }
      // })

      resetData()
    }

    console.log('messages', messages)

    return (
      <>
        <Card
          className={cnChat(
            { component: type === 'component', default: type === 'default' },
            [className],
          )}
        >
          <div className={cnChat('title')}>{t('Chat')}</div>
          <div className={cnChat('wrap', [classNameWrap])}>
            <Scrollbars
              onScrollFrame={handleScroll}
              ref={scrollbar => !isLoading && setScrollbars(scrollbar)}
              className={cnChat('scrollbar')}
            >
              <>
                {(loading || isLoading) && (
                  <div className={cnChat('loading', { full: isLoading })}>
                    <Icon name='discuss' />
                  </div>
                )}

                {!isLoading &&
                  messages.map((el, i) => (
                    <>
                      {el.messageType === 'MESSAGE' && (
                        <div
                          key={i + '-message'}
                          className={cnChat('item', {
                            you: el.createdBy === recipient,
                          })}
                        >
                          <div
                            className={cnChat('text')}
                            dangerouslySetInnerHTML={{ __html: el.message }}
                          ></div>
                          <div className={cnChat('date')}>
                            {dayjs(el.createdAt).format('DD.MM.YYYY HH:mm')}
                          </div>

                          {el.createdBy &&
                            Boolean(
                              generateFullName(el.createdBy as string),
                            ) && (
                              <div className={cnChat('user')}>
                                <Icon name='user' fill='black' />
                                {generateFullName(el.createdBy as string)}
                              </div>
                            )}
                        </div>
                      )}

                      {el.messageType === 'IMAGE' && (
                        <div
                          key={i + '-image'}
                          className={cnChat('item', {
                            you: el.createdBy === recipient,
                          })}
                        >
                          <Photos
                            className={cnChat('photos')}
                            items={[el.message]}
                            isAdd={false}
                          />
                          <div className={cnChat('date')}>
                            {dayjs(el.createdAt).format('DD.MM.YYYY HH:mm')}
                          </div>

                          {el.createdBy &&
                            Boolean(
                              generateFullName(el.createdBy as string),
                            ) && (
                              <div className={cnChat('user')}>
                                <Icon name='user' fill='black' />
                                {generateFullName(el.createdBy as string)}
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  ))}
              </>
            </Scrollbars>
          </div>

          <div className={cnChat('title')}>{t('Enter your message')}</div>

          <form className={cnChat('form')} onSubmit={handleSubmit(onSubmit)}>
            <div className={cnChat('group')}>
              {messageType === 'MESSAGE' && (
                <textarea
                  className={cnChat('textarea')}
                  onKeyDown={onKeyDown}
                  disabled={disabled}
                  {...register('message')}
                ></textarea>
              )}

              {messageType === 'IMAGE' && images && (
                <Photos
                  items={[images]}
                  isAdd={false}
                  isChat
                  resetImage={resetImage}
                />
              )}

              <button
                type='button'
                className={cnChat('add')}
                disabled={disabled}
                onClick={openWindow}
              >
                <Icon name='add' />
              </button>

              <input
                type='file'
                name='file'
                id='file'
                ref={inputReference}
                className={cnChat('file')}
                accept={accept}
                onChange={e => changeInputFile(e.target.files)}
              />
            </div>
            <Button
              type='submit'
              fluid
              isLoading={isSubmitting}
              disabled={disabled}
            >
              {t('Send')}
            </Button>
          </form>
        </Card>
      </>
    )
  },
)
