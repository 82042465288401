import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import WeatherService from '../WeatherService'
import { IForecastData } from '../models/ForecastModel'
import { IFilterWeather } from '../models/WeatherModel'

export interface WeatherState {
  weather: IForecastData | null
  forecast: IForecastData[]
  current: IForecastData[]
  uvi: number
}

const initialState: WeatherState = {
  weather: null,
  forecast: [],
  current: [],
  uvi: 0,
}

export const getFieldWeatherThunk = createAsyncThunk(
  'weather/getFieldWeatherThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await WeatherService.getFieldWeather(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getFieldForecastThunk = createAsyncThunk(
  'weather/getFieldForecastThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await WeatherService.getFieldForecast(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getCurrentWeatherThunk = createAsyncThunk(
  'weather/getCurrentWeatherThunk',
  async (data: IFilterWeather, { rejectWithValue }) => {
    try {
      return await WeatherService.getCurrentWeather(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getCurrentUVIThunk = createAsyncThunk(
  'weather/getCurrentUVIThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await WeatherService.getCurrentUVI(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    resetWeather(state) {
      state.weather = null
      state.forecast = []
      state.current = []
      state.uvi = 0
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFieldWeatherThunk.fulfilled, (state, action) => {
        state.weather = action.payload
      })
      .addCase(getFieldWeatherThunk.rejected, state => {
        state.weather = null
      })
      .addCase(getFieldForecastThunk.fulfilled, (state, action) => {
        state.forecast = action.payload
      })
      .addCase(getFieldForecastThunk.rejected, state => {
        state.forecast = []
      })

      .addCase(getCurrentWeatherThunk.fulfilled, (state, action) => {
        state.current = action.payload
      })
      .addCase(getCurrentWeatherThunk.rejected, state => {
        state.current = []
      })

      .addCase(getCurrentUVIThunk.fulfilled, (state, action) => {
        state.uvi = action.payload.uvi
      })
      .addCase(getCurrentUVIThunk.rejected, state => {
        state.uvi = 0
      })
  },
})
export const { actions, reducer } = weatherSlice
export const { resetWeather } = actions
