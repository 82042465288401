import { paths } from '@/config/paths'
import axios, { AxiosHeaders, AxiosResponse } from 'axios'

import { http } from '@/utils/http'

import {
  UploadUrlRequestModel,
  UploaderRequestModel,
  UploaderResponseModel,
} from './models/UploaderModel'

class UploaderService {
  async upload(data: UploaderRequestModel): Promise<UploaderResponseModel> {
    const res = await http.post<
      UploaderRequestModel,
      AxiosResponse<UploaderResponseModel>
    >(`/${paths.uploader}/upload`, data)

    return res.data
  }

  async presignUrl(data: UploadUrlRequestModel): Promise<void> {
    const headers: AxiosHeaders = new AxiosHeaders()

    headers.setContentType(data.type)
    headers.setAccept(data.type)

    console.log(headers)

    await axios.put(`${data.presignUrl}`, data.file, {
      headers: {
        'Content-Type': data.type,
        Accept: data.type,
      },
    })

    // await http.put(`${data.presignUrl}`, data.file, {
    //   headers,
    // })
  }
}

export default new UploaderService()
