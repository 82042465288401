import { cn } from '@bem-react/classname'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'

// import { isIOS } from 'react-device-detect'
import { Memo } from '@/hoc/Memo'

import './Button.scss'

export type ButtonType = 'button' | 'submit'
export type ButtonColorType = 'outline' | 'dark' | 'red'

const cnButton = cn('Button')

export interface ButtonShema
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode
  fluid?: boolean
  type?: ButtonType
  color?: ButtonColorType
  isLoading?: boolean
  iconPosition?: 'left' | 'right'
  icon?: ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Button: FC<ButtonShema> = Memo(
  ({
    disabled,
    isLoading,
    type = 'button',
    children,
    color = 'dark',
    fluid,
    className,
    id,
    iconPosition,
    icon,
    onClick,
    onMouseEnter,
    onMouseLeave,
  }: ButtonShema) => {
    return (
      <>
        <button
          id={id}
          className={cnButton(
            {
              outline: color === 'outline',
              dark: color === 'dark',
              red: color === 'red',
              loading: isLoading,
              fluid,
              // ios: isIOS,
            },
            [className],
          )}
          type={type}
          onClick={() => {
            onClick && onClick()
          }}
          onMouseEnter={() => {
            onMouseEnter && onMouseEnter()
          }}
          onMouseLeave={() => {
            onMouseLeave && onMouseLeave()
          }}
          disabled={disabled || isLoading}
        >
          {icon && iconPosition === 'left' && (
            <span className={cnButton('icon', { left: true })}>{icon}</span>
          )}

          {children}

          {icon && iconPosition === 'right' && (
            <span className={cnButton('icon', { right: true })}>{icon}</span>
          )}
        </button>
      </>
    )
  },
)
