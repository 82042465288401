import { IVegetationIndicesData } from './VegetationIndices'

export const ndvi: IVegetationIndicesData[] = [
  {
    value: -0.2,
    color: [0, 0, 0],
  },
  {
    value: 0,
    color: [0.65, 0, 0.15],
  },
  {
    value: 0.1,
    color: [0.84, 0.19, 0.15],
  },
  {
    value: 0.2,
    color: [0.96, 0.43, 0.26],
  },
  {
    value: 0.3,
    color: [0.99, 0.68, 0.38],
  },
  {
    value: 0.4,
    color: [1, 0.88, 0.55],
  },
  {
    value: 0.5,
    color: [1, 1, 0.75],
  },
  {
    value: 0.6,
    color: [0.85, 0.94, 0.54],
  },
  {
    value: 0.7,
    color: [0.65, 0.85, 0.42],
  },
  {
    value: 0.8,
    color: [0.4, 0.74, 0.39],
  },
  {
    value: 0.9,
    color: [0.1, 0.6, 0.31],
  },
  {
    value: 1,
    color: [0, 0.41, 0.22],
  },
  {
    value: null,
    color: [1, 1, 1],
  },
]

export const evi: IVegetationIndicesData[] = [
  {
    value: -1.1,
    color: [0, 0, 0],
  },
  {
    value: -0.2,
    color: [0.75, 0.75, 0.75],
  },
  {
    value: -0.1,
    color: [0.86, 0.86, 0.86],
  },
  {
    value: 0,
    color: [1, 1, 0.88],
  },
  {
    value: 0.05,
    color: [1, 0.98, 0.8],
  },
  {
    value: 0.1,
    color: [0.93, 0.91, 0.71],
  },
  {
    value: 0.15,
    color: [0.87, 0.85, 0.61],
  },
  {
    value: 0.2,
    color: [0.8, 0.78, 0.51],
  },
  {
    value: 0.25,
    color: [0.74, 0.72, 0.42],
  },

  {
    value: 0.3,
    color: [0.69, 0.76, 0.38],
  },
  {
    value: 0.35,
    color: [0.64, 0.8, 0.35],
  },
  {
    value: 0.4,
    color: [0.57, 0.75, 0.32],
  },

  {
    value: 0.5,
    color: [0.5, 0.7, 0.28],
  },
  {
    value: 0.6,
    color: [0.44, 0.64, 0.25],
  },
  {
    value: 0.7,
    color: [0.38, 0.59, 0.21],
  },
  {
    value: 0.8,
    color: [0.31, 0.54, 0.18],
  },
  {
    value: 0.9,
    color: [0.25, 0.49, 0.14],
  },
  {
    value: 1,
    color: [0.19, 0.43, 0.11],
  },
  {
    value: 1.2,
    color: [0.13, 0.38, 0.07],
  },

  {
    value: 1.3,
    color: [0.06, 0.33, 0.04],
  },

  {
    value: 1.3,
    color: [0, 0.27, 0],
  },
  {
    value: null,
    color: [1, 1, 1],
  },
]

export const gli: IVegetationIndicesData[] = [
  {
    value: -1.0,
    color: [0.61, 0.33, 0.12],
  },
  {
    value: -0.66,
    color: [0.75, 0.53, 0.31],
  },
  {
    value: -0.33,
    color: [0.88, 0.75, 0.53],
  },
  {
    value: 0.0,
    color: [1.0, 1.0, 0.75],
  },
  {
    value: 0.33,
    color: [0.73, 0.83, 0.69],
  },
  {
    value: 0.66,
    color: [0.45, 0.68, 0.63],
  },
  {
    value: 0.66,
    color: [0.15, 0.55, 0.36],
  },

  {
    value: null,
    color: [1, 1, 1],
  },
]

export const msi: IVegetationIndicesData[] = [
  {
    value: 0.2,
    color: [0.01, 0.31, 0.52],
  },

  {
    value: 0.7,
    color: [0.5, 1.0, 0.83],
  },
  {
    value: 1.2,
    color: [0.25, 0.36, 0.15],
  },
  {
    value: 1.7,
    color: [0.51, 0.72, 0.29],
  },
  {
    value: 2.2,
    color: [1.0, 0.48, 0.15],
  },
  {
    value: 2.2,
    color: [1.0, 0.8, 0.36],
  },

  {
    value: null,
    color: [1, 1, 1],
  },
]

export const osavi: IVegetationIndicesData[] = [
  {
    value: 0,
    color: [0.69, 0.88, 0.9],
  },

  {
    value: 0.05,
    color: [0.74, 0.72, 0.42],
  },
  {
    value: 0.1,
    color: [0.64, 0.8, 0.35],
  },
  {
    value: 0.2,
    color: [0.57, 0.75, 0.32],
  },

  {
    value: 0.3,
    color: [0.6, 0.8, 0.19],
  },

  {
    value: 0.4,
    color: [0.13, 0.63, 0.13],
  },

  {
    value: 0.5,
    color: [0.13, 0.55, 0.13],
  },
  {
    value: 0.6,
    color: [0, 0.5, 0],
  },

  {
    value: 0.7,
    color: [0, 0.39, 0],
  },

  {
    value: 0.7,
    color: [0, 0.29, 0],
  },

  {
    value: null,
    color: [1, 1, 1],
  },
]

export const gci: IVegetationIndicesData[] = [
  {
    value: 0,
    color: [0, 0, 0],
  },
  {
    value: 0.2,
    color: [0.92, 0.97, 0.93],
  },

  {
    value: 0.4,
    color: [0.83, 0.94, 0.88],
  },
  {
    value: 0.6,
    color: [0.66, 0.87, 0.75],
  },

  {
    value: 0.8,
    color: [0.49, 0.81, 0.63],
  },

  {
    value: 1,
    color: [0.32, 0.75, 0.5],
  },
  {
    value: 1.5,
    color: [0.15, 0.68, 0.38],
  },
  {
    value: 2,
    color: [0.13, 0.6, 0.33],
  },
  {
    value: 2.5,
    color: [0.12, 0.52, 0.29],
  },
  {
    value: 3,
    color: [0.1, 0.44, 0.24],
  },
  {
    value: 3,
    color: [0.08, 0.36, 0.2],
  },

  {
    value: null,
    color: [1, 1, 1],
  },
]

export const rgb: IVegetationIndicesData[] = [
  {
    value: 0,
    color: [0, 0, 0],
  },

  {
    value: null,
    color: [1, 1, 1],
  },
]
