/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react'
import UISelect, { PropsValue, StylesConfig, components } from 'react-select'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

import { TypeMargin, TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Select.scss'

const cnSelect = cn('Select')

export type SelectType = 'text' | 'number' | 'password' | 'email'
export type SelectThemeType = 'default' | 'white'
export interface SelectOption {
  [key: string]: string | number
}

export interface ISelect {
  options: any[]
  value: any
  optionValue?: string
  optionLabel?: string
  uid?: string
  label?: string
  name?: string
  errors?: string
  placeholder?: string
  className?: string
  classNameInput?: string
  classNameLabel?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  iconLeft?: ReactNode | string
  iconRight?: ReactNode | string
  noArrow?: boolean
  onChange?: (e: any) => void
  handleOnBlur?: (e?: SyntheticEvent) => void
  handleCustomChange?: (
    e?: ChangeEvent<HTMLInputElement> | number | string,
  ) => void
  sizeInput?: TypeSize
  mb?: TypeMargin
  theme?: SelectThemeType
  info?: string
  defaultValue?: PropsValue<any>
  returnValue?: string
  isMulti?: boolean
}

export const Select: FC<ISelect> = Memo(
  ({
    options,
    value,
    optionValue = 'value',
    optionLabel = 'label',
    uid = 'select-' + uuidv4(),
    label,
    // name,
    required,
    errors,
    placeholder,
    // readonly = false,
    className,
    classNameLabel,
    disabled,
    iconLeft,
    iconRight,
    // noArrow,
    // sizeInput = 'md',
    mb = 'sm',
    info,
    theme = 'default',
    defaultValue,
    returnValue,
    onChange,
    handleCustomChange,
    isMulti,
  }: ISelect) => {
    const [initValue, setInitValue] = useState<any>()

    useEffect(() => {
      if (value && options && options.length && !isMulti) {
        switch (true) {
          case typeof value === 'string' && isNaN(+value):
            {
              const v = options.find(el => el[optionValue] === value)

              setInitValue(v)
            }
            break
          case typeof value === 'number':
            {
              const v = options.find(el => +el[optionValue] === +value)
              setInitValue(v)
            }
            break

          default:
            setInitValue(value)
            break
        }
      }
    }, [value, options])

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon name='chevron-down' fill='#3499E6' />
        </components.DropdownIndicator>
      )
    }

    const handleOptionValue = (option: any) => {
      return optionValue && option[optionValue]
    }

    const handleOptionLabel = (option: any) => {
      return optionLabel && option[optionLabel]
    }

    const colourStyles: StylesConfig = {
      control: () => ({}),
      option: () => ({}),
      input: () => ({}),
      placeholder: () => ({}),
      singleValue: () => ({}),
      valueContainer: () => ({}),
      container: () => ({}),
      indicatorSeparator: () => ({}),
      indicatorsContainer: () => ({}),
      dropdownIndicator: () => ({}),
      menu: () => ({}),
      menuList: () => ({}),
    }

    return (
      <div
        className={cnSelect(
          {
            xxl: mb === 'xxl',
            xl: mb === 'xl',
            lg: mb === 'lg',
            md: mb === 'md',
            sm: mb === 'sm',
            none: mb === 'none',
          },
          [className],
        )}
      >
        {!!label && (
          <label
            htmlFor={uid}
            className={cnSelect(
              'label',
              {
                required,
              },
              [classNameLabel],
            )}
          >
            {label}
          </label>
        )}
        <div className={cnSelect('wrap')}>
          {iconLeft && (
            <div className={cnSelect('icon', { left: true })}>{iconLeft}</div>
          )}

          <UISelect
            defaultValue={defaultValue}
            styles={colourStyles}
            value={initValue}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            getOptionValue={handleOptionValue}
            getOptionLabel={handleOptionLabel}
            onChange={(e: any) => {
              onChange && onChange(e)
              returnValue &&
                handleCustomChange &&
                handleCustomChange(e[returnValue])
            }}
            className={cnSelect('basic', {
              default: theme === 'default',
              white: theme === 'white',
              // ios: isIOS,
              error: info || !!errors,
              multi:
                isMulti && Array.isArray(initValue) && initValue.length > 0,
            })}
            classNamePrefix={cnSelect('el')}
            components={{
              DropdownIndicator,
            }}
            isMulti={isMulti}
            // menuIsOpen={true}
          />

          {iconRight && !errors && !info && (
            <div
              className={cnSelect('icon', {
                right: true,
              })}
            >
              {iconRight}
            </div>
          )}

          {(info || !!errors) && (
            <>
              <div
                id={'tooltip' + uid}
                className={cnSelect('icon', {
                  right: true,
                })}
                data-tooltip-content={info || errors}
                data-tooltip-place='bottom'
              >
                <Icon name='info' fill={info ? '#687283' : '#E24A4A'} />
              </div>
              <ReactTooltip
                events={['click', 'hover']}
                anchorId={'tooltip' + uid}
                className={cnSelect('tooltip')}
              />
            </>
          )}
        </div>
      </div>
    )
  },
)
