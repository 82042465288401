/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IQueryParams } from '@/core/intefaces'

import ChatsService from '../ChatsService'
import { IConsultant, IMessage, IRequestMessages } from '../models/СhatsModel'

export interface ChatsState {
  consultants: IConsultant[]
  messages: IMessage[]
}

const initialState: ChatsState = {
  consultants: [],
  messages: [],
}

export const getChatThunk = createAsyncThunk(
  'chats/getChatThunk',
  async (query: IQueryParams | undefined, { rejectWithValue }) => {
    try {
      return await ChatsService.getChat(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getСonsultantsThunk = createAsyncThunk(
  'chats/getСonsultantsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await ChatsService.getСonsultants()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getMessagesThunk = createAsyncThunk(
  'chats/getMessagesThunk',
  async (data: IRequestMessages, { rejectWithValue }) => {
    try {
      return await ChatsService.getMessages(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const setHelpThunk = createAsyncThunk(
  'chats/setHelpThunk',
  async (chatId: string, { rejectWithValue }) => {
    try {
      return await ChatsService.setHelp(chatId)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    pushMessage(state, action) {
      state.messages = [...state.messages, ...action.payload]
    },

    uploadMessage(state, action) {
      state.messages = [...action.payload, ...state.messages]
    },
    resetMessage(state) {
      state.messages = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getСonsultantsThunk.fulfilled, (state, action) => {
        state.consultants = action.payload
      })
      .addCase(getСonsultantsThunk.rejected, state => {
        state.consultants = []
      })
  },
})
export const { actions, reducer } = chatsSlice
export const { pushMessage, resetMessage, uploadMessage } = actions
