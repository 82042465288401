/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const removeEmpty = (obj: any, all = true): any => {
  if (all) {
    return (
      Object.keys(obj)
        .filter(key => obj[key])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((newObj: any, key) => {
          newObj[key] = obj[key]
          return newObj
        }, {})
    )
  } else {
    const res = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v !== ''),
    )

    return res
  }
}
