import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import './Menu.scss'
import { menu } from './data'

const cnMenu = cn('Menu')

interface IMenu {
  className?: string
}

export const Menu: FC<IMenu> = Memo(({ className }: IMenu) => {
  const { t } = useTranslation('translation')

  return (
    <nav className={cnMenu(null, [className])}>
      <ul className={cnMenu('list')}>
        {menu.map(el => (
          <li key={el.id} className={cnMenu('item')}>
            <NavLink
              to={'/cabinet' + el.url}
              className={({ isActive }) => cnMenu('link', { active: isActive })}
            >
              {t(el.title)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
})
