import { ReactKeycloakProvider } from '@react-keycloak/web/lib/provider'
import { FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import i18n from '@/core/i18n'

import { Memo } from '@/hoc/Memo'

import { store } from './core/store'
import keycloak from './keycloak'
import { AppTeams } from './modules'
import { setItem } from './utils/persistanceStorage'

export const App: FC = Memo(() => {
  return (
    <>
      <ReactKeycloakProvider
        authClient={keycloak}
        // onEvent={eventLogger}
        onTokens={({ token }) => {
          if (token) {
            setItem('token', token)
          }
        }}
        initOptions={{
          checkLoginIframe: false,
        }}
      >
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <AppTeams />
          </I18nextProvider>
        </Provider>
      </ReactKeycloakProvider>
    </>
  )
})
