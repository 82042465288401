import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const user = (state: RootState) => state.settingsReducer
export const userSelector = createSelector(
  user,
  settingsReducer => settingsReducer.user,
)

const members = (state: RootState) => state.settingsReducer
export const membersSelector = createSelector(
  members,
  settingsReducer => settingsReducer.members,
)
