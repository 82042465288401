import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const tasksData = (state: RootState) => state.tasksReducer
export const tasksDataSelector = createSelector(
  tasksData,
  tasksReducer => tasksReducer.tasksData,
)

const tasks = (state: RootState) => state.tasksReducer
export const tasksSelector = createSelector(
  tasks,
  tasksReducer => tasksReducer.tasks,
)

const task = (state: RootState) => state.tasksReducer
export const taskSelector = createSelector(
  task,
  tasksReducer => tasksReducer.task,
)

const reports = (state: RootState) => state.tasksReducer
export const reportsSelector = createSelector(
  reports,
  tasksReducer => tasksReducer.reports,
)
