import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import { IForecastHourly } from '@/core/services/Weather/models/ForecastModel'

import { Memo } from '@/hoc/Memo'

import './ChartArea.scss'

const cnChartArea = cn('ChartArea')

interface IChartArea
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: IForecastHourly[] //IForecastData[]
}

export const ChartArea: FC<IChartArea> = Memo(
  ({ className, data }: IChartArea) => {
    const uid = 'ChartArea'

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [list, setList] = useState<any[]>([])
    const [maxValue, setMaxValue] = useState<number>(0)

    am5.addLicense('AM5C378450555')

    // console.log('list', list)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const generateData = (data: any[]) => {
      const arr = data
        .filter(el => dayjs().isSame(dayjs(el.dt * 1000), 'day'))
        .map(el => {
          const val = Math.round(el.temp)
          return {
            date: el.dt * 1000,
            value: String(val) === '-0' || String(val) === '0' ? 0 : val,
            icon: el.weather[0].icon,
          }
        })

      setList(arr.filter((el, i) => i < 24))

      const maxVals: number[] = arr
        .map(el => el.value)
        .sort((a, b) => {
          return (a as number) - (b as number)
        })
        .reverse()

      if (maxVals.length) {
        // const length = maxVals[0].toFixed(0).length
        setMaxValue(maxVals[0] + 10)
        // setMaxValue(maxVals[0] + bigIntager(length - 1))
      }
    }

    useEffect(() => {
      generateData(data)
    }, [data])

    useLayoutEffect(() => {
      if (data.length) {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        const root = am5.Root.new(uid)

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)])

        // root.dateFormatter.setAll({
        //   dateFormat: 'HH',
        //   dateFields: ['valueX'],
        // })

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            // panX: true,
            // panY: true,
            // wheelX: 'panX',
            // wheelY: 'zoomX',

            layout: root.verticalLayout,
          }),
        )

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        const xRenderer = am5xy.AxisRendererX.new(root, {})
        // xRenderer.grid.template.set('forceHidden', true)
        // xRenderer.grid.template.set('location', 0.5)
        // xRenderer.labels.template.setAll({
        //   location: 1,
        //   multiLocation: 0.5,
        //   fontSize: 10,
        //   fontWeight: '600',
        //   fontFamily: 'Montserrat',
        //   paddingTop: 15,
        // })

        const xAxis = chart.xAxes.push(
          // am5xy.CategoryDateAxis.new(root, {
          //   baseInterval: { timeUnit: 'hour', count: 1 },
          //   categoryField: 'date',
          //   renderer: xRenderer,
          // }),
          am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'hour', count: 1 },
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
          }),
        )

        const yRenderer = am5xy.AxisRendererY.new(root, {})
        yRenderer.grid.template.set('forceHidden', true)
        yRenderer.labels.template.set('minPosition', 0.5)

        yRenderer.ticks.template.setAll({
          visible: false,
        })

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxPrecision: 0,
            extraMin: 0.1,
            visible: false,
            max: maxValue ? maxValue : undefined,
            renderer: yRenderer,
          }),
        )

        const series = chart.series.push(
          am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            // maskBullets: false,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'vertical',
              dy: -20,
              labelText: '{valueY}',
            }),
          }),
        )

        series.fills.template.setAll({
          fillOpacity: 0.1,
          visible: true,
        })

        series.strokes.template.setAll({
          visible: false,
        })

        let i = -1
        series.bullets.push(function () {
          i++

          // if (i > 24) {
          //   i = 0
          // }

          const element = list[i]

          const container = am5.Container.new(root, {
            centerX: am5.percent(50),
            centerY: am5.percent(220),
            // centerY: element.value >= 0 ? am5.percent(220) : am5.percent(-10),
          })

          container.children.push(
            am5.Picture.new(root, {
              centerX: am5.p50,
              centerY: am5.p50,
              width: 20,
              height: 21,
              src: `/img/icons/${element.icon}.svg`,
            }),
          )

          return am5.Bullet.new(root, {
            sprite: container,
          })
        })

        series.bullets.push(function (root) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              text: '{valueY}' + '°',
              centerX: am5.percent(50),
              centerY: am5.percent(100),
              populateText: true,
              fontSize: 10,
              fontWeight: '600',
              fontFamily: 'Montserrat',
            }),
          })
        })

        series.set('fill', am5.color('#3499E6'))

        series.data.setAll(list)
        series.appear(1000)

        // add scrollbar
        const scrollbarX = am5.Scrollbar.new(root, {
          orientation: 'horizontal',
          height: 6,
          maxHeight: 6,
          start: 0.4,
          end: 1,
          cursorOverStyle: 'grub',
        })

        scrollbarX.get('background')?.setAll({
          fill: am5.color('#f5f5f7'),
          fillOpacity: 1,
          height: 6,
          maxHeight: 6,
        })

        scrollbarX.thumb.setAll({
          fill: am5.color('#e0e0e0'),
          fillOpacity: 0.8,
          height: 6,
          y: 50,
        })

        scrollbarX.startGrip.setAll({
          visible: false,
        })

        scrollbarX.endGrip.setAll({
          visible: false,
        })
        chart.set('scrollbarX', scrollbarX)
        chart.bottomAxesContainer.children.push(scrollbarX)

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100)

        return () => {
          root.dispose()
        }
      }
    }, [list])

    return (
      <div className={cnChartArea(null, [className])}>
        <div className={cnChartArea('wrap')} id={uid}></div>
      </div>
    )
  },
)
