import { paths } from '@/config/paths'

import { IResponseData } from '@/core/intefaces'

import { http } from '@/utils/http'

import { ISatelliteImage } from './models/SatelliteImageModel'

class SatelliteService {
  async getSatelliteImage(query: string): Promise<ISatelliteImage | null> {
    const res = await http.get<IResponseData<ISatelliteImage[]>>(
      `/${paths.satellite}/images${query}`,
    )

    return res.data.content.length ? res.data.content[0] : null
  }

  async getCurrentDate(query: string): Promise<string[]> {
    const res = await http.get<string[]>(
      `/${paths.satellite}/images/dates${query}`,
    )

    return res.data
  }
}

export default new SatelliteService()
