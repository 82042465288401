import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IUser } from '@/core/intefaces'

import { Memo } from '@/hoc/Memo'

import './Users.scss'

const cnUsers = cn('Users')

interface IUsers {
  className?: string
  users?: IUser[]
}

export const Users: FC<IUsers> = Memo(({ className, users }: IUsers) => {
  const { t } = useTranslation('translation')

  const randomColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16)
  }

  return (
    <div className={cnUsers(null, [className])}>
      <div className={cnUsers('group')}>
        {users?.map((el, i) => (
          <div
            key={i}
            className={cnUsers('item')}
            style={{
              backgroundColor: '#' + randomColor(),
            }}
          >
            {el.firstName.charAt(0).toUpperCase()}
          </div>
        ))}
      </div>
      <div className={cnUsers('count')}>
        {users?.length} {t('users')}
      </div>
    </div>
  )
})
