import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak'
import { FC } from 'react'
import { Navigate } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

export const Login: FC = Memo(() => {
  const { keycloak } = useKeycloak()

  if (keycloak?.authenticated) {
    return <Navigate to={'/cabinet/organizations'} />
  } else {
    const redirect = keycloak.createLoginUrl()

    window.location.replace(redirect)
  }

  return <div></div>
})
