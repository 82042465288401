import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IPaginateQuery, IQueryParams, IResponseData } from '@/core/intefaces'

import FieldsService from '../FieldsService'
import {
  FieldCoordinatesModel,
  FieldCreateModel,
  FieldResponseDataModel,
  InitMapModel,
  ModeMapBoxType,
} from './../models/FieldsModel'

export interface FieldsState {
  fieldsData: IResponseData<FieldResponseDataModel[]> | null
  fields: FieldResponseDataModel[]
  field: FieldResponseDataModel | null
  fieldsToMaps: FieldResponseDataModel[]
  coordinates: FieldCoordinatesModel[]
  initEditMap: InitMapModel | null
  mode: ModeMapBoxType | null
  isLoading: boolean
}

const initialState: FieldsState = {
  fieldsData: null,
  fields: [],
  field: null,
  fieldsToMaps: [],
  coordinates: [],
  initEditMap: null,
  mode: null,
  isLoading: false,
}

export const getFieldsThunk = createAsyncThunk(
  'fields/getFieldsThunk',
  async (
    query: IPaginateQuery | IQueryParams | undefined,
    { rejectWithValue },
  ) => {
    try {
      return await FieldsService.getFields(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getFieldByIdThunk = createAsyncThunk(
  'fields/getFieldByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await FieldsService.getField(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createFieldThunk = createAsyncThunk(
  'fields/createFieldThunk',
  async (data: FieldCreateModel, { rejectWithValue }) => {
    try {
      return await FieldsService.createField(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteFieldThunk = createAsyncThunk(
  'fields/deleteFieldThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      return await FieldsService.deleteField(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    createOrUpdateCoordinates(state, action) {
      state.coordinates = action.payload
    },
    initEdittingMap(state, action) {
      state.initEditMap = action.payload
    },
    setFieldsToMaps(state, action) {
      state.fieldsToMaps = action.payload
    },

    changeModeMapBox(state, { payload }: { payload: ModeMapBoxType | null }) {
      state.mode = payload
    },

    resetField(state) {
      state.field = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getFieldsThunk.fulfilled,
        (
          state,
          action: PayloadAction<IResponseData<FieldResponseDataModel[]>>,
        ) => {
          state.fields = action.payload.content
          state.fieldsData = action.payload
        },
      )
      .addCase(getFieldsThunk.rejected, state => {
        state.fields = []
        state.fieldsData = null
      })

      .addCase(getFieldByIdThunk.pending, state => {
        state.isLoading = true
      })
      .addCase(getFieldByIdThunk.fulfilled, (state, action) => {
        state.field = action.payload
        state.isLoading = false
      })
      .addCase(getFieldByIdThunk.rejected, state => {
        state.field = null
        state.isLoading = false
      })
  },
})

export const { actions, reducer } = fieldsSlice
export const {
  createOrUpdateCoordinates,
  initEdittingMap,
  setFieldsToMaps,
  changeModeMapBox,
  resetField,
} = actions
