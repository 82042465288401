import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const fieldsData = (state: RootState) => state.fieldsReducer
export const fieldsDataSelector = createSelector(
  fieldsData,
  fieldsReducer => fieldsReducer.fieldsData,
)

const fields = (state: RootState) => state.fieldsReducer
export const fieldsSelector = createSelector(
  fields,
  fieldsReducer => fieldsReducer.fields,
)

const field = (state: RootState) => state.fieldsReducer
export const fieldSelector = createSelector(
  field,
  fieldsReducer => fieldsReducer.field,
)

const fieldsToMaps = (state: RootState) => state.fieldsReducer
export const fieldsToMapsSelector = createSelector(
  fieldsToMaps,
  fieldsReducer => fieldsReducer.fieldsToMaps,
)

const coordinates = (state: RootState) => state.fieldsReducer
export const coordinatesSelector = createSelector(
  coordinates,
  fieldsReducer => fieldsReducer.coordinates,
)

const initEditMap = (state: RootState) => state.fieldsReducer
export const initMapSelector = createSelector(
  initEditMap,
  fieldsReducer => fieldsReducer.initEditMap,
)

const mode = (state: RootState) => state.fieldsReducer
export const modeMapBoxSelector = createSelector(
  mode,
  fieldsReducer => fieldsReducer.mode,
)

const isLoading = (state: RootState) => state.fieldsReducer
export const isLoadingSelector = createSelector(
  isLoading,
  fieldsReducer => fieldsReducer.isLoading,
)
