import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak'
import { FC, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { Private } from '@/components/Private'

import { getUserThunk } from './services/Settings/store/SettingsSlice'
import { useAppDispatch } from './store/hooks'

export const PrivateRoute: FC = Memo(() => {
  const { keycloak } = useKeycloak()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      getUser()
    }
  }, [keycloak])

  const getUser = async () => {
    try {
      await dispatch(getUserThunk()).unwrap()
    } catch (errors) {
      console.log('getUserThunk', errors)
    }
  }

  return keycloak && keycloak.authenticated ? (
    <Private>
      <Outlet />
    </Private>
  ) : (
    <Navigate to={'/'} />
  )
})
