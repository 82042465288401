import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import { Memo } from '@/hoc/Memo'

import './Card.scss'

const cnCard = cn('Card')

interface ICard
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const Card: FC<ICard> = Memo(({ children, className }: ICard) => {
  return <div className={cnCard(null, [className])}>{children}</div>
})
