import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import SettingsService from '../SettingsService'
import { UserGroupsModel, UserModel } from '../models/UserModel'

export interface UserState {
  user: UserModel | null
  members: UserModel[]
}

const initialState: UserState = {
  user: null,
  members: [],
}

export const getUserThunk = createAsyncThunk(
  'settings/getUserThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await SettingsService.getUser()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateUserThunk = createAsyncThunk(
  'settings/updateUserThunk',
  async (user: UserModel, { rejectWithValue }) => {
    try {
      return await SettingsService.updateUser(user)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getMembersThunk = createAsyncThunk(
  'settings/getMembersThunk',
  async (groupName: string | undefined, { rejectWithValue }) => {
    try {
      return await SettingsService.getMembers(groupName)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteGroupThunk = createAsyncThunk(
  'settings/deleteGroupThunk',
  async (groupName: string, { rejectWithValue }) => {
    try {
      return await SettingsService.deleteGroup(groupName)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const leaveGroupThunk = createAsyncThunk(
  'settings/leaveGroupThunk',
  async (data: UserGroupsModel, { rejectWithValue }) => {
    try {
      return await SettingsService.leaveGroup(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const inviteUserThunk = createAsyncThunk(
  'settings/inviteUserThunk',
  async (data: UserGroupsModel, { rejectWithValue }) => {
    try {
      return await SettingsService.inviteUser(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    editUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(getUserThunk.rejected, state => {
        state.user = null
      })

      .addCase(getMembersThunk.fulfilled, (state, action) => {
        state.members = action.payload
      })
      .addCase(getMembersThunk.rejected, state => {
        state.members = []
      })
  },
})

export const { actions, reducer } = settingsSlice
export const { editUser } = actions
