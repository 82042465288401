import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const satelliteImage = (state: RootState) => state.satelliteReducer
export const satelliteImageSelector = createSelector(
  satelliteImage,
  satelliteReducer => satelliteReducer.satelliteImage,
)

const dates = (state: RootState) => state.satelliteReducer
export const datesSelector = createSelector(
  dates,
  satelliteReducer => satelliteReducer.dates,
)
