import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IQueryParamsAndPaginate, IResponseData } from '@/core/intefaces'

import TasksService from '../TasksService'
import { IReport, ITask } from '../models/TasksModel'

export interface TaskState {
  tasksData: IResponseData<ITask[]> | null
  reports: IResponseData<IReport[]> | null
  tasks: ITask[]
  task: ITask | null
}

const initialState: TaskState = {
  tasksData: null,
  reports: null,
  tasks: [],
  task: null,
}

export const getReportsThunk = createAsyncThunk(
  'tasks/getReportsThunk',
  async (taskId: string, { rejectWithValue }) => {
    try {
      return await TasksService.getReports(taskId)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getTasksThunk = createAsyncThunk(
  'tasks/getTasksThunk',
  async (query: IQueryParamsAndPaginate | undefined, { rejectWithValue }) => {
    try {
      return await TasksService.getTasks(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getTaskThunk = createAsyncThunk(
  'tasks/getTaskThunk',
  async (taskId: string, { rejectWithValue }) => {
    try {
      return await TasksService.getTask(taskId)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const createTaskThunk = createAsyncThunk(
  'tasks/createTaskThunk',
  async (data: ITask, { rejectWithValue }) => {
    try {
      return await TasksService.createTask(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateTaskThunk = createAsyncThunk(
  'tasks/updateTaskThunk',
  async (data: ITask, { rejectWithValue }) => {
    try {
      return await TasksService.updateTask(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteTaskThunk = createAsyncThunk(
  'tasks/deleteTaskThunk',
  async (taskId: string, { rejectWithValue }) => {
    try {
      return await TasksService.deleteTask(taskId)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetTasks(state) {
      state.tasksData = null
      state.task = null
      state.tasks = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getTasksThunk.fulfilled,
        (state, { payload }: PayloadAction<IResponseData<ITask[]>>) => {
          state.tasksData = payload
          state.tasks = payload.content
        },
      )
      .addCase(getTasksThunk.rejected, state => {
        state.tasksData = null
        state.tasks = []
      })

      .addCase(getTaskThunk.fulfilled, (state, action) => {
        state.task = action.payload
      })
      .addCase(getTaskThunk.rejected, state => {
        state.task = null
      })

      .addCase(getReportsThunk.fulfilled, (state, action) => {
        state.reports = action.payload
      })
      .addCase(getReportsThunk.rejected, state => {
        state.reports = null
      })
  },
})
export const { actions, reducer } = tasksSlice
export const { resetTasks } = actions
