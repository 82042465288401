import geo2svg from 'geo2svg'

import { FieldCoordinatesModel } from '@/modules/Fields/models/FieldsModel'

export const generateSvgImage = (
  coordinates: FieldCoordinatesModel[],
  displayColor: string,
) => {
  const arr = coordinates.map(el => {
    return [+el.longitude, +el.latitude]
  })

  const option = {
    size: [48, 48], // size[0] is svg width, size[1] is svg height
    padding: [0, 0, 0, 0], // paddingTop, paddingRight, paddingBottom, paddingLeft, respectively
    output: 'string', // output type: 'string' | 'element'(only supported in browser)
    precision: 10, // svg coordinates precision
    stroke: displayColor, // stroke color
    strokeWidth: '1px', // stroke width
    //background: '#ccc', // svg background color, and as the fill color of polygon hole
    fill: displayColor, // fill color
    fillOpacity: 0.3, // fill opacity
    //radius: 10, // only for `Point`, `MultiPoint`
  }
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [arr],
        },
      },
    ],
  }
  const svgStr = geo2svg(geojson, option)

  return svgStr
}
