import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { hideOrShowMapBox } from '@/core/services/App/store/app.slice'
import { membersSelector } from '@/core/services/Settings/store/SettingsSelector'
import { getMembersThunk } from '@/core/services/Settings/store/SettingsSlice'
import { setToast } from '@/core/services/Toast/store/toast.slice'
import { ToastType } from '@/core/services/Toast/types/ToastType'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import yup from '@/utils/yup-extended'

import { Button } from '@/components/Button'
import { DatePicker } from '@/components/DatePicker'
import { Field } from '@/components/Field'
import { Icon } from '@/components/Icon'
import { MapBox } from '@/components/MapBox'
import { Select } from '@/components/Select'
import { TextArea } from '@/components/TextArea'
import { Title } from '@/components/Title'
import { Top } from '@/components/Top'

import { FieldCoordinatesModel } from '../Fields/models/FieldsModel'
import { fieldSelector } from '../Fields/store/FieldsSelector'
import { getFieldByIdThunk } from '../Fields/store/FieldsSlice'
import { ETaskStatus, ETaskType, ITask } from './models/TasksModel'
import { taskSelector } from './store/TasksSelector'
import { getTaskThunk, updateTaskThunk } from './store/TasksSlice'
import './styles/EditTask.scss'

type Params = {
  id: string
}

const cnEditTask = cn('EditTask')

export const EditTask: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams<Params>()
  const field = useAppSelector(fieldSelector)
  const members = useAppSelector(membersSelector)
  const task = useAppSelector(taskSelector)

  // const [mode, setMode] = useState<any>(new EditingMode())
  // const [features, setFeatures] = useState<Array<any>>([])
  const [markers, setMarkers] = useState<FieldCoordinatesModel[]>([])
  const [priorities] = useState<Array<{ id: string; label: string }>>([
    { id: 'CRITICAL', label: t('Critical') },
    { id: 'HIGH', label: t('High') },
    { id: 'LOW', label: t('Low') },
    { id: 'NORMAL', label: t('Normal') },
  ])

  useEffect(() => {
    dispatch(hideOrShowMapBox(false))
    return () => {
      dispatch(hideOrShowMapBox(true))
    }
  }, [])

  useEffect(() => {
    if (id) {
      getTaskById(id)
    }
  }, [id])

  useEffect(() => {
    if (task) {
      reset(task)
      setValue('createdAt', dayjs(task.createdAt).format('DD.MM.YYYY'))
      setMarkers(task.coordinates)
    }
  }, [task])

  const getTaskById = async (id: string) => {
    try {
      const res = await dispatch(getTaskThunk(id)).unwrap()

      await getFieldById(res.fieldId)
      await getMembers(res.farmId)
    } catch (errors) {
      console.log('getFieldById', errors)
    }
  }

  const getFieldById = async (id: string) => {
    try {
      await dispatch(getFieldByIdThunk(id)).unwrap()
    } catch (errors) {
      console.log('getFieldById', errors)
    }
  }

  const getMembers = async (id: string) => {
    try {
      await dispatch(getMembersThunk(id)).unwrap()
    } catch (errors) {
      console.log('getMembers', errors)
    }
  }

  const Schema = yup.object().shape({
    priority: yup.string().trim().required(t('This field is required')),
    assignee: yup.string().trim().required(t('This field is required')),
    description: yup.string().trim().required(t('This field is required')),
    endTime: yup.string().trim().required(t('This field is required')),
  })

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    control,
    reset,
    setValue,
  } = useForm<ITask>({
    // defaultValues: new FieldCreateModel(),
    resolver: yupResolver(Schema),
    mode: 'onChange',
  })

  const onClickBtn = () => {
    navigate(-1)
  }

  const saveTask = async (data: ITask) => {
    if (markers.length === 0) {
      dispatch(
        setToast({
          type: ToastType.error,
          message: t(`Field not drawn`),
          position: 'top-center',
        }),
      )

      return
    }

    data.coordinates = markers

    switch (true) {
      case data.coordinates.length === 1:
        data.type = ETaskType.PIN
        break
      case data.coordinates.length >= 2:
        data.type = ETaskType.POLYGON
        break
      default:
        data.type = ETaskType.VEGETATION_POLYGON
        break
    }

    data.status = ETaskStatus.ACTUAL
    delete data.createdAt

    try {
      const res = await dispatch(updateTaskThunk(data)).unwrap()

      dispatch(
        setToast({
          type: ToastType.success,
          message: t(`The task {{name}} has been successfully {{action}}`, {
            name: `${res.name}`,
            action: t('created'),
          }),
        }),
      )

      // navigate(`/cabinet/fields/${res.fieldId}/edit`)
    } catch (errors) {
      console.log('getMembers', errors)
    }
  }

  // const onClickToMap = (e: mapboxgl.MapLayerMouseEvent) => {
  //   const coords = [
  //     ...markers,
  //     {
  //       longitude: e.lngLat.lng,
  //       latitude: e.lngLat.lat,
  //     },
  //   ]

  //   setMarkers(coords)
  // }

  return (
    <>
      <Helmet>
        <title>{t('Edit task')} | Sensilize</title>
      </Helmet>
      <div className={cnEditTask('content')}>
        <div className={cnEditTask('wrap')}>
          <Top type='close' onClickBtn={onClickBtn}>
            <Title>{t('Edit task')}</Title>
          </Top>

          <form onSubmit={handleSubmit(saveTask)}>
            <div className={cnEditTask('step')}>
              <Field
                placeholder={t('Task id')}
                theme='white'
                {...register('serialNumber')}
                readOnly
                disabled
                mb='none'
              />
            </div>

            <div className={cnEditTask('step')}>
              <div className={cnEditTask('title')}>{t('Date of creation')}</div>
              <Field
                theme='white'
                {...register('createdAt')}
                readOnly
                disabled
              />
            </div>

            <div className={cnEditTask('step')}>
              <div className={cnEditTask('title')}>{t('Responsible')}</div>
              <Controller
                name={`assignee`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={members}
                    value={value}
                    handleCustomChange={onChange}
                    placeholder={t('Select responsible')}
                    optionLabel='firstName'
                    optionValue='id'
                    returnValue='id'
                    theme='white'
                    className={cnEditTask('select')}
                    errors={errors.assignee && errors.assignee.message}
                    mb='none'
                  />
                )}
              />
            </div>

            <div className={cnEditTask('step')}>
              <div className={cnEditTask('title')}>{t('Task priority')}</div>
              <Controller
                name={`priority`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={priorities}
                    value={value}
                    handleCustomChange={onChange}
                    placeholder={t('Select priority')}
                    optionLabel='label'
                    optionValue='id'
                    returnValue='id'
                    theme='white'
                    className={cnEditTask('select')}
                    errors={errors.priority && errors.priority.message}
                    mb='none'
                  />
                )}
              />
            </div>

            <div className={cnEditTask('step')}>
              <div className={cnEditTask('title')}>
                {t('The due date of the task')}
              </div>

              <Controller
                name={`endTime`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <DatePicker
                    name={name}
                    value={value as Date}
                    onChange={onChange}
                    placeholder={t('Date')}
                    className={cnEditTask('select')}
                    theme='white'
                    iconRight={<Icon name='calendar' />}
                    errors={errors.endTime && errors.endTime.message}
                  />
                )}
              />
            </div>

            <div className={cnEditTask('step')}>
              <div className={cnEditTask('title')}>{t('Task description')}</div>

              <TextArea
                theme='white'
                placeholder={t('Problem')}
                {...register(`description`)}
                errors={errors.description && errors.description.message}
                mb='none'
              />
            </div>

            <Button type='submit' fluid isLoading={isSubmitting}>
              {t('Edit task')}
            </Button>
          </form>
        </div>

        {field && task && (
          <MapBox
            className={cnEditTask('mapbox')}
            centroid={field.centroid}
            fieldsList={[field]}
            zoom={13}
            mode={'view'}
            style={{
              height: '100%',
            }}
            tasks={[task]}
            // markers={task && task.type === ETaskType.PIN ? markers : undefined}
            // onClick={onClickToMap}
          ></MapBox>
        )}
      </div>
    </>
  )
})
