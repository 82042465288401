import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'

import './Organization.scss'

const cnOrganization = cn('Organization')

interface IOrganization {
  id: string
  name: string
  text: string
  className?: string
  farms?: number
  fields?: number
  peoples?: number
}

export const Organization: FC<IOrganization> = Memo(
  ({ id, name, text, className, farms, fields, peoples }: IOrganization) => {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const onClick = (path: string) => {
      navigate(`/cabinet/${path}/?organizationId=${id}`)
    }

    return (
      <Card className={cnOrganization(null, [className])}>
        <div className={cnOrganization('left')}>
          <div className={cnOrganization('title')}>{name}</div>
          <div className={cnOrganization('desc')}>{text}</div>
        </div>
        <div className={cnOrganization('actions')}>
          {/* {farms && ( */}
          <Button
            color='outline'
            onClick={() => onClick(`farms`)}
            className={cnOrganization('btn')}
          >
            {/* {farms}  */}
            {t('Farms')}
          </Button>
          {/* )} */}
          {/* {fields && ( */}
          <Button
            color='outline'
            onClick={() => onClick(`fields`)}
            className={cnOrganization('btn')}
          >
            {/* {fields}  */}
            {t('Fields')}
          </Button>
          {/* )} */}
          {peoples && (
            <Button
              color='outline'
              onClick={() => onClick('peoples')}
              className={cnOrganization('btn')}
            >
              {peoples} {t('Peoples')}
            </Button>
          )}
        </div>
      </Card>
    )
  },
)
