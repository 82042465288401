import { combineReducers } from '@reduxjs/toolkit'

import { reducer as toastReducer } from '@/core/services//Toast/store/toast.slice'
import { reducer as appReducer } from '@/core/services/App/store/app.slice'
import { reducer as satelliteReducer } from '@/core/services/Satellite/store/SatelliteSlice'
import { reducer as seasonsReducer } from '@/core/services/Season/store/SeasonsSlice'
import { reducer as settingsReducer } from '@/core/services/Settings/store/SettingsSlice'
import { reducer as soilReducer } from '@/core/services/Soil/store/SoilSlice'
import { reducer as uploaderReducer } from '@/core/services/Uploader/store/UploderSlice'
import { reducer as weatherReducer } from '@/core/services/Weather/store/WeatherSlice'
import { reducer as chatsReducer } from '@/core/services/Сhats/store/СhatsSlice'

import { reducer as farmsReducer } from '@/modules/Farms/store/FarmsSlice'
import { reducer as fieldsReducer } from '@/modules/Fields/store/FieldsSlice'
import { reducer as organizationsReducer } from '@/modules/Organizations/store/OrganizationsSlice'
import { reducer as tasksReducer } from '@/modules/Tasks/store/TasksSlice'

const rootReducer = combineReducers({
  appReducer,
  toastReducer,
  weatherReducer,
  chatsReducer,
  uploaderReducer,
  settingsReducer,
  satelliteReducer,
  soilReducer,
  farmsReducer,
  tasksReducer,
  fieldsReducer,
  seasonsReducer,
  organizationsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
