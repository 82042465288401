import { paths } from '@/config/paths'

import { http } from '@/utils/http'

import { UserGroupsModel, UserModel } from './models/UserModel'

class SettingsService {
  async getUser(): Promise<UserModel> {
    const res = await http.get<UserModel>(`/${paths.user}/users/me`)

    return res.data
  }

  async updateUser(user: UserModel): Promise<UserModel> {
    const { id } = user
    const res = await http.put(`/${paths.user}/users/update/${id}`, user)

    return res.data
  }

  async getMembers(groupName: string | undefined): Promise<UserModel[]> {
    const q = groupName ? groupName : ''
    const res = await http.get(`/${paths.user}/groups/getMembers/${q}`)

    return res.data
  }

  async deleteGroup(groupName: string): Promise<void> {
    await http.get(`/${paths.user}/groups/${groupName}`)
  }

  async leaveGroup(data: UserGroupsModel): Promise<void> {
    await http.post(`/${paths.user}/groups/leave`, data)
  }

  async inviteUser(data: UserGroupsModel): Promise<void> {
    await http.post(`/${paths.user}/groups/inviteUser`, data)
  }
}

export default new SettingsService()
