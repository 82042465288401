import { cn } from '@bem-react/classname'
import { FC } from 'react'

import { Memo } from '@/hoc/Memo'

import './Paginator.scss'
import { PaginatorModel } from './PaginatorModel'

const cnPaginator = cn('Paginator')

interface IPaginator {
  className?: string
  list: PaginatorModel[]
  onClick: (id: number | string) => void
  active: number | string
  isPadding?: boolean
  isPaddingBtn?: boolean
}

export const Paginator: FC<IPaginator> = Memo(
  ({
    className,
    list,
    onClick,
    active,
    isPadding = true,
    isPaddingBtn = false,
  }: IPaginator) => {
    return (
      <div className={cnPaginator({ padding: isPadding }, [className])}>
        {list.map(el => (
          <button
            key={el.id}
            onClick={() => onClick(el.id)}
            type='button'
            className={cnPaginator('btn', {
              padding: isPaddingBtn,
              active: el.id === active,
            })}
          >
            {el.title}
          </button>
        ))}
      </div>
    )
  },
)
