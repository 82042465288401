import { paths } from '@/config/paths'

import { http } from '@/utils/http'

import { IForecastData } from './models/ForecastModel'
import { IFilterWeather, IUVI } from './models/WeatherModel'

class WeatherService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getFieldWeather(id: string): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = await http.get<any>(
      `/${paths.agromonitoring}/weather?fieldId=${id}`,
    )

    return res.data
  }

  async getFieldForecast(id: string): Promise<IForecastData[]> {
    const res = await http.get<IForecastData[]>(
      `/${paths.agromonitoring}/weather/forecast?fieldId=${id}`,
    )

    return res.data
  }

  async getCurrentWeather({
    fieldId,
    historyPeriodEnum,
    start,
    end,
  }: IFilterWeather): Promise<IForecastData[]> {
    const query = historyPeriodEnum
      ? `&historyPeriodEnum=${historyPeriodEnum}`
      : start && end
      ? `&start=${start}&end=${end}`
      : ''

    const res = await http.get<IForecastData[]>(
      `/${paths.agromonitoring}/weather/history?fieldId=${fieldId}${query}`,
    )

    return res.data
  }

  async getCurrentUVI(id: string): Promise<IUVI> {
    const res = await http.get<IUVI>(
      `/${paths.agromonitoring}/uvi?fieldId=${id}`,
    )

    return res.data
  }
}

export default new WeatherService()
