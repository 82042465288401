import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { motion } from 'framer-motion'

import './Loading.scss'

const cnLoading = cn('Loading')

interface ILoading
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: 'text' | 'line' | 'circle'
  minHeight?: string
}

export const Loading: FC<ILoading> = ({
  className,
  type = 'text',
  minHeight = '100vh',
}: ILoading) => {
  return (
    <div style={{ minHeight }} className={cnLoading(null, [className])}>
      {type === 'text' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 2 } }}
          className={cnLoading('wrap')}
        >
          <div>G</div>
          <div>N</div>
          <div>I</div>
          <div>D</div>
          <div>A</div>
          <div>O</div>
          <div>L</div>
        </motion.div>
      )}

      {type === 'line' && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='80px'
          height='80px'
          viewBox='0 0 100 100'
          preserveAspectRatio='xMidYMid'
        >
          <rect x='17.5' y='30' width='15' height='40' fill='#80f0aa'>
            <animate
              attributeName='y'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='18;30;30'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
              begin='-0.25s'
            ></animate>
            <animate
              attributeName='height'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='64;40;40'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
              begin='-0.25s'
            ></animate>
          </rect>
          <rect x='42.5' y='30' width='15' height='40' fill='#96f8bb'>
            <animate
              attributeName='y'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='20.999999999999996;30;30'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
              begin='-0.125s'
            ></animate>
            <animate
              attributeName='height'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='58.00000000000001;40;40'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
              begin='-0.125s'
            ></animate>
          </rect>
          <rect x='67.5' y='30' width='15' height='40' fill='#b5facf'>
            <animate
              attributeName='y'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='20.999999999999996;30;30'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
            ></animate>
            <animate
              attributeName='height'
              repeatCount='indefinite'
              dur='1.25s'
              calcMode='spline'
              keyTimes='0;0.5;1'
              values='58.00000000000001;40;40'
              keySplines='0 0.5 0.5 1;0 0.5 0.5 1'
            ></animate>
          </rect>
        </svg>
      )}

      {type === 'circle' && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='80px'
          height='80px'
          viewBox='0 0 100 100'
          preserveAspectRatio='xMidYMid'
        >
          <path
            d='M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50'
            fill='#ffffff'
            stroke='none'
          >
            <animateTransform
              attributeName='transform'
              type='rotate'
              dur='1s'
              repeatCount='indefinite'
              keyTimes='0;1'
              values='0 50 51;360 50 51'
            ></animateTransform>
          </path>
        </svg>
      )}
    </div>
  )
}
