import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { datesSelector } from '@/core/services/Satellite/store/SatelliteSelector'
import { useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { DatePicker } from '@/components/DatePicker'

import { Icon } from '../Icon'
import './VegetationIndices.scss'
import { evi, gci, gli, msi, ndvi, osavi, rgb } from './data'

const cnVegetationIndices = cn('VegetationIndices')

type IndexType = 'NDVI' | 'EVI' | 'GLI' | 'MSI' | 'OSAVI' | 'GCI' | 'RGB'

export interface IVegetationIndicesData {
  value: number | null
  color: number[]
}

interface IVegetationIndices {
  onClickIndex: (str: string) => void
  onSetDate: (date: string | Date) => void
  className?: string
}

export const VegetationIndices: FC<IVegetationIndices> = Memo(
  ({ className, onSetDate, onClickIndex }: IVegetationIndices) => {
    const { t } = useTranslation('translation')
    const includeDates = useAppSelector(datesSelector)

    const [dates, setDates] = useState<string[]>([])
    const [arr, setArr] = useState<IVegetationIndicesData[]>([])
    const [isVisible, setIsVisible] = useState(false)
    const [active, setActive] = useState<IndexType>('NDVI')
    const [date, setDate] = useState<string | Date>(new Date())
    const buttons: IndexType[] = [
      'NDVI',
      'EVI',
      'GLI',
      'MSI',
      'OSAVI',
      'GCI',
      'RGB',
    ]

    useEffect(() => {
      // const arr = includeDates.map(el => dayjs(new Date(el)).unix())
      // .filter((el, i) => i <= 9)
      // .reverse()

      setDates(includeDates)
    }, [includeDates])

    const loadIndex = () => {
      switch (active) {
        case 'EVI':
          setArr(evi)
          break

        case 'GLI':
          setArr(gli)
          break
        case 'MSI':
          setArr(msi)
          break
        case 'OSAVI':
          setArr(osavi)
          break
        case 'GCI':
          setArr(gci)
          break
        case 'RGB':
          setArr(rgb)
          break

        default:
          setArr(ndvi)
          break
      }
    }

    useEffect(() => {
      loadIndex()
    }, [active])

    useEffect(() => {
      if (isVisible) {
        onClickIndex(active)
      }
    }, [isVisible])

    const onChangeDate = (date: string | Date) => {
      setDate(date)
      onSetDate(date)
    }

    const onClick = (el: IndexType) => {
      setActive(el)
      onClickIndex(el)
    }

    const generateColor = (colors: number[]): string => {
      const rgbColor = colors.map(el => (el * 255).toFixed()).join(', ')

      return `rgb(${rgbColor})`
    }

    return (
      <div className={cnVegetationIndices(null, [className])}>
        <div className={cnVegetationIndices('top')}>
          <div className={cnVegetationIndices('title')}>
            {t('Vegetation Indices')}
          </div>
          <button
            type='button'
            className={cnVegetationIndices('arr')}
            onClick={() => setIsVisible(!isVisible)}
          >
            <Icon
              name='arrow-down'
              className={cnVegetationIndices('icon', { open: isVisible })}
            />
          </button>
        </div>
        <div className={cnVegetationIndices('text')}>
          {t('Select field to view vegetation indices')}
        </div>

        <AnimatePresence>
          {isVisible && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: '100%',
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0, height: 0 }}
              className={cnVegetationIndices('info')}
            >
              <div className={cnVegetationIndices('content')}>
                <div className={cnVegetationIndices('buttons')}>
                  {buttons.map(el => (
                    <button
                      key={el}
                      className={cnVegetationIndices('btn', {
                        active: el === active,
                      })}
                      onClick={() => onClick(el)}
                    >
                      {el}
                    </button>
                  ))}
                </div>
                <div className={cnVegetationIndices('colors')}>
                  {arr.map((el, i) => (
                    <div
                      key={i}
                      className={cnVegetationIndices('item', {
                        margin: active === 'RGB',
                      })}
                    >
                      <div
                        className={cnVegetationIndices('square')}
                        style={{
                          backgroundColor: generateColor(el.color),
                        }}
                      ></div>

                      {el.value || el.value === 0 ? (
                        <div
                          className={cnVegetationIndices('value', {
                            opacity: i === arr.length - 2 && active !== 'RGB',
                            none: active === 'RGB',
                          })}
                        >
                          {el.value.toFixed(2)}
                        </div>
                      ) : (
                        <div className={cnVegetationIndices('value')}>
                          {t('Clouds')}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* {dates && dates.length > 0 && ( */}
              <DatePicker
                name={'date'}
                onChange={onChangeDate}
                className={cnVegetationIndices('select')}
                theme='gray'
                iconRight={<Icon name='calendar' />}
                mb='none'
                value={date}
                minDate={new Date(dayjs().subtract(10, 'years').toDate())}
                maxDate={new Date()}
                // showMonthYearPicker
                // includeDates={dates as unknown as Date[]}
                filterDates={dates}
              />
              {/* )} */}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  },
)
