import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { FC } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Icon } from '@/components/Icon'
import { Status } from '@/components/Status'

import { ITask } from '../../models/TasksModel'
import './TaskItem.scss'

const cnTaskItem = cn('TaskItem')

interface ITaskItem {
  className?: string
  organizationsName?: string
  farmName?: string
  fieldName?: string
  task?: ITask
}

export const TaskItem: FC<ITaskItem> = Memo(
  ({ className, organizationsName, farmName, fieldName, task }: ITaskItem) => {
    const navigate = useNavigate()

    const onClick = (path: string) => {
      navigate(`/cabinet/${path}`)
    }

    return (
      <Card className={cnTaskItem(null, [className])}>
        {task && (
          <div className={cnTaskItem('left')}>
            <NavLink
              to={`/cabinet/tasks/${task.id}/view`}
              className={cnTaskItem('title')}
            >
              #task{task.serialNumber}
            </NavLink>
            <div className={cnTaskItem('desc')}>{task.description}</div>
            <div className={cnTaskItem('bottom')}>
              <div className={cnTaskItem('day')}>
                {dayjs(task.endTime).format('MMM DD, YYYY HH:mm')}
              </div>
              <Status status={task.priority} />
            </div>
          </div>
        )}
        <div className={cnTaskItem('actions')}>
          {organizationsName && (
            <Button
              color='outline'
              onClick={() => onClick('farms')}
              className={cnTaskItem('btn')}
              icon={<Icon name='organizations' />}
              iconPosition='left'
            >
              {organizationsName}
            </Button>
          )}
          {farmName && (
            <Button
              color='outline'
              onClick={() => onClick('fields')}
              className={cnTaskItem('btn')}
              icon={<Icon name='farms' />}
              iconPosition='left'
            >
              {farmName}
            </Button>
          )}
          {fieldName && (
            <Button
              color='outline'
              onClick={() => onClick('fields')}
              className={cnTaskItem('btn')}
              icon={<Icon name='fields' />}
              iconPosition='left'
            >
              {fieldName}
            </Button>
          )}
        </div>
      </Card>
    )
  },
)
