import { cn } from '@bem-react/classname'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { userSelector } from '@/core/services/Settings/store/SettingsSelector'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Accordion } from '@/components/Accordion/Accordion'
import { Chat } from '@/components/Chat'
import { Paginator } from '@/components/Paginator'
import { Title } from '@/components/Title'

import { fieldsSelector } from '../Fields/store/FieldsSelector'
import { getFieldsThunk } from '../Fields/store/FieldsSlice'
import { ETaskStatus, ITask } from '../Tasks/models/TasksModel'
import { tasksSelector } from '../Tasks/store/TasksSelector'
import { getTasksThunk } from '../Tasks/store/TasksSlice'
import { Message } from './components/Message/Message'
import { IMessageGroup, IMessages } from './models/ChatsModels'
import './styles/Chats.scss'

const cnChats = cn('Chats')

export const Chats: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const tasks = useAppSelector(tasksSelector)
  const fields = useAppSelector(fieldsSelector)
  const user = useAppSelector(userSelector)

  const [activeTab, setActiveTab] = useState<number>(1)
  const [messages, setMessages] = useState<IMessageGroup[]>([])
  const [task, setTask] = useState<ITask | null>(null)

  const listPager = [
    { id: 1, title: t('Actual') },
    { id: 2, title: t('Archive') },
  ]

  const loadData = async () => {
    try {
      const res = await dispatch(
        getTasksThunk({ size: 2000, page: 0 }),
      ).unwrap()

      const arrayStrs = res.content.map(el => el.fieldId)

      if (arrayStrs.length) {
        await dispatch(getFieldsThunk({ fieldIds: arrayStrs })).unwrap()
      }
    } catch (errors) {
      console.log('loadData', errors)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (tasks.length) {
      const arr: IMessages[] = []

      for (let index = 0; index < tasks.length; index++) {
        const element = tasks[index]

        const item = fields.find(field => field.id === element.fieldId)

        if (item) {
          arr.push({
            ...item,
            task: element,
          })
        }
      }

      const group = _.chain(arr)
        .groupBy('farmDto.organizationDto.id')
        .map(items => {
          const el = items[0]
          return {
            name: el.farmDto?.organizationDto.organizationName || '',
            fields: items,
          }
        })
        .value()

      setMessages(group)
    }
  }, [tasks, fields])

  return (
    <>
      <Helmet>
        <title>{t('Chats')} | Sensilize teams</title>
      </Helmet>
      <Title>{t('Chats')}</Title>

      <div className={cnChats('wrap')}>
        <div className={cnChats('sidebar')}>
          <Paginator
            list={listPager}
            onClick={id => setActiveTab(id as number)}
            active={activeTab}
            className={cnChats('paginator')}
          />

          {messages.map((item, idx) => (
            <Accordion key={idx} title={item.name} className={cnChats('item')}>
              {item.fields.map((el, i) => (
                <Message
                  key={i}
                  message={el}
                  obb={(i + 1) % 2 === 0}
                  className={cnChats('child')}
                  onClick={() => {
                    if (el) {
                      setTask(el.task)
                    }
                  }}
                  urlTask={`/cabinet/tasks/${el.task.id}/view`}
                />
              ))}
            </Accordion>
          ))}
        </div>

        {!!task && user && (
          <div className={cnChats('content')}>
            <Chat
              key={task.id}
              taskId={task.id}
              recipient={user.id}
              farmId={task.farmId}
              fieldId={task.fieldId}
              disabled={task.status === ETaskStatus.COMPLETED}
            />
          </div>
        )}
      </div>
    </>
  )
})
