import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Loading } from '@/components/Loading'
import { Paginate } from '@/components/Paginate'
import { Title } from '@/components/Title'

import { Organization } from './components/Organization'
import { OrganizationModel } from './models/OrganizationsModel'
import { organizationsSelector } from './store/OrganizationsSelector'
import { getOrganizationThunk } from './store/OrganizationsSlice'
import './styles/Organizations.scss'

const cnOrganizations = cn('Organizations')

export const Organizations: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const orgData = useAppSelector(organizationsSelector)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [organizations, setOrganizations] = useState<OrganizationModel[]>([])
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    if (orgData) {
      setOrganizations(orgData.content)
    }
  }, [orgData])

  useEffect(() => {
    getOrganization(page)
  }, [page])

  const getOrganization = async (page: number) => {
    try {
      await dispatch(getOrganizationThunk({ page, size: 10 })).unwrap()
      setLoading(false)
    } catch (error) {
      console.log('getOrganization', error)
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('Organizations')} | Sensilize teams</title>
      </Helmet>
      <Title>{t('Organizations')}</Title>

      {isLoading ? (
        <Loading minHeight='50vh' />
      ) : (
        <div className={cnOrganizations('grid')}>
          {organizations.map(el => (
            <Organization
              key={el.id}
              id={el.id}
              name={el.organizationName}
              text={el.organizationTypeDto?.nameFull || ''}
              // farms={10}
              // fields={15}
              // peoples={70}
            />
          ))}

          {orgData && orgData.totalPages >= 2 && (
            <Paginate
              total={orgData.totalPages}
              page={page}
              changePage={page => setPage(page)}
            />
          )}
        </div>
      )}
    </>
  )
})
