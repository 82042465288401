import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { membersSelector } from '@/core/services/Settings/store/SettingsSelector'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Button } from '@/components/Button'
import { Icon } from '@/components/Icon'
import { Status } from '@/components/Status'

import { ITask } from '@/modules/Tasks/models/TasksModel'
import { deleteTaskThunk } from '@/modules/Tasks/store/TasksSlice'

import './Task.scss'

const cnTask = cn('Task')

interface ITaskSchema {
  task: ITask
  className?: string
  onDeleted?: () => void
  isReview?: boolean
  urlEdit: string
  urlView: string
}

export const Task: FC<ITaskSchema> = Memo(
  ({ className, task, onDeleted, isReview, urlEdit, urlView }: ITaskSchema) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const members = useAppSelector(membersSelector)

    const [userName, setUserName] = useState<string>('')

    useEffect(() => {
      if (task && members.length) {
        const current = members.find(el => el.id === task.assignee)
        if (current) {
          setUserName(current.firstName)
        }
      }
    }, [members, task])

    const editTask = () => {
      navigate(urlEdit)
    }
    const deleteTask = async () => {
      try {
        await dispatch(deleteTaskThunk(task.id)).unwrap()

        onDeleted && onDeleted()
      } catch (errors) {
        console.log('getMembers', errors)
      }
    }

    const onClickBtn = (str: string) => {
      console.log(str)
    }

    return (
      <div className={cnTask({ review: isReview }, [className])}>
        <div className={cnTask('left')}>
          <div className={cnTask('top')}>
            <NavLink to={urlView} className={cnTask('title')}>
              {task.name}
            </NavLink>
            {!isReview && (
              <div className={cnTask('actions')}>
                <button className={cnTask('action')} onClick={editTask}>
                  <Icon name='edit' />
                </button>
                <button className={cnTask('action')} onClick={deleteTask}>
                  <Icon name='delete' fill='#3499E6' />
                </button>
              </div>
            )}
          </div>
          <div className={cnTask('desc')}>{task.description}</div>
          <div className={cnTask('wrap')}>
            <div className={cnTask('date')}>
              {dayjs(task.endTime).format('MMM DD, YYYY')}
            </div>

            <Status status={task.priority} />
          </div>

          {userName && (
            <div className={cnTask('wrap')}>
              <div className={cnTask('circle')}>
                {userName.charAt(0).toUpperCase()}
              </div>
              <div className={cnTask('username')}>{userName}</div>
            </div>
          )}
        </div>
        {isReview && (
          <div className={cnTask('right')}>
            <Button onClick={() => onClickBtn('complete')}>
              {t('Complete')}
            </Button>
            <Button color='outline' onClick={() => onClickBtn('view report')}>
              {t('View report')}
            </Button>
            <Button
              color='outline'
              className={cnTask('btn')}
              onClick={() => onClickBtn('assign task to the Advisor')}
            >
              {t('Assign task to the Advisor')}
            </Button>
          </div>
        )}
      </div>
    )
  },
)
