/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { ToastPosition } from 'react-hot-toast'
import { ToastType } from 'react-hot-toast'

export interface ToastState {
  toast: {
    message: any
    type?: ToastType
    position?: ToastPosition
  } | null
}

const initialState: ToastState = {
  toast: null,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload
    },
  },
  extraReducers: {},
})

export const { actions, reducer } = toastSlice
export const { setToast } = actions
