import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { userSelector } from '@/core/services/Settings/store/SettingsSelector'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Chat } from '@/components/Chat'
import { Info } from '@/components/Info'
import { Photos } from '@/components/Photos'
import { Status } from '@/components/Status'
import { Title } from '@/components/Title'

import { fieldSelector } from '../Fields/store/FieldsSelector'
import { getFieldByIdThunk } from '../Fields/store/FieldsSlice'
import { reportsSelector, taskSelector } from './store/TasksSelector'
import { getReportsThunk, getTaskThunk, resetTasks } from './store/TasksSlice'
import './styles/TaskView.scss'

const cnTaskView = cn('TaskView')

type Params = {
  id: string
}

export const TaskView: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { id } = useParams<Params>()

  const task = useAppSelector(taskSelector)
  const user = useAppSelector(userSelector)
  const field = useAppSelector(fieldSelector)
  const reports = useAppSelector(reportsSelector)

  const getTask = async (id: string) => {
    try {
      await dispatch(getTaskThunk(id)).unwrap()
      await getReports(id)
    } catch (errors) {
      console.log('getTask', errors)
    }
  }

  const getField = async (id: string) => {
    try {
      await dispatch(getFieldByIdThunk(id)).unwrap()
    } catch (errors) {
      console.log('getField', errors)
    }
  }

  const getReports = async (id: string) => {
    try {
      await dispatch(getReportsThunk(id)).unwrap()
    } catch (errors) {
      console.log('getField', errors)
    }
  }

  useEffect(() => {
    if (task) {
      getField(task.fieldId)
    }
  }, [task])

  useEffect(() => {
    if (id) {
      getTask(id)
    }

    return () => {
      dispatch(resetTasks())
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('TaskView')} | Sensilize teams</title>
      </Helmet>

      <div className={cnTaskView('wrap')}>
        {task && (
          <div className={cnTaskView('content')}>
            <Title className={cnTaskView('title')}>
              #task{task.serialNumber}
            </Title>

            {field && (
              <div className={cnTaskView('top')}>
                <Info
                  title={field.farmDto?.organizationDto.organizationName || ''}
                  iconName='organizations'
                  className={cnTaskView('info')}
                />
                <Info
                  title={field.farmDto?.farmName || ''}
                  iconName='farms'
                  className={cnTaskView('info')}
                />
                <Info
                  title={field.fieldName}
                  iconName='fields'
                  className={cnTaskView('info')}
                />
              </div>
            )}

            <div className={cnTaskView('item')}>
              <div className={cnTaskView('label')}>{t('Date of creation')}</div>
              <div className={cnTaskView('value')}>
                {dayjs(task.createdAt).format('DD.MM.YYYY')}
              </div>
            </div>
            <div className={cnTaskView('item')}>
              <div className={cnTaskView('label')}>{t('Task priority')}</div>
              <Status status={task.priority} />
            </div>
            <div className={cnTaskView('item')}>
              <div className={cnTaskView('label')}>
                {t('The due date of the task')}
              </div>
              <div className={cnTaskView('value')}>
                {dayjs(task.endTime).format('DD.MM.YYYY')}
              </div>
            </div>
            <div className={cnTaskView('item')}>
              <div className={cnTaskView('label')}>{t('Task description')}</div>
              <div className={cnTaskView('value')}>{task.description}</div>
            </div>
            {/* <div className={cnTaskView('item')}>
              <div className={cnTaskView('label')}>{t('Photo')}</div>
              <Photos
                items={images}
                onUpload={uploadImage}
                onChangePhoto={v => setImages(v)}
              />
            </div> */}

            {reports && reports.content.length > 0 && (
              <>
                <Title className={cnTaskView('title')}>{t('Reports')}</Title>

                {reports.content.map(item => (
                  <div key={item.id} className={cnTaskView('list')}>
                    <div className={cnTaskView('item')}>
                      <div className={cnTaskView('label')}>{t('BBCH')}</div>
                      <div className={cnTaskView('value')}>{item.bbch}</div>
                    </div>
                    <div className={cnTaskView('item')}>
                      <div className={cnTaskView('label')}>
                        {t('Task description')}
                      </div>
                      <div className={cnTaskView('value')}>
                        {item.description}
                      </div>
                    </div>
                    <div className={cnTaskView('item')}>
                      <div className={cnTaskView('label')}>{t('Problem')}</div>
                      <div className={cnTaskView('value')}>
                        {item.problemTypes.join(', ')}
                      </div>
                    </div>

                    {item.urlPictures.length > 0 && (
                      <Photos items={item.urlPictures} isAdd={false} />
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        {task && user && (
          <Chat
            className={cnTaskView('chat')}
            taskId={task.id}
            recipient={user.id}
            farmId={task.farmId}
            fieldId={task.fieldId}
          />
        )}
      </div>
    </>
  )
})
