import dayjs from 'dayjs'

export const getFilterTimeUTC = (
  date?: Date | string,
  format?: string,
): string => {
  const dateHours = (h = 0) => {
    return new Date(new Date().setHours(h, 0, 0, 0))
  }

  const diffHours = 24 - new Date(dateHours()).getUTCHours()

  const currentDate = new Date(dayjs(date).add(diffHours, 'hours').toString())

  return dayjs(currentDate).format(format ? format : 'DD.MM.YYYY')
}
