import * as am5 from '@amcharts/amcharts5'
import { ITimeInterval } from '@amcharts/amcharts5/.internal/core/util/Time'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { cn } from '@bem-react/classname'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IDataChart } from '@/core/services/Weather/models/ForecastModel'

import { Memo } from '@/hoc/Memo'

import { ISettingsChart } from '../../models/FieldsModel'
import './LineChart.scss'

const cnLineChart = cn('LineChart')

interface ILineChart
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: IDataChart[]
  settings?: ISettingsChart
  baseInterval?: ITimeInterval
}

export const LineChart: FC<ILineChart> = Memo(
  ({
    className,
    data,
    settings,
    baseInterval = { timeUnit: 'day', count: 1 },
  }: ILineChart) => {
    const uid = 'LineChart-' + uuidv4()

    const [list, setList] = useState<IDataChart[]>([])

    am5.addLicense('AM5C378450555')

    useEffect(() => {
      setList(data)
    }, [data])

    useLayoutEffect(() => {
      if (data.length) {
        const root = am5.Root.new(uid)

        root.setThemes([am5themes_Animated.new(root)])

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            layout: root.verticalLayout,
          }),
        )

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        // const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
        // cursor.lineY.set('visible', false)

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        const xRenderer = am5xy.AxisRendererX.new(root, {})
        xRenderer.grid.template.set('forceHidden', true)
        xRenderer.grid.template.set('location', 1)
        xRenderer.labels.template.setAll({
          location: 1,
          multiLocation: 0.5,
          fontSize: 10,
          fontWeight: '600',
          fontFamily: 'Montserrat',
          paddingTop: 15,
        })

        const xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            baseInterval,
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
          }),
        )

        const yRenderer = am5xy.AxisRendererY.new(root, {})
        // yRenderer.grid.template.set('forceHidden', true)
        // yRenderer.labels.template.set('minPosition', 0.5)

        // yRenderer.ticks.template.setAll({
        //   visible: false,
        // })

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            // maxPrecision: 0,
            // extraMin: 0.1,
            // visible: false,
            // max: maxValue ? maxValue : undefined,
            renderer: yRenderer,
            tooltip: am5.Tooltip.new(root, {}),
          }),
        )

        // root.dateFormatter.setAll({
        //   dateFormat: 'yyyy-MM-dd',
        //   dateFields: ['valueX'],
        // })

        if (settings && settings.fields) {
          for (let index = 0; index < settings.fields.length; index++) {
            const element = settings.fields[index]

            const series = chart.series.push(
              am5xy.LineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: element.valueY,
                valueXField: element.valueX,
              }),
            )

            // series.fills.template.setAll({
            //   fillOpacity: 0.2,
            //   visible: true,
            // })

            // series.strokes.template.setAll({
            //   strokeWidth: 1,
            // })

            series.data.setAll(list)
            series.appear(1000)
          }
        }

        // add scrollbar
        // const scrollbarX = am5.Scrollbar.new(root, {
        //   orientation: 'horizontal',
        //   height: 6,
        //   maxHeight: 6,
        //   start: 0.4,
        //   end: 1,
        //   cursorOverStyle: 'grub',
        // })

        // scrollbarX.get('background')?.setAll({
        //   fill: am5.color('#f5f5f7'),
        //   fillOpacity: 1,
        //   height: 6,
        //   maxHeight: 6,
        // })

        // scrollbarX.thumb.setAll({
        //   fill: am5.color('#e0e0e0'),
        //   fillOpacity: 0.8,
        //   height: 6,
        //   y: 50,
        // })

        // scrollbarX.startGrip.setAll({
        //   visible: false,
        // })

        // scrollbarX.endGrip.setAll({
        //   visible: false,
        // })
        // chart.set('scrollbarX', scrollbarX)
        // chart.bottomAxesContainer.children.push(scrollbarX)

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100)

        return () => {
          root.dispose()
        }
      }
    }, [list])

    return (
      <div className={cnLineChart(null, [className])}>
        <div className={cnLineChart('wrap')} id={uid}></div>
      </div>
    )
  },
)
