import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const consultants = (state: RootState) => state.chatsReducer
export const consultantsSelector = createSelector(
  consultants,
  chatsReducer => chatsReducer.consultants,
)

const messages = (state: RootState) => state.chatsReducer
export const messagesSelector = createSelector(
  messages,
  chatsReducer => chatsReducer.messages,
)
