import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store'

const organizations = (state: RootState) => state.organizationsReducer
export const organizationsSelector = createSelector(
  organizations,
  organizationsReducer => organizationsReducer.organizations,
)

const organization = (state: RootState) => state.organizationsReducer
export const organizationSelector = createSelector(
  organization,
  organizationsReducer => organizationsReducer.organization,
)

const organizationTypes = (state: RootState) => state.organizationsReducer
export const organizationTypesSelector = createSelector(
  organizationTypes,
  organizationsReducer => organizationsReducer.organizationTypes,
)
