/* eslint-disable @typescript-eslint/no-explicit-any */
export const getItem = (key: string, isParse = true): never | any => {
  try {
    const val = localStorage.getItem(key)
    if (val && isParse) {
      return JSON.parse(val)
    } else if (val && !isParse) {
      return val
    } else {
      return null
    }
  } catch (error) {
    console.log('Error getting data from localStorage', error)
    return null
  }
}
export const setItem = (
  key: string,
  data: unknown,
  isStringify = true,
): void => {
  try {
    if (isStringify) {
      localStorage.setItem(key, JSON.stringify(data))
    } else {
      localStorage.setItem(key, data as string)
    }
  } catch (error) {
    console.log('Error saving data in localStorage', error)
  }
}
