import { Memo } from '@/hoc/Memo'
import { FC } from 'react'

export const PageNotFound: FC = Memo(() => {
  return (
    <div>
      <div>PageNotFound</div>
    </div>
  )
})
