import { cn } from '@bem-react/classname'
import dayjs from 'dayjs'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { Icon } from '@/components/Icon'

import { IMessages } from '../../models/ChatsModels'
import './Message.scss'

interface IMessage {
  message: IMessages
  className?: string
  obb?: boolean
  onClick?: () => void
  urlTask: string
}

const cnMessage = cn('Message')

export const Message: FC<IMessage> = ({
  className,
  message,
  obb,
  onClick,
  urlTask,
}) => {
  return (
    <div className={cnMessage({ obb }, [className])} onClick={onClick}>
      <div className={cnMessage('farm')}>{message.farmDto?.farmName}</div>
      <div className={cnMessage('field')}>
        {message.fieldName}

        <Icon name='arrow-right' fill='#687283' />
      </div>
      <div className={cnMessage('text')}>{message.task.description}</div>
      <div className={cnMessage('info')}>
        <div className={cnMessage('date')}>
          {dayjs(message.task.endTime as Date).format('DD.MM.YYYY HH:mm')}
        </div>
        <NavLink to={urlTask} className={cnMessage('link')}>
          task #{message.task.serialNumber}
        </NavLink>
      </div>

      {/* <div className={cnMessage('assignee')}>{message.task.assignee}</div> */}
    </div>
  )
}
