import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  forwardRef,
} from 'react'
import { isIOS } from 'react-device-detect'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

import { TypeMargin, TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './TextArea.scss'

const cnTextArea = cn('TextArea')

export type TextAreaThemeType = 'default' | 'white'

export interface ITextArea
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  uid?: string
  label?: string
  name: string
  errors?: string
  placeholder?: string
  classNameInput?: string
  classNameLabel?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  iconLeft?: ReactNode | string
  iconRight?: ReactNode | string
  handleOnBlur?: (e?: SyntheticEvent) => void
  handleCustomChange?: (
    e?: ChangeEvent<HTMLInputElement> | number | string,
  ) => void
  pattern?: string
  isNumber?: boolean
  sizeInput?: TypeSize
  mb?: TypeMargin
  info?: string
  theme?: TextAreaThemeType
  rows?: number
}

export const TextArea: FC<ITextArea> = Memo(
  forwardRef(
    (
      {
        uid = 'textarea-' + uuidv4(),
        value,
        label,
        onChange,
        onBlur,
        name,
        required,
        errors,
        placeholder,
        readonly = false,
        className,
        classNameLabel,
        classNameInput,
        disabled,
        iconLeft,
        iconRight,
        mb = 'sm',
        info,
        onFocus,
        theme = 'default',
        rows = 6,
        ...props
      }: ITextArea,
      ref: ForwardedRef<HTMLTextAreaElement>,
    ) => {
      return (
        <div
          className={cnTextArea(
            {
              xxl: mb === 'xxl',
              xl: mb === 'xl',
              lg: mb === 'lg',
              md: mb === 'md',
              sm: mb === 'sm',
              none: mb === 'none',
            },
            [className],
          )}
        >
          {!!label && (
            <label
              htmlFor={uid}
              className={cnTextArea(
                'label',
                {
                  required,
                },
                [classNameLabel],
              )}
            >
              {label}
            </label>
          )}
          <div className={cnTextArea('wrap')}>
            {iconLeft && (
              <div className={cnTextArea('icon', { left: true })}>
                {iconLeft}
              </div>
            )}

            <textarea
              placeholder={placeholder}
              id={uid}
              value={value}
              name={name}
              rows={rows}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              readOnly={readonly}
              className={cnTextArea(
                'input',
                {
                  errors: !!errors,
                  left: !!iconLeft,
                  right: !!iconRight || !!errors || !!info,
                  default: theme === 'default',
                  white: theme === 'white',
                  ios: isIOS,
                },
                [classNameInput],
              )}
              ref={ref}
              {...props}
            />

            {iconRight && !errors && !info && (
              <div
                className={cnTextArea('icon', {
                  right: true,
                })}
              >
                {iconRight}
              </div>
            )}

            {(info || !!errors) && (
              <>
                <div
                  id={'tooltip' + uid}
                  className={cnTextArea('icon', {
                    right: true,
                  })}
                  data-tooltip-content={info || errors}
                  data-tooltip-place='bottom'
                >
                  <Icon name='info' fill={info ? '#687283' : '#E24A4A'} />
                </div>
                <ReactTooltip
                  events={['click', 'hover']}
                  anchorId={'tooltip' + uid}
                  className={cnTextArea('tooltip')}
                />
              </>
            )}
          </div>
        </div>
      )
    },
  ),
)
