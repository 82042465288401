import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  FocusEvent,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { isIOS } from 'react-device-detect'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

import { TypeMargin, TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Field.scss'

const cnField = cn('Field')

export type FieldType = 'text' | 'number' | 'password' | 'email'
export type FieldThemeType = 'default' | 'white' | 'gray'

export interface FieldSchema
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  uid?: string
  type?: FieldType
  label?: string
  name: string
  errors?: string
  placeholder?: string
  classNameInput?: string
  classNameLabel?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  iconLeft?: ReactNode | string
  iconRight?: ReactNode | string
  noArrow?: boolean
  returnValue?: boolean
  isBackgroundSquare?: boolean
  backgroundSquare?: string
  handleOnBlur?: (e?: SyntheticEvent) => void
  handleCustomChange?: (
    e?: ChangeEvent<HTMLInputElement> | number | string,
  ) => void
  pattern?: string
  isNumber?: boolean
  sizeInput?: TypeSize
  mb?: TypeMargin
  info?: string
  theme?: FieldThemeType
}

export const Field: FC<FieldSchema> = Memo(
  forwardRef(
    (
      {
        uid = 'input-' + uuidv4(),
        type = 'text',
        value,
        label,
        onChange,
        onBlur,
        name,
        required,
        errors,
        placeholder,
        readonly = false,
        className,
        classNameLabel,
        classNameInput,
        disabled,
        iconLeft,
        iconRight,
        noArrow,
        handleOnBlur,
        returnValue,
        handleCustomChange,
        children,
        pattern,
        isNumber,
        sizeInput = 'md',
        mb = 'sm',
        info,
        onFocus,
        theme = 'default',
        ...props
      }: FieldSchema,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      const [isEye, setIsEye] = useState<boolean>(false)
      const [patternLocal, setPatternLocal] = useState<string | undefined>(
        undefined,
      )

      useEffect(() => {
        const p = '^-?[0-9]\\d*\\.?\\d*$'
        if (isNumber) setPatternLocal(p)
        if (pattern) setPatternLocal(pattern)
      }, [isNumber, pattern])

      // const changeType = () => {
      //   setIsEye(!isEye)

      //   const inputElement = document.getElementById(uid)
      //   if (inputElement) inputElement.focus()
      // }

      const hanleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
          onChange(e)
        } else if (handleCustomChange) {
          handleCustomChange(returnValue ? e.target.value : e)
        }
      }

      const hanleBlur = (e: FocusEvent<HTMLInputElement>): void => {
        setIsEye(false)
        if (type === 'password' && onBlur) onBlur(e)
      }

      return (
        <div
          className={cnField(
            {
              xxl: mb === 'xxl',
              xl: mb === 'xl',
              lg: mb === 'lg',
              md: mb === 'md',
              sm: mb === 'sm',
              none: mb === 'none',
            },
            [className],
          )}
        >
          {!!label && (
            <label
              htmlFor={uid}
              className={cnField(
                'label',
                {
                  required,
                },
                [classNameLabel],
              )}
            >
              {label}
            </label>
          )}
          <div className={cnField('wrap')}>
            {iconLeft && (
              <div className={cnField('icon', { left: true })}>{iconLeft}</div>
            )}
            <input
              placeholder={placeholder}
              id={uid}
              value={value}
              name={name}
              type={
                (type === 'password' && (isEye ? 'text' : 'password')) || type
              }
              autoFocus={isEye}
              onChange={hanleChange}
              onBlur={e => {
                hanleBlur(e)
                handleOnBlur && handleOnBlur(e)
              }}
              onFocus={onFocus}
              disabled={disabled}
              readOnly={readonly}
              className={cnField(
                'input',
                {
                  errors: !!errors,
                  left: !!iconLeft,
                  right: !!iconRight || !!errors || !!info,
                  arrow: noArrow,
                  xxl: sizeInput === 'xxl',
                  xl: sizeInput === 'xl',
                  lg: sizeInput === 'lg',
                  md: sizeInput === 'md',
                  sm: sizeInput === 'sm',
                  default: theme === 'default',
                  white: theme === 'white',
                  ios: isIOS,
                },
                [classNameInput],
              )}
              ref={ref}
              pattern={patternLocal}
              // onKeyPress={onKeyPress}
              {...props}
            />

            {iconRight && !errors && !info && (
              <div
                className={cnField('icon', {
                  right: true,
                })}
              >
                {iconRight}
              </div>
            )}

            {(info || !!errors) && (
              <>
                <div
                  id={'tooltip' + uid}
                  className={cnField('icon', {
                    right: true,
                  })}
                  data-tooltip-content={info || errors}
                  data-tooltip-place='bottom'
                >
                  <Icon name='info' fill={info ? '#687283' : '#E24A4A'} />
                </div>
                <ReactTooltip
                  events={['click', 'hover']}
                  anchorId={'tooltip' + uid}
                  className={cnField('tooltip')}
                />
              </>
            )}

            {/* {type === 'password' && (
              <button
                className={cnField('eye')}
                type='button'
                onClick={changeType}
              >
                <Icon
                  name={isEye ? 'eye-solid' : 'eye-slash-solid'}
                  width={20}
                  height={20}
                  fill='#9194A7'
                />
              </button>
            )} */}
          </div>

          {children && children}

          {/* <Transition in={!!errors} timeout={500} mountOnEnter unmountOnExit>
            {(state: string) => (
              <div className={cnField('error', [state])}>{errors}</div>
            )}
          </Transition> */}
        </div>
      )
    },
  ),
)
